import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  showingPackages:boolean,
  clientName:string,
  showingLearningPaths:boolean,
  packages:any,
  selectedPackages:any,
  sortOrder:string,
  searchTerm:string,
  filteredPackages:any,
  learningPaths:any,
  selectedLearningPaths:any,
  isAssignButtonDisable:boolean,
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CoursesAndPackagesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClientDetailsApiCallId='';
  getAllPackagesApiCallId = '';
  getAllLearningPathApiCallId = '';
  assignPackageCallId = '';
  assignLearningPathCallId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id:'0',
      showingPackages:true,
      showingLearningPaths:false,
      packages:[],
      selectedPackages:[],
      learningPaths:[],
      sortOrder:'relevance',
      searchTerm:'',
      filteredPackages:[],
      clientName:'',
      selectedLearningPaths:[],
      isAssignButtonDisable:true,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    await Promise.all([
      this.getAllPackages(),
      this.getClientDetails(),
      this.getLearningPaths(),
    ]);
  }
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getAllPackagesApiCallId)
      {
        this.recieveAllPackagesResponse(responseJson)
      }
      if (apiRequestCallId === this.getClientDetailsApiCallId) {
        this.receiveClientDetailsApiResponse(responseJson);
      }
      if (apiRequestCallId === this.getAllLearningPathApiCallId) {
        this.receiveLearningPathResponse(responseJson);
      }
      if(apiRequestCallId === this.assignPackageCallId)
      {
        this.receiveAssignPackageResponse(responseJson)
      }
      if(apiRequestCallId === this.assignLearningPathCallId)
      {
        this.receiveAssignLearningPathResponse(responseJson)
      }
    }
  };
  toggleAssingButton = () => {
    if (this.state.showingPackages) {
      if(this.state.selectedPackages.length > 0)
      {
        this.setState({isAssignButtonDisable:false})
      }
      else{
        this.setState({isAssignButtonDisable:true})
      }
    }
    if (this.state.showingLearningPaths) {
      if(this.state.selectedLearningPaths.length > 0)
      {
        this.setState({isAssignButtonDisable:false})
      }
      else{
        this.setState({isAssignButtonDisable:true})
      }
    }
  }
  handlePackagesClick = () => {
    this.setState({showingPackages:true})
    this.setState({showingLearningPaths:false})
  }
  handleLearningPathClick = () => {
    this.setState({
        showingPackages:false,
        showingLearningPaths:true,
    })
  }
  handleClientNavigation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationManageClientMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getAllPackages = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllPackagesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_content_management/course_packages"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  recieveAllPackagesResponse = (response:any) => {
    const packages = response.data.map((item:any) => ({
      id: item.id,
      name: item.attributes.name,
      coursesCount: item.attributes.courses.length
    }));
    this.setState({packages:packages})
    this.setState({filteredPackages:packages})
  }
  handleCheckboxChange = (pkgId:string) => {
    this.setState((prevState) => {
      const isSelected = prevState.selectedPackages.includes(pkgId);
      const updatedSelectedPackages = isSelected
        ? prevState.selectedPackages.filter((id:string) => id !== pkgId) 
        : [...prevState.selectedPackages, pkgId]; 

      return { selectedPackages: updatedSelectedPackages };
    },this.toggleAssingButton);
  };
  handleSortChange = (event:any) => {
    this.setState({ sortOrder: event.target.value },this.updateFilteredPackages);
  };
  handleSearchChange = (event:any) => {
    this.setState({ searchTerm: event.target.value },this.updateFilteredPackages);
};
  updateFilteredPackages = () => {
    const { packages, sortOrder, searchTerm } = this.state;

    let filteredPackages = packages.filter((packageItem:any) =>
      packageItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // Sort the filtered packages
    filteredPackages.sort((firstPackage:any, secondPackage:any) => {
      if (sortOrder === "a-z") {
        return firstPackage.name.localeCompare(secondPackage.name);
      } else if (sortOrder === "z-a") {
        return secondPackage.name.localeCompare(firstPackage.name);
      }
      return 0;
    });
    this.setState({ filteredPackages });
  };
  handleLearningPathToggle = (learningPathId:any, courses:any) => {
    this.setState(prevState => {
      const existingPath = prevState.selectedLearningPaths.find((path:any) => path.learningPathId === learningPathId);
      if (existingPath) {
        return {
          selectedLearningPaths: prevState.selectedLearningPaths.filter((path:any) => path.learningPathId !== learningPathId)
        };
      } else {
        return {
          selectedLearningPaths: [
            ...prevState.selectedLearningPaths,
            {
              learningPathId,
              selectedCourses: courses.map((course:any) => course.id)
            }
          ]
        };
      }
    },this.toggleAssingButton);
  };
  handleCourseToggle = (learningPathId:any, courseId:any) => {
    this.setState(prevState => {
      const existingPath = prevState.selectedLearningPaths.find((path:any) => path.learningPathId === learningPathId);
      if (existingPath) {
        const courseSelected = existingPath.selectedCourses.includes(courseId);
        const updatedCourses = courseSelected
          ? existingPath.selectedCourses.filter((id:any) => id !== courseId)
          : [...existingPath.selectedCourses, courseId];

        if (updatedCourses.length === 0) {
          return {
            selectedLearningPaths: prevState.selectedLearningPaths.filter((path:any) => path.learningPathId !== learningPathId)
          };
        } else {
          return {
            selectedLearningPaths: prevState.selectedLearningPaths.map((path:any) =>
              path.learningPathId === learningPathId
                ? { ...path, selectedCourses: updatedCourses }
                : path
            )
          };
        }
      } else {
        return {
          selectedLearningPaths: [
            ...prevState.selectedLearningPaths,
            { learningPathId, selectedCourses: [courseId] }
          ]
        };
      }
    },this.toggleAssingButton);
  };
  handleAssignPackageClick = () => {
    if (this.state.showingPackages) {
      this.assignPackage();
    }
    if (this.state.showingLearningPaths) {
      this.assignLearningPath();
    }
  }
  getClientDetails = () => {
    let clientId = localStorage.getItem('SelectedClientID')
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/clients/${clientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.getClientDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveClientDetailsApiResponse = (response:any) => {
    this.setState({
      clientName:response.data.attributes.company_name,
    })
  }
  getLearningPaths = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllLearningPathApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/learning_paths"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveLearningPathResponse = (response:any) => {
    this.setState({learningPaths:response?.data});
  }
  NavigationClient = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationClientMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  assignPackage = () => {
    const clientId = localStorage.getItem('SelectedClientID')
    const reqBody = {
      "id": clientId,
      "course_package_ids": this.state.selectedPackages
    }
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assignPackageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_account_groups/assign_packages"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveAssignPackageResponse = (response:any) => {
    if(response.error)
    {
      toast.error(response.error)
      return;
    }
    toast.success(response.message)
  }
  assignLearningPath = () => {
    const clientId = localStorage.getItem('SelectedClientID')
    const reqbody = {
      id: clientId,
      data: this.state.selectedLearningPaths.map((item: any) => ({
        learning_path_id: parseInt(item.learningPathId, 10),
        selected_course_ids: item.selectedCourses
      }))
    };
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assignLearningPathCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_account_groups/assign_learning_paths"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveAssignLearningPathResponse = (response:any) => {
    if(response.error)
    {
      toast.error(response.error)
    }
    toast.success(response.message)
  }
  // Customizable Area End
}
