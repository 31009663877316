import React from 'react';

// Customizable Area Start
import {
  TextField,
  Box,
  Button,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  Popover,
  List,
  ListItem,
  Tab,
  Checkbox,
  Tabs,
} from '@mui/material';


import { Close, Search, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import FullCalendar from '@fullcalendar/react'; 
import dayGridPlugin from '@fullcalendar/daygrid'; 
import timeGridPlugin from '@fullcalendar/timegrid'; 
import interactionPlugin from '@fullcalendar/interaction'; 
import { GroupAdd, Clock, BlackArrow, CalendarIcon, GreenTick,CopyIcon,CalendarEnd,Location,CompanyIcon,JobRoleIcon } from './assets';


// Customizable Area End

import EventManagementController, {
  Props,
} from './EventManagementController.web';


export default class EventManagement extends EventManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const timeZones = [
      { value: 'UTC', label: 'UTC' },
      { value: 'GMT', label: 'GMT' },
      { value: 'EST', label: 'Eastern Standard Time (UTC-5)' },
      { value: 'CST', label: 'Central Standard Time (UTC-6)' },
      { value: 'MST', label: 'Mountain Standard Time (UTC-7)' },
      { value: 'PST', label: 'Pacific Standard Time (UTC-8)' },
      { value: 'CET', label: 'Central European Time (UTC+1)' },
      { value: 'EET', label: 'Eastern European Time (UTC+2)' },
      { value: 'IST', label: 'Indian Standard Time (UTC+5:30)' },
      { value: 'JST', label: 'Japan Standard Time (UTC+9)' },
      { value: 'AEDT', label: 'Australian Eastern Daylight Time (UTC+11)' },
    ];
    
    const { anchorEl, clientSearch } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const filteredClients = this.state.ClientList.filter((client:any) =>
      client.companyName.toLowerCase().includes(clientSearch.toLowerCase())
    );
    
    return (
      <>
        
        <Box sx={webStyles.mainWrapper}>
          <Box sx={webStyles.mainBlock}>
            <Sidebar current='event_management' />
            <div style={{ width: '100%' }}>
              <Box sx={webStyles.topHeader}>
                <h2>Event Management</h2>
                <BlackButton data-test-id="add-new-button" onClick={this.handleShowEventDetailsDialog}> <img style={{marginRight:'5px'}} src={GroupAdd} /> Add new event</BlackButton>
              </Box>
              <div style={{ columnGap: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1rem 1rem', height: 'fit-content' }}>
                <CalendarWrapper>

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>{this.state.currentMonthYear}</h2>
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                      <ArrowBackIos data-test-id="prev-btn" fontSize='small' style={{ padding: '14px', backgroundColor: '#F1F5F9', borderRadius: '4px' }} onClick={this.handlePrevClick} />
                      <ArrowForwardIos data-test-id="next-btn" fontSize='small' style={{ padding: '14px', backgroundColor: '#F1F5F9', borderRadius: '6px' }} onClick={this.handleNextClick} />

                      <div>
                        <StyledTabs
                          TabIndicatorProps={{
                            style: {
                              display: 'none'
                            }
                          }}
                          value={this.state.selectedTab}
                          onChange={this.handleTabChange}
                          centered
                        >
                          <StyledTab label="Day" value={0} />
                          <StyledTab label="Week" value={1} />
                          <StyledTab label="Month" value={2} />
                        </StyledTabs>
                      </div>
                    </div>
                  </div>

                  <FullCalendar
                    firstDay={1}
                    ref={this.state.calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={this.state.events}
                    headerToolbar={false}
                    dayMaxEventRows={true}
                    dayMaxEvents={1}
                    eventClick={this.handleEventClick}
                  />

                </CalendarWrapper>
                <div>

                  <div >
                    <Typography style={{ fontFamily: "Heebo", fontSize: '14px', marginBottom: '10px' }}>UPCOMING EVENTS</Typography>
                    <div style={{maxHeight:'450px',overflowY:'scroll'}}>
                      {this.state.upcomingEvents.map((event: any, index: number) => {
                        return (<div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderLeft: '4px solid black',
                            padding: '1rem',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            alignItems: 'start',
                            marginBottom: '8px',
                               
                          }}
                        >
                          <div>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '16px' }}>
                              {event.title}
                            </Typography>
                            <Typography style={{ fontSize: '14px' }}>{event.host || 'Host Name'}</Typography>
                            <Typography style={{ fontSize: '14px' }}>
                              {this.formatTime(event.start_time)} - {this.formatTime(event.end_time)}, {event.date}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              style={{
                                padding: '8px 12px',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '20px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textTransform: "uppercase",
                                fontSize: '14px',
                                color: "#6A6A6A"
                              }}
                            >
                              {event.total_attendees} Attendees
                            </Typography>
                          </div>
                        </div>)
                      })}
                    </div>
                  </div>

                  <div >
                    <Typography style={{ fontFamily: "Heebo", fontSize: '14px', marginBottom: '10px' }}>PAST EVENTS</Typography>
                    <div style={{maxHeight:'325px',overflowY:'scroll'}}>
                      {this.state.pastEvents.map((event: any, index: number) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderLeft: '4px solid #646464',  
                            padding: '1rem',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            alignItems: 'start',
                            marginBottom: '8px',
                            
                          }}
                        >
                          <div>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '16px', color: '#0f172a' }}>
                              {event.title}
                            </Typography>
                            <Typography style={{ fontSize: '14px', color: '#0f172a' }}>{event.host || 'Host Name'}</Typography>
                            <Typography style={{ fontSize: '14px', color: '#0f172a' }}>
                              {this.formatTime(event.start_time)} - {this.formatTime(event.end_time)}, {event.date}
                            </Typography>
                          </div>
                          <div>
                          <Typography
                              style={{
                                padding: '8px 12px',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textTransform: "uppercase",
                                fontSize: '14px',
                                color: "white",
                                visibility:'hidden'
                              }}
                            >
                              {"Attentdees"}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Box>




          <Box sx={webStyles.footerBlock}>
            <Box sx={webStyles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Pravicy Policy</Box>
              <Box></Box>
            </Box>
            <Box>
              <Box>© 2024 All Rights Reserved</Box>
            </Box>
          </Box>
        </Box>

        <Dialog fullWidth onClose={this.handleCloseEventDetailsDialog} aria-labelledby="customized-dialog-title" open={this.state.showEventDetailsDialog}>
          <StyledDialogTitle >
            <Typography variant="h6">Add new event</Typography>
            <IconButton data-test-id="event-close" aria-label="close" onClick={this.handleCloseEventDetailsDialog} className="MuiIconButton-root">
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '1rem' }}>
              <h3 style={{
                backgroundColor: 'black',
                color: 'white',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                margin: '0',
                fontWeight: 400
              }}>
                1
              </h3>
              <h3 style={{ margin: '0', fontWeight: 500 }}>Event Details</h3>
            </div>

            <CommonInputLable>Title</CommonInputLable>
            <CommonTextField
              value={this.state.eventTitle}
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="outlined"
              onChange={(event) => this.handleChange(event, 'title')}
            />

            <CommonInputLable>Date</CommonInputLable>
            <CommonTextField
              value={this.state.eventDate}
              data-test-id='date-input'
              onChange={(event) => this.handleChange(event,'date')}
              type='date'
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CalendarIcon} alt="Calendar Icon" style={{ width: '24px', height: '24px' }} />
                  </InputAdornment>
                ),
                endAdornment: null, 
              }}
            />

            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Time start</CommonInputLable>
                <CommonTextField
                  data-test-id="time-start"
                  fullWidth
                  value={this.state.timeStart}
                  onChange={(event) => this.handleTimeChange(event,'time-start')}
                  type="time"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Clock} alt="Time Icon" style={{ width: '24px', height: '24px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TimeFieldContainer>
              <img src={BlackArrow} />
              <TimeFieldContainer>
                <CommonInputLable>Time end</CommonInputLable>
                <CommonTextField
                  data-test-id="time-end"
                  value={this.state.timeEnd}
                  onChange={(event) => this.handleTimeChange(event, 'time-end')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={CalendarEnd} alt="Time Icon" style={{ width: '24px', height: '24px' }} />
                      </InputAdornment>
                    ),
                  }} fullWidth
                  type="time"
                  variant="outlined"
                />
              </TimeFieldContainer>
            </TimeContainer>
            <CommonInputLable>Time Zone</CommonInputLable>
            <CustomSelect

              fullWidth
              labelId="time-zone-select-label"
              value={this.state.selectedTimeZone}
              onChange={(event) => this.handleSelectTimeZone(event)}
              style={{ borderRadius: '8px', marginBottom: '1rem' }}
            >
              {timeZones.map((timeZone) => (
                <MenuItem key={timeZone.value} value={timeZone.value}>
                  {timeZone.label}
                </MenuItem>
              ))}
            </CustomSelect>

            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Event link</CommonInputLable>
                <CommonTextField
                  data-test-id="event-link"
                  value={this.state.eventLink}
                  onChange={(event) => this.handleChange(event,'event-link')}
                  fullWidth type="text"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={CopyIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TimeFieldContainer>

              <TimeFieldContainer>
                <CommonInputLable>Location</CommonInputLable>
                <CommonTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Location} />
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.eventLocation}
                  fullWidth
                  type="text"
                  variant="outlined"
                  onChange={(event) => this.handleChange(event, 'location')}
                />
              </TimeFieldContainer>
            </TimeContainer>

            <CommonInputLable>Notification</CommonInputLable>
            <CommonTextField
              data-test-id="time-notification"
              type='time'
              value={this.state.notificationTime}
              onChange={(event) => this.handleTimeChange(event,'notification-time')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Clock} />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="outlined"
            />

            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '1rem' }}>
              <h3 style={{
                backgroundColor: '#D7D7D7',
                color: 'white',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                margin: '0',
                fontWeight: 400
              }}>
                2
              </h3>
              <h3 style={{ margin: '0', fontWeight: 500, color: '#6A6A6A' }}>Select Attendees</h3>
            </div>


          </StyledDialogContent>
          <StyledDialogActions>
            <LightButton autoFocus onClick={this.handleCloseEventDetailsDialog} color="primary">
              Cancel
            </LightButton>
            <DarkButton data-test-id="save-btn" autoFocus onClick={this.handleEventDetailsSave} color="primary">
              Save
            </DarkButton>
          </StyledDialogActions>
        </Dialog>

        <Dialog fullWidth onClose={this.handleCloseSelectAttendeeDialog} aria-labelledby="customized-dialog-title" open={this.state.showSelectAttendeesDialog}>
          <StyledDialogTitle >
            <Typography variant="h6">Select Attendies</Typography>
            <IconButton aria-label="close" onClick={this.handleCloseSelectAttendeeDialog} className="MuiIconButton-root">
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>

            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '1rem' }}>
              <img src={GreenTick} height={32} width={32} />
              <h3 style={{ margin: '0', fontWeight: 500 }}>Event Details</h3>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '1rem' }}>
              <h3 style={{
                backgroundColor: 'black',
                color: 'white',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                margin: '0',
                fontWeight: 400
              }}>
                2
              </h3>
              <h3 style={{ margin: '0', fontWeight: 500 }}>Select Attendies</h3>
            </div>
            <CommonInputLable>Client</CommonInputLable>

            <div style={{marginBottom:'1rem'}}>
              <CommonTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={CompanyIcon} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
                onClick={this.handleAddClientClick}
                value={
                  this.state.selectedClients.length > 0
                    ? [...new Set(this.state.selectedClients)]  // Remove duplicates
                      .map((id: any) =>
                        this.state.ClientList.find((client: any) => client.companyId === id)
                      )
                      .map((client: any) => client ? client.companyName : '') // Safely access companyName
                      .join(', ')
                    : ''
                }
              />
              <Popover
                style={{ maxHeight: '250px' }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleAddClientClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div style={{ padding: 16, width: '32.4rem' }}>
                  <TextField
                    placeholder="Search..."
                    variant='standard'
                    fullWidth
                    onChange={this.handleInputChange}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search htmlColor='#d7d7d7' />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Close htmlColor='#d7d7d7' onClick={this.handleAddClientClose} style={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <List>
                    {filteredClients.map((client:any) => (
                      <ListItem
                        style={{ padding: '8px' }}
                        data-test-id="list-item"
                        key={client.companyId}
                        onClick={() => this.handleClientToggle(client)}
                      >
                        <Checkbox
                          checked={this.state.selectedClients.includes(client.companyId)} 
                          onChange={() => this.handleClientToggle(client)} 
                        />
                        {client.companyName}
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Popover>
            </div>
            <CommonInputLable>Job Role</CommonInputLable>
            <CustomSelect
              startAdornment={
                <InputAdornment position="start">
                  <img src={JobRoleIcon} />
                </InputAdornment>
              }
              multiple
              placeholder="Select role"
              fullWidth
              labelId="job-role-select-label"
              value={this.state.selectedJobRoles}
              onChange={this.handleSelectJobRole}
              renderValue={(selected:any) => selected.join(', ')}
              style={{ borderRadius: '8px', marginBottom: '1rem' }}
            >
              {this.state.jobRoles.map((jobRole) => (
                <MenuItem key={jobRole} value={jobRole}>
                  <Checkbox
                    checked={this.state.selectedJobRoles.indexOf(jobRole) > -1}
                  />
                  {jobRole}
                </MenuItem>
              ))}
            </CustomSelect>


            <CommonInputLable>Users</CommonInputLable>
            <CustomSelect
              startAdornment={
                <InputAdornment position="start">
                  <img src={JobRoleIcon} />
                </InputAdornment>
              }
              placeholder='Add user'
              fullWidth
              labelId="time-zone-select-label"
              value={this.state.selectedUsers}
              onChange={(event) => this.handleSelectUser(event)}
              style={{ borderRadius: '8px', marginBottom: '1rem' }}
            >
              {this.state.filteredUsers.map((user:any) => (
                <MenuItem key={user.id} value={user.id}>
                  <Checkbox
                    checked={this.state.selectedUsers.includes(user.id)}
                    onChange={(event) => this.handleUserToggle(event, user.id)}
                  />
                  {`${user.first_name} ${user.last_name}`}
                </MenuItem>
              ))}
            </CustomSelect>



          </StyledDialogContent>
          <StyledDialogActions>
            <LightButton data-test-id="back-button" autoFocus onClick={this.handleShowEventDetailsDialog} color="primary">
              Back
            </LightButton>
            <DarkButton data-test-id="publish-btn" autoFocus onClick={this.handlePublishEventClick} color="primary">
              Publish
            </DarkButton>
          </StyledDialogActions>
        </Dialog>

        <Dialog fullWidth onClose={this.handleCloseEditEvent} aria-labelledby="customized-dialog-title" open={this.state.showEditEvent}>
          <StyledDialogTitle >
            <Typography variant="h6">{this.state.selectedEvent?.title}</Typography>
            <IconButton aria-label="close" onClick={this.handleCloseEditEvent} className="MuiIconButton-root">
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <CommonInputLable>Date</CommonInputLable>
            <CommonTextField
              value={this.state.selectedEvent?.start }
              type='text'
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CalendarIcon} alt="Calendar Icon" style={{ width: '24px', height: '24px' }} />
                  </InputAdornment>
                ),
                endAdornment: null, 
              }}
            />
            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Time start</CommonInputLable>
                <CommonTextField
                  value={this.state.selectedEvent?.startTime + ` (${this.state.selectedEvent?.timeZone})`}
                  fullWidth
                  type="text"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Clock} alt="Time Icon" style={{ width: '24px', height: '24px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TimeFieldContainer>
              <TimeFieldContainer>
                <CommonInputLable>Time end</CommonInputLable>
                <CommonTextField InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={CalendarEnd} alt="Time Icon" style={{ width: '24px', height: '24px' }} />
                    </InputAdornment>
                  ),
                }} fullWidth
                  type="text"
                  variant="standard"
                  value={this.state.selectedEvent?.endTime + ` (${this.state.selectedEvent?.timeZone})`}
                />
              </TimeFieldContainer>
            </TimeContainer>
            <CommonInputLable>Notification</CommonInputLable>
            <CommonTextField
              value={this.state.selectedEvent?.notification + ` (${this.state.selectedEvent?.timeZone})`}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Clock} />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="standard"
            />
            <CommonInputLable>Event link</CommonInputLable>
                <CommonTextField
                  value={this.state.selectedEvent?.url}
                  style={{marginBottom:'1rem'}}
                  fullWidth type="text"
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={CopyIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Client</CommonInputLable>
                <CommonTextField
                  value={this.state.selectedEvent?.clients}
                  fullWidth
                  type="text"
                  variant="standard"
                  InputProps={{
                    readOnly:true,
                    disableUnderline: true,
                  }}
                />
              </TimeFieldContainer>
              <TimeFieldContainer>
                <CommonInputLable>User</CommonInputLable>
                <CommonTextField InputProps={{
                  readOnly:true,
                  disableUnderline: true,
                  
                }} fullWidth
                  type="text"
                  variant="standard"
                  value={this.state.selectedEvent?.totalUser}
                />
              </TimeFieldContainer>
            </TimeContainer>

          </StyledDialogContent>
          <StyledDialogActions>
            <LightButton data-test-id='edit-btn' onClick={this.handleEditEventClick} color="primary">
              Edit
            </LightButton>
            <DarkButton data-test-id='delete-event-btn' onClick={this.deleteEvent} style={{backgroundColor:'#FC5555'}} color="primary">
              Delete
            </DarkButton>
          </StyledDialogActions>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CalendarWrapper = styled('div')({
  width: '65%',
  backgroundColor: 'white',
  padding: "1rem",
  borderRadius: '8px',
  height: '50rem',
  '& .fc-col-header-cell': {
    borderLeft: 'none !important',
    borderRight: 'none !important',
  },
  '& .fc .fc-scrollgrid-liquid': {
    borderTop: 'none !important',
    border: 'none !important'
  },
  '& .fc .fc-col-header-cell-cushion': {
    padding: '2rem 0rem',
    color: "#64748B",
    FontFamily: 'Heebo'
  },
  
  
  '& .fc .fc-scrollgrid-section > *': {
    borderRightWidth: '0px !important',
  },
  '& .fc .fc-scroller-harness-liquid': {
    borderRight: '1px solid #DDDDDD',
  },
  '& .fc-event-time':{
    display:'none'
  },
  '& .fc-event-title .fc-sticky':{
    color:'black !important'
  },
  '& .fc-scroller': {
    overflow: 'hidden !important'
  },
  '& .fc .fc-daygrid-event': {
    backgroundColor: '#F6F0FF',
    borderRadius: '4px',
    padding: '5px',
    color: 'black',
    fontSize: '0.9em',
    fontFamily: 'Heebo',
  },

  

  
});
const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 'bolder',
  fontFamily: 'Heebo-bold',
  color: '#64748B',
  '&$selected': {
    color: 'white',
    backgroundColor: "black",
    borderRadius: '25px',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: "black",
    borderRadius: '25px',
    border: 'none'
  },
  
})
const StyledTabs = styled(Tabs)({
  backgroundColor: '#F1F5F9',
  borderRadius: '25px',
  width: 'fit-content',
  padding: '5px'
})
const StyledDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: '16px 25px',
  position: 'relative',
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: '#757575',
  },
});
const CommonInputLable = styled(InputLabel)({
  marginBottom: '5px',
  color: '#334155',
  fontWeight: 500
})
const CommonTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px'
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 14px'
  }
})
const CustomSelect = styled(Select)({
  '& .MuiSelect-outlined.MuiSelect-outlined': {
    padding: '14px !important'
  }
})
const StyledDialogContent = styled(DialogContent)({
  padding: '25px',
});

const StyledDialogActions = styled(DialogActions)({
  margin: 0,
  padding: '16px 25px',
});

const TimeContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '1rem',
});

const TimeFieldContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});
const LightButton = styled(Button)({
  backgroundColor: '#d7d7d7',
  color: 'black',
  textTransform: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#d7d7d7',
    color: 'black',
  },
})
const DarkButton = styled(Button)({
  backgroundColor: 'black',
  color: 'white',
  textTransform: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
})

const BlackButton = styled(Button)({
  backgroundColor: 'black',
  color: 'white',
  textTransform: 'none',
  height: 'fit-content',
  fontFamily: 'Heebo',
  fontWeight: 400,
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
})
const webStyles = {
  mainBlock: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6',
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6'
  },
  topHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'fit-content',
    paddingLeft: '1rem',
    paddingRight: '2rem',
    alignItems: 'center',
  },

  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow: 1
  },

  stepNumber: {
    backgroundColor: 'black',
    color: 'white',
    padding: '5px',
    borderRadius: '50%',
    marginRight: '0.5rem',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1rem',
    marginBottom: '1rem',
  },
  timeInput: {
    flex: 1,
    minWidth: '120px',
  },
  formControl: {
    marginBottom: '1rem',
  },
  eventLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1rem',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
  textField: {
    flex: 1,
    minWidth: '120px',
  },
  upcomingContainer: {
    marginBottom: '10px'
  },
  upcomingEventCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderLeft: '4px solid black',
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    alignItems: 'start',
    marginBottom: '8px'
  },
  upcomingTitle: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    fontSize: '16px'
  },
  upcomingHost: {
    fontSize: '14px'
  },
  upcomingTime: {
    fontSize: '14px'
  },
  upcomingAttendees: {
    padding: '8px 12px',
    backgroundColor: '#f0f0f0',
    borderRadius: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: "uppercase",
    fontSize: '14px',
    color: "#6A6A6A"
  }
};
// Customizable Area End
