import React from "react";
// Customizable Area Start
import { Box, Button, Typography, IconButton,styled,Grid } from "@mui/material";
import Header from "../../../components/src/Header.web";
import { imgWave, } from "../../../../packages/blocks/landingpage/src/assets";
import { myLearningCourseImg } from "./assets";
import HtmlReactParser from 'html-react-parser';
import DOMPurify from 'dompurify';
import {TimerOutlined} from '@mui/icons-material'
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPageNumbers = () => {
    const { currentPage } = this.state;
    const totalPages = this.getTotalPages(); 
    const pages = [];
    if (currentPage === 1) {
      pages.push(1, 2, totalPages); 
    } else if (currentPage === totalPages) {
      pages.push(totalPages - 1, totalPages); 
    } else if (currentPage === totalPages - 1) {
      pages.push(totalPages - 2, totalPages - 1, totalPages); 
    } else {
      pages.push(currentPage, currentPage + 1, totalPages);
    }
    const uniquePages = [...new Set(pages)]; 
  
    return uniquePages.map((page, index) => (
      <Button
        key={index}
        data-test-id={`PageNo${index}`}
        style={{
          color: 'black', backgroundColor: '#fff',
          minWidth: '40px', height: '37px', boxShadow: 'none',
          borderRadius: '4px',
          border: this.state.currentPage === page ? '1px solid #141615' : 'none'
        }
        }
        variant={this.state.currentPage === page ? 'contained' : 'outlined'}
        onClick={() => this.handlePageChange(page)} 
      >
        {page}
      </Button>
    ));
  };

  parsedString = (data: any) => {
    const cleanHtml = DOMPurify.sanitize(data);
    return HtmlReactParser(cleanHtml);
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentPage } = this.state;
    const totalPages = this.getTotalPages();
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Header
          navigation={this.props.navigation}
          islogin={true}
          isHeader={true}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: '30px', justifyContent: 'space-between' }}>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box width={"50%"} display={"flex"} flexDirection={"column"} gap={"16px"}>
                <Box display={"flex"} gap={"16px"}>
                  {this.state.welcomeContent.welcome_heading_logo ? <><img src={this.state.welcomeContent.welcome_heading_logo.url} alt="wave" /></> : <img src={imgWave} alt="wave" />}
                  {this.state.welcomeContent.updated_heading ? <>{this.parsedString(this.state.welcomeContent.updated_heading)}</> :
                    <Typography
                      style={{ fontSize: "32px", fontWeight: '300', lineHeight: '47px', fontFamily: "Heebo-Light" }}
                    >
                      Welcome {" "}
                      <span style={{ fontWeight: '500', fontFamily: "Heebo-Regular", color: '#232323' }}>
                        {this.state.username},
                      </span>
                      {" "}from Builder.Ai
                    </Typography>}
                </Box>
                {this.state.welcomeContent?.context ? <>{this.parsedString(this.state.welcomeContent.context)}</> : <Typography>
                  Here you can access courses designed to enhance your skills and knowledge.
                  Explore the wide range of learning paths we have curated just for you.
                </Typography>}
              </Box>
              <Box width={"20%"}>
               {this.state.welcomeContent.company_logo?.url ?<img height={100} width={100} src={this.state.welcomeContent.company_logo.url} alt="builder_logo" />: <img  src="/builderLogo.svg" alt="builder_logo" />}
                {this.state.welcomeContent?.company_logo_context ? <><WrapperDiv>{this.parsedString(this.state.welcomeContent.company_logo_context)}</WrapperDiv></> :    <Typography style={{
                  color: '#4C4C4C', fontWeight: "400", fontSize: '16px', lineHeight: '23.5px',
                  fontFamily: "Heebo-Regular"
                }}
                  paddingTop="20px">
                  "This portal is brought to you in
                  collaboration with Builder.ai and Join
                  Talent, tailored for your learning needs."
                </Typography>}
              </Box>
            </Box>

            <Typography variant="h4" sx={{ fontWeight: '500', padding: '10px 0px' }}>Your Learning Paths</Typography>
            <Box
              display={"flex"} justifyContent={"space-between"} padding={"10px 0px 20px 0px"}
            >
              <Box sx={{ display: 'flex', gap: '16px', padding: '15px 0px', alignItems: 'center' }}>
                <Button sx={{ ...styles.blackButton }}>
                  All Courses (10)
                </Button>
                <Button sx={{ color: 'black', backgroundColor: 'white', textTransform: 'none' }}>
                  Experience Design (4)
                </Button>
                <Button sx={{ color: 'black', backgroundColor: 'white', textTransform: 'none' }}>
                  Interface Design (6)
                </Button>
              </Box>

              <Box display={'flex'} justifyContent={'space-between'} alignItems={"center"} sx={{ gap: "32px" }}>
                <Box sx={{
                  borderRadius: '100%',
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  height: '40px', width: '40px',
                }}
                  display={'flex'} justifyContent={'center'} alignItems={"center"}
                >
                  <img src={require('../assets/ic_arrowleft.png').default} alt="" />
                </Box>
                <Box sx={{
                  borderRadius: '50%',
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  height: '40px', width: '40px',
                }}
                  display={'flex'} justifyContent={'center'} alignItems={"center"}
                >
                  <img src={require('../assets/ic_arrowright.png').default} alt="" />
                </Box>
              </Box>
            </Box>


            <Grid container spacing={4} marginTop={-5} marginBottom={'5rem'}>
              {this.state.allCourses.map((course: any) => (
                <Grid item xs={2.4} key={course.id}> 
                  <Box
                    
                    onClick={() => {this.props.navigation.navigate('CourseDescription',{path:{courseId:course.id}})}}
                    data-test-id={'course-' + course.id}
                    sx={styles.courseCard}>
                    <BoxThumbnail>
                      <img src={course.imageUrl || myLearningCourseImg} alt="ux_courses" />
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '10px',
                          height: '65px',
                          boxSizing: 'border-box'
                        }}
                      />
                      <Typography
                        style={{
                          display: 'flex',
                          color: '#fff',
                          padding: '10px',
                          fontSize: '14px',
                          alignItems: 'center'
                        }}
                      >
                        <TimerOutlined style={{ marginRight: '5px' }} />
                        {course.duration} Hours
                      </Typography>
                    </BoxThumbnail>
                    <Box sx={styles.courseCardDescription}>
                      <Box sx={{ fontSize: '16px' }}>{course.title}</Box>
                      <Box sx={{ fontSize: '12px', color: '#171717' }}>
                        {course.lessonCount} lessons
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
              <IconButton
                data-test-id="LeftBtn"
                onClick={() => this.handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <img src={require('../assets/ic_arrowleft.png').default} alt="" />
              </IconButton>
              {this.renderPageNumbers()}
              <IconButton
                onClick={() => this.handlePageChange(currentPage + 1)}
                data-test-id="RightBtn"
                disabled={currentPage === totalPages}
              >
                <img src={require('../assets/ic_arrowright.png').default} alt="" />

              </IconButton>
            </Box>

          </Box>
        </Header >
      </Box >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxThumbnail = styled(Box)({
  boxSizing:'border-box',
  position:'relative',
  width: '100%',
  display:'flex',
  flexDirection:'column',
  justifyContent:'space-between',
      height:'160px',
  "&>img":{
      position: 'absolute',
      width:'100%',
      height:'100%',
      zIndex:'-1'
  }
})
const WrapperDiv = styled('div')({
  '& p:first-of-type': {
    wordBreak: 'break-word',
  },
});
const styles = {
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },

  pageContent: {
    display: 'flex',
    flexDirection: 'column'
  },

  contentHeader: {
    height: '92px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 48px'
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700'
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  userContent: {
    margin: '25px',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px'
  },
  greetingBlock: {
    width: '1069px',
    display: 'flex',
    flexDirection: 'row',
    gap: '130px',
    padding: '24px 16px'
  },
  adminGreeting: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    fontSize: '32px',
    background: '#F0F0F0',
    whiteSpace: 'nowrap'
  },
  providerBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    width: '1069px',
    borderRadius: '4px'
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    maxWidth: '350px',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow: {
    whiteSpace: 'nowrap'
  }
}
// Customizable Area End
