import React from "react";
// Customizable Area Start
import {
  Box, Grid, Button, InputBase, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from "../../../components/src/Loader.web";
import ContactusController from "./ContactusController";
// Customizable Area End

export default class Contactus extends ContactusController {
  // Customizable Area Start
  signUpSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required("*Name is required"),
      email: Yup.string().required("*Email is required")
        .email("*Please enter a valid email address"),
      query: Yup.string().required("*Please enter query"),
    });
  }

  getName = () => {
    return <Typography className="mt5">{this.state.name ? this.state.name : "-"}</Typography>;
  }

  getEmail = () => {
    return <Typography className="mt5">{this.state.email ? this.state.email : "-"}</Typography>;
  }

  getCompanyName = () => {
    return <Typography className="mt5">{this.state.company ? this.state.company : "-"}</Typography>;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <CustomDialog
          data-test-id="dialog"
          open={true}
          scroll="body"
          onClose={() => this.props.onClose?.()}
        >
          <Loader loading={this.state.loading} />
          <DialogTitle className="dialog-title">
            <ArrowBackIcon className="arrow-icon" data-test-id="arrow-btn" onClick={() => this.props.onClose?.()} />
            <Typography className="heading">Submit a request</Typography>
            <CloseIcon className="close-icon" data-test-id="close-btn" onClick={() => this.props.onClose?.()} />
          </DialogTitle>
          <Formik
            data-test-id="formik"
            initialValues={{
              name: this.state.name,
              email: this.state.email,
              company: this.state.company,
              jobTitle: this.state.jobTitle,
              subject: "",
              query: "",
            }}
            enableReinitialize
            validationSchema={this.signUpSchema}
            onSubmit={(values) => {
              this.submitContactInfoApi(values);
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className="form"
              >
                <DialogContent className="content">
                  <Box>
                    <Typography variant="body2" className="contact-info">Your contact info</Typography>
                  </Box>
                  <Grid container columnSpacing={1} rowGap={1}>
                    <Grid item xs={12}>
                      <Typography className="label-title">First and last name{!this.state.isUserLogin && "*"}</Typography>
                      {this.state.isUserLogin
                        ? this.getName()
                        : <CustomInput
                          fullWidth
                          data-test-id="name"
                          placeholder="First and last name"
                          onChange={(e) => {
                            setFieldValue("name", e.target.value.trim());
                          }}
                        />}
                      {touched.name && errors.name &&
                        <div className="text-danger">{errors.name}</div>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="label-title">Your email{!this.state.isUserLogin && "*"}</Typography>
                      {this.state.isUserLogin
                        ? this.getEmail()
                        : <CustomInput
                          fullWidth
                          data-test-id="email"
                          placeholder="Your email"
                          onChange={(e) => {
                            setFieldValue("email", e.target.value.trim());
                          }}
                        />}
                      {touched.email && errors.email &&
                        <div className="text-danger">{errors.email}</div>
                      }
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography className="label-title">Company</Typography>
                      {this.state.isUserLogin
                        ? this.getCompanyName()
                        : <CustomInput
                          fullWidth
                          data-test-id="company"
                          placeholder="Company"
                          onChange={(e) => {
                            setFieldValue("company", e.target.value.trim());
                          }}
                        />}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography className="label-title">Job title</Typography>
                      {this.state.isUserLogin
                        ? <Typography className="mt5">{this.state.jobTitle ? this.state.jobTitle : "-"}</Typography>
                        : <CustomInput
                          fullWidth
                          data-test-id="job-title"
                          placeholder="Job title"
                          onChange={(e) => {
                            setFieldValue("jobTitle", e.target.value.trim());
                          }}
                        />}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="label-title" style={{color:'#64748B'}}>Subject</Typography>
                      <CustomInput
                        fullWidth
                        data-test-id="subject"
                        placeholder="Subject"
                        onChange={(e) => {
                          setFieldValue("subject", e.target.value.trim());
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="label-title" style={{color:'#64748B'}}>Query*</Typography>
                      <CustomInput
                        fullWidth
                        rows={3}
                        data-test-id="query"
                        multiline={true}
                        placeholder="Message"
                        onChange={(e) => {
                          setFieldValue("query", e.target.value.trim());
                        }}
                      />
                      {touched.query && errors.query &&
                        <div className="text-danger">{errors.query}</div>
                      }
                    </Grid>
                    <Grid item xs={12} className="send-btn">
                      <SendButton
                        type="submit"
                        fullWidth
                      >
                        Send
                      </SendButton>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog-action">
                  <CancelButton data-test-id="cancel-btn" onClick={() => this.props.onClose?.()}>
                    Cancel
                  </CancelButton>
                  <DoneButton
                    type="submit"
                  >
                    Submit
                  </DoneButton>
                </DialogActions>
              </form>
            )}
          </Formik>
        </CustomDialog>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '720px',
    width: '100%',
    borderRadius: "8px 8px 25px",
    [theme.breakpoints.down('md')]: {
      maxWidth: "80% !important",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
      maxWidth: "100% !important",
      borderRadius: "0px",
      height: "100vh",
    }
  },
  "& .MuiGrid-root > .MuiGrid-item": {
    paddingTop: "7px",
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 16px 10px 30px",
    borderBottom: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 15px 25px 20px",
      borderBottom: "0px",
      justifyContent: "normal",
    },
    "& .heading": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: "14px",
        textAlign: "center",
        textTransform: "uppercase",
      },
    },
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
      [theme.breakpoints.down('sm')]: {
        display: "none"
      },
    },
    "& .arrow-icon": {
      cursor: "pointer",
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block"
      },
    }
  },
  "& .content": {
    padding: "9px 30px 15px !important",
    [theme.breakpoints.down('sm')]: {
      padding: "15px !important",
    },
    "& .mt5": {
      marginTop: "5px",
      fontSize: "16px",
      fontFamily: "Heebo-Regular",
    }
  },
  "& .contact-info": {
    fontSize: "16px",
    color: "#0F172A",
    marginBottom: "15px",
    paddingTop:'20px',
    fontFamily: "Heebo-Regular"
  },
  "& .label-title": {
    fontSize: "14px",
    color: "#9D9D9D",
    fontFamily: "Heebo",
    fontWeight:600,
    padding:'5px 0',
    [theme.breakpoints.down('sm')]: {
      color: "#6A6A6A"
    },
  },
  "& .cool-gray": {
    color: "#64748B"
  },
  "& .dialog-action": {
    padding: "10px 16px 0px",
    borderTop: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  "& .send-btn": {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
  }
}));

const CustomInput = styled(InputBase)({
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
    padding: "7px 8px",
    '&:placeholder':{
      fontWeight:500
    }
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    padding: "5px 8px",
  },
  
});

const CancelButton = styled(Button)({
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});

const SendButton = styled(Button)({
  textTransform: "none",
  marginTop: "50px",
  color: "#64748B !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  fontFamily: "Heebo-Bold",
  borderRadius: "8px !important",
  backgroundColor: "#F0F0F0",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});
// Customizable Area End