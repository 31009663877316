import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Box} from '@mui/material'
import {ArrowBackIosNew, ArrowForwardIos} from '@mui/icons-material';

function SampleNextArrow(props:any) {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className={className}
        style={{ ...style,     borderRadius:'50%',
            color:'black', 
            backgroundColor:"#fff",
            boxShadow: '0px 2px 20px 0px #00000014',
            padding:'15px'}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props:any) {
    const { className, style, onClick } = props;
    return (
                <ArrowBackIosNew  
                onClick={onClick}
              className={className}
                style={{
                    ...style,
                    borderRadius:'50%',
                color:'black', 
                backgroundColor:"#fff",
                boxShadow: '0px 2px 20px 0px #00000014',
                padding:'15px',
                zIndex:10
}}/>
      
    );
  }

const CarouselCustom = ({children}:{children:React.ReactNode[]}) => {
 const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  );
}

export default CarouselCustom