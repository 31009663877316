import React from 'react';

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  styled,
  TextField,
  Divider
} from '@mui/material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import { KeyboardArrowRight } from '@mui/icons-material';
// Customizable Area End

import ClientProfileController, {
  Props,
} from './ClientProfileController';


export default class ClientProfile extends ClientProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      <>
            <Box sx={webStyles.mainWrapper1}>
                <Box sx={webStyles.mainBlock1}>
                    <Sidebar current='clients' />
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex',  alignItems: 'center', columnGap: '10px', justifyContent: 'space-between', padding: '1rem 2rem',}}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography onClick={this.NavigationClient} data-test-id='nav-client' sx={{ fontSize: '24px' }}>Client</Typography>
                                        <KeyboardArrowRight />
                                        <Typography sx={{ fontSize: '24px', fontWeight: 'bolder' }}>{this.state.clientName} Profile</Typography>
                                        <KeyboardArrowRight />
                                    </div>
                                    <BlackButton data-test-id='save-btn' onClick={this.handleSave}>Save</BlackButton>
                                </Box>

                                <Box sx={{ width: '70%', padding: '1rem 2rem' }}>

                                    <div>
                                        <Typography style={{color:'#64748B',fontWeight:'700'}}>COMPANY LOGO</Typography>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                                    <div>
                                        {this.state.companyLogoUrl ? (
                                            <img
                                                src={this.state.companyLogoUrl}
                                                alt="Company Logo"
                                                style={{
                                                    height: '158px',
                                                    width: '155px',
                                                    objectFit: 'contain',
                                                    objectPosition: 'center',
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    height: '158px',
                                                    width: '155px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: '80px',
                                                    backgroundColor: '#ccc',  
                                                    borderRadius: '50%',      
                                                }}
                                            >
                                                {this.state.clientName?.charAt(0).toUpperCase() || ''}
                                            </div>
                                        )}
                                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '1rem 0rem' }}>
                                            <ProfileOption data-test-id='reupload' onClick={this.handleReuploadClick}>Reupload</ProfileOption>
                                            <ProfileOption data-test-id='delete' onClick={this.handleDelete} style={{ color: '#FC5555' }}>Delete</ProfileOption>
                                            <input
                                                data-test-id='img-upload'
                                                type="file"
                                                ref={this.state.fileInputRef}
                                                accept="image/jpeg, image/png"
                                                onChange={this.handleImageUpload}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>

                                        </div>
                                    </div>

                                </Box>
                                <Divider />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        columnGap: '8rem',
                                        margin: '1rem 0',
                                        padding: '0 2rem',
                                        width: '70%'
                                    }}
                                >
                                    <Typography style={{color:'#64748B',fontWeight:'700'}}>COMPANY DETAILS</Typography>
                            <div style={{ width: '60%' }}>
                                <TextWrapper>
                                    <TextHeading>Company name</TextHeading>
                                    <TextField
                                        data-test-id='c-name'
                                        onChange={(event) => this.handleChange(event, 'company-name')}
                                        value={this.state.companyName}
                                        fullWidth
                                        sx={{
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                                '& input': {
                                                    padding: '12px',
                                                },
                                            },
                                        }}
                                    />
                                    {this.state.companyNameError && (
                                        <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                            Company name is required
                                        </p>
                                    )}
                                </TextWrapper>

                                <TextWrapper>
                                    <TextHeading>Contact name</TextHeading>
                                    <TextField
                                        data-test-id='contact-name'
                                        onChange={(event) => this.handleChange(event, 'contact-name')}
                                        value={this.state.contactName}
                                        fullWidth
                                        sx={{
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                                '& input': {
                                                    padding: '12px',
                                                },
                                            },
                                        }}
                                    />
                                    {this.state.contactNameError && (
                                        <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                            Contact name is required
                                        </p>
                                    )}
                                </TextWrapper>

                                <TextWrapper>
                                    <TextHeading>Contact job title</TextHeading>
                                    <TextField
                                        data-test-id='job-name'
                                        onChange={(event) => this.handleChange(event, 'contact-job-title')}
                                        value={this.state.contactJobTitle}
                                        fullWidth
                                        sx={{
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                                '& input': {
                                                    padding: '12px',
                                                },
                                            },
                                        }}
                                    />
                                    {this.state.contactJobTitleError && (
                                        <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                            Job title is required
                                        </p>
                                    )}
                                </TextWrapper>

                                        <TextWrapper>
                                            <TextHeading >Contact email</TextHeading>
                                            <TextField
                                                data-test-id='email'
                                                onChange={this.handleEmailChange}
                                                value={this.state.contactEmail}
                                                fullWidth
                                                sx={{
                                                    backgroundColor: 'white',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                        '& input': {
                                                            padding: '12px',
                                                        },
                                                    },
                                                }}
                                            />
                                            {this.state.emailError && (
                                                <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                                    Please enter a valid email address
                                                </p>
                                            )}
                                        </TextWrapper>
                                        <TextWrapper>
                                    <TextWrapper>
                                        <TextHeading>Contact phone</TextHeading>
                                        <TextField
                                            data-test-id='phone'
                                            onChange={this.handlePhoneChange}
                                            value={this.state.contactNumber}
                                            fullWidth
                                            sx={{
                                                backgroundColor: 'white',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '10px',
                                                    '& input': {
                                                        padding: '12px',
                                                    },
                                                },
                                            }}
                                        />
                                        {this.state.contactNumberError && (
                                            <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                                Phone number must be exactly 12 digits
                                            </p>
                                        )}
                                    </TextWrapper>

                                        </TextWrapper>
                                    </div>

                                </div>
                            </Box>
                </Box>

                <Box sx={webStyles.footerBlock}>
                    <Box sx={webStyles.leftFooter}>
                        <Box>Terms and Conditions</Box>
                        <Box>Pravicy Policy</Box>
                        <Box></Box>
                    </Box>
                    <Box>
                        <Box>© 2024 All Rights Reserved</Box>
                    </Box>
                </Box>
            </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ProfileOption = styled(Typography)({
    textDecoration:'underline',
    fontFamily:'Heebo',
})
const TextWrapper = styled('div')({
    marginBottom: '1rem',
})
const TextHeading = styled(Typography)({
    marginBottom:'5px',
    fontWeight:"bold",
    fontFamily:'Heebo',
    color:'#334155',
    size:'14px',
  })
const BlackButton = styled(Button)({
    textTransform: 'none',
    color: 'white',
    fontFamily: 'Heebo',
    backgroundColor: 'black',
    fontWeight: 400,
    height: 'fit-content',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  })
const webStyles = {
    mainWrapper1: {
        backgroundColor: '#f6f6f6',
        flex: 1,
        flexDirection: 'col',

    },
    mainBlock1: {
        display: 'flex',
        backgroundColor: '#f6f6f6',
        flexDirection: 'row',
    },
    footerBlock: {
        display: 'flex',
        padding: '24px 52px 24px 52px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        color: '#fff',
        backgroundColor: '#1C1C1C',
    },
    leftFooter: {
        gap: '24px',
        display: 'flex',
        flexGrow: 1
    },
}
// Customizable Area End
