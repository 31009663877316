import React from "react";

// Customizable Area Start
import { AppBar, Tab, Tabs, Box, Button, Typography, Select, TextField, FormControl, MenuItem, IconButton, InputAdornment, Modal, Container } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import { KeyboardArrowDown, Search, Close, AddCircleOutline, DeleteOutline, MoreHoriz } from '@mui/icons-material';

// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  UserAnalytics = () => {
    return <div>User Analytics Component</div>;
  };

  CourseProgressAnalytics = () => {
    return <div>Course Progress Analytics Component</div>;
  };

  UsageMetrics = () => {
    return <div>Usage Metrics Component</div>;
  };

  Reports = () => {
    return <div>Reports Component</div>;
  };

  FeedbackAggregation = () => {
    return (
      <Box width={"100%"}>
        <Box display={"flex"} gap={"40px"} alignItems={"center"} height={"60px"}>
          <Box style={{
            width: '75%', height: '100%'
          }}>
            <TextField
              variant="outlined"
              placeholder="Search a user"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid",
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                },
              }}
              value={""}
              data-testID="searchQuery"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Box height={"100%"}>
            <Button
              data-testID="feedbackModal"
              style={{
                fontFamily: 'Heebo', height: '100%',
                display: 'flex', textAlign: 'center', gap: '10px',
                alignItems: 'center', fontWeight: '700',
                padding: '10px 16px', borderRadius: '4px',
                fontSize: '16px', lineHeight: '24px',
                backgroundColor: '#000', color: '#fff',
                textTransform: 'none'
              }}
              onClick={this.handleReviewModalOpen}
            >
              <AddCircleOutline />
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#fff' }}
              >
                Create New Review
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  FeedbackReviewModal = () => {
    return (
      <Modal open={this.state.reviewModal} onClose={this.handleReviewModalClose}>
        <Container
          sx={{
            position: 'absolute',
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0px 8px 32px 0px #0000000F",
            width: '640px',
            minWidth: '380px',
            padding: '24px',
            height: "675px",
            borderRadius: '8px 8px 32px 8px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #E2E8F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              New Feedback Form
            </Typography>
            <IconButton data-testID="close-Modal" onClick={this.handleReviewModalClose}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: 'auto',
            gap: '24px'
          }}>
            <FormControl fullWidth>
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#64748B' }}
              >Course</Typography>
              <Select
                labelId="client-select-label"
                id="client-select"
                placeholder="Select Course"
                value={this.state.selectedCourse.id}
                onChange={this.handleSelectedCourse}
                data-testID="clientSelect"
              >
                {this.state?.courseData?.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
              >Name of Feedback Form</Typography>
              <TextField placeholder="Builder's Feedback Form" data-testID="formName" fullWidth value={this.state.feedbackFormName} onChange={this.handleFeedbackFormName} />
            </Box>
            <Box>
              <Button data-testID="addQuestion" style={{ padding: "0px", display: 'flex', gap: '6px', textTransform: 'none' }} onClick={this.handleAddQuestion}>
                <AddCircleOutline />
                <Typography
                  style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#1C1C1C' }}
                >
                  Add question
                </Typography>
              </Button>
            </Box>

            <Box>
              {this.state.questions.map((question, index) => (
                <Box key={index} display={"flex"} flexDirection={"column"}
                  justifyContent={"space-between"} gap={"24px"} padding={"10px 0px"}>
                  <Typography
                    style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
                  >Question</Typography>
                  <TextField
                    placeholder="Ease of use"
                    data-testID="question"
                    fullWidth
                    value={question.question}
                    onChange={(e) => this.handleQuestionChange(index, 'question', e.target.value)}
                  />
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box display={"flex"} alignItems="center" gap={"10px"}>
                      <Typography
                        style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
                      >Required: </Typography>
                      <Select
                        style={{ border: '1px solid #1C1C1C' }}
                        value={question.required ? 'Yes' : 'No'}
                        onChange={(e) => this.handleQuestionChange(index, 'required', e.target.value === 'Yes')}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </Box>
                    <Box display={"flex"} alignItems="center" gap={"10px"}>
                      <Typography
                        style={{
                          fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155'
                        }}>Type: </Typography>
                      <Select
                        style={{ border: '1px solid #1C1C1C' }}
                        value={question.type}
                        onChange={(e) => this.handleQuestionChange(index, 'type', e.target.value)}
                      >
                        <MenuItem value="free-text">Free text</MenuItem>
                        <MenuItem value="rating">Numeric 1-10</MenuItem>
                      </Select>
                    </Box>
                    <Box>
                      <IconButton data-testID={`delete${index}`} disabled={index == 0} onClick={() => this.handleDeleteQuestion(index)}>
                        <DeleteOutline style={{ color: 'red' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box display={"flex"} gap={"24px"} paddingTop={"10px"} justifyContent={"end"} borderTop="1px solid #E2E8F0">
            <Button
              style={{
                backgroundColor: '#F0F0F0',
                color: '#1C1C1C',
                padding: '16px 32px',
                borderRadius: '8px',
                textTransform: 'none', fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px',
              }}
              data-testID="closeModal"
              onClick={this.handleReviewModalClose}>Cancel</Button>
            <Button style={{
              backgroundColor: '#1C1C1C',
              color: 'white',
              textTransform: 'none',
              padding: '16px 32px',
              borderRadius: '8px', fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px',
            }}
            data-testID="saveBtn"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  tabConfigurations = [
    { label: 'User Analytics', },
    { label: 'Course Progress Analytics', },
    { label: 'Usage Metrics',},
    { label: 'Feedback Aggregation',  },
    { label: 'Reports',  },
  ];

  SelectedComponent = () => {
    switch (this.state.value) {
      case 0: return (
        <>
          {this.UserAnalytics()}
        </>
      )
      case 1: return (
          <>
            {this.CourseProgressAnalytics()}
          </>
        )
      case 2:
        return (
          <>
            {this.UsageMetrics()}
          </>
        )
      case 3:
        return (
          <>
            {this.FeedbackAggregation()}
          </>
        )
      case 4:
        return (
          <>
            {this.Reports()}
          </>
        )
    }
  };

  // Customizable Area End

  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box display={"flex"}>
            <Box>
              <Sidebar navigation={this.props.navigation} current="analytics" />
            </Box>
            <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"16px"} padding={"24px 48px"}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
                <Box display={"flex"} gap="10px" alignItems={"center"}>
                  <Typography
                    style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '24px', lineHeight: '32px', color: '#1C1C1C' }}
                  >Analytics</Typography>
                  <>
                    <Typography
                      style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px', lineHeight: '24px', color: '#475569' }}
                    >Jul 30 - Aug 5</Typography>
                    <KeyboardArrowDown />
                  </>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
                  <Button endIcon={<KeyboardArrowDown />}
                    style={{ fontFamily: 'Heebo', fontWeight: '700', padding: '10px 16px', borderRadius: '4px', fontSize: '16px', lineHeight: '24px', backgroundColor: '#fff', color: '#000', textTransform: 'none' }}
                    variant="outlined"
                  >Generate report</Button>
                  <Button
                    style={{ fontFamily: 'Heebo', fontWeight: '700', padding: '10px 16px', borderRadius: '4px', fontSize: '16px', lineHeight: '24px', backgroundColor: '#000', color: '#fff', textTransform: 'none' }}
                  >Export data</Button>
                  <IconButton><MoreHoriz /></IconButton>
                </Box>
              </Box>
              <Box width={"fit-content"}>
                <AppBar position="static" style={{ width: "100%" }} color="secondary">
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    data-testID="Tabs"
                    aria-label="analytics tabs"
                    textColor="secondary"
                    style={{ gap: '10px' }}
                    TabIndicatorProps={{
                      style: {
                        display: 'none'
                      },
                    }}
                  >
                    {this.tabConfigurations.map((tab, index) => (
                      <Tab
                        key={index}
                        label={
                          <Typography
                            sx={{
                              fontFamily: 'Heebo-bold',
                              fontSize: '16px',
                              fontWeight: '700',
                              lineHeight: '24px',
                              padding: '12px 16px',
                              textTransform: 'none'
                            }}
                          >
                            {tab.label}
                          </Typography>
                        }
                        sx={{
                          backgroundColor: this.state.value === index ? 'black' : 'inherit',
                          color: this.state.value === index ? 'white' : 'black',
                          borderRadius: '4px',
                          '&.Mui-selected': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderBottom: 'none',
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Box>
              <Box paddingTop={"50px"}>
                {this.SelectedComponent()}
              </Box>
            </Box>
          </Box>
          {this.FeedbackReviewModal()}
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
