//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ReactNode } from "react";
import { apiCall } from '../../../components/src/ApiCall.web';

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    children: ReactNode;
    isLessonVisible: boolean;
    isQuizVisible:boolean;
    isPreview: boolean;
    learningPath: string[];
    onPreview: () => void;
    onGoBack: () => void;
    onSaveQuiz: () => void;
    onPublish: (totalHours: number, passingPercentage: number, courseRequirements: string, packageIds: string[]) => void;
    requirement: {
        "duration": number | null,
        "passing_percentage": number | null,
        "course_requirements": string,
    }
    onSaveRequirements?: (values) => void;
    updateAssessmentTitle?: (value) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isPreview: boolean;
    requirementsModal: {
        isVisible: boolean;
    },
    totalHours: number;
    passingPercentage: number;
    courseRequirements: string;
    isPackageDialogOpen: boolean;
    packageList: any;
    packageIds: string[];
    tempIds: string[];
    noOfQuestions: number,
    noOfAnswers: number,
    showAnswer: boolean,
    retakeOption: boolean,
    assessmentTitle: string,
    titleError: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start
interface IValues {
    totalHours: number;
    passingPercentage: number;
    courseRequirements: string;
}
// Customizable Area End

export default class CourseCreationHeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getPackageListApiCallId: string = "";
    postLearningPathApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            // Customizable Area Start
            isPreview: false,
            requirementsModal: {
                isVisible: false
            },
            totalHours: 0,
            passingPercentage: 0,
            courseRequirements: "",
            isPackageDialogOpen: false,
            tempIds: [],
            packageIds:[],
            packageList: [],
            noOfQuestions: 5,
            noOfAnswers: 4,
            showAnswer: false,
            retakeOption: false,
            assessmentTitle: "",
            titleError: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getPackageListApiCallId) {
                    if (responseJson.data) {
                        const packageList = responseJson.data.map((item) => ({ value: item.id, label: item.attributes.title }));
                        this.setState({ packageList, tempIds: this.props.learningPath, packageIds: this.props.learningPath });
                    }
                } else if (apiRequestCallId === this.postLearningPathApiCallId) {
                    this.handleLearningPathResponse(responseJson);
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleLearningPathResponse = (responseJson: { data: { id: string, attributes: { title: string } } } | { error: { title?: string[] } }) => {
        if (responseJson.error && responseJson.error.title[0] === "has already been taken") {
            this.setState({ titleError: "*This title is already present" });
        } else if (responseJson.data) {
            this.setState({
                tempIds: [...this.state.tempIds, responseJson.data.id],
                packageIds: [...this.state.packageIds, responseJson.data.id],
                packageList: [...this.state.packageList, { label: responseJson.data.attributes.title, value: responseJson.data.id }]
            });
        }
    }

    async componentDidMount() {
        this.getLearningPathData();
    }

    componentDidUpdate(prevProps: Props, prevState: S) {
        if (this.props.requirement !== prevProps.requirement) {
          this.setState({
            totalHours: this.props.requirement.duration,
            passingPercentage: this.props.requirement.passing_percentage,
            courseRequirements: this.props.requirement.course_requirements,
          })
        }
        if (this.props.isPreview !== prevProps.isPreview) {
            this.setState({ isPreview: this.props.isPreview });
        }
    }

    onBack = () => {
        if (this.props.isLessonVisible || this.props.isQuizVisible) {
            this.props.onGoBack();
        } else {
            this.props.navigation.navigate("AdminLandingPage");
        }
    }

    openPackageDialog = () => {
        this.setState({ isPackageDialogOpen: true });
    }

    closePackageDialog = () => {
        this.setState({ isPackageDialogOpen: false, tempIds: this.state.packageIds });
    }

    onSavePackageList = () => {
        this.setState({ packageIds: this.state.tempIds, isPackageDialogOpen: false });
    }

    onHideRequirementsModal = () => {
        this.setState({ requirementsModal: { isVisible: false } })
    }

    submitCourseRequirements = (values: IValues) => {
        this.setState({
            totalHours: values.totalHours,
            passingPercentage: values.passingPercentage,
            courseRequirements: values.courseRequirements,
            requirementsModal: { isVisible: false },
        });
    }

    setAssessmentTitle = (value) => {
        this.setState({ assessmentTitle: value });
        this.props.updateAssessmentTitle?.(value);
    }

    setNoOfAnswers = (e) => {
        this.setState({ noOfAnswers: (+e.target.value.trim() >= 0 ? +e.target.value.trim() : 0) });
    }

    setNoOfQuestions = (e) => {
        this.setState({ noOfQuestions: (+e.target.value.trim() >= 0 ? +e.target.value.trim() : 0) });
    }

    submitAssessmentRequirement = (values: any) => {
        this.setState({ requirementsModal: false });
        this.props?.onSaveRequirements?.(values);
    }

    handleNumericInput = (e: React.FormEvent<HTMLDivElement>) => {
        const inputElement = e.target as HTMLInputElement;
        const value = inputElement.value;

        if (!/[0-9]/.test(value)) {
            inputElement.value = value.replace(/\D/g, '');
        }
    };

    handleValdidateMaxValue = (e: React.FormEvent<HTMLDivElement>) => {
        const inputElement = e.target as HTMLInputElement;
        const value = inputElement.value;
        try {
            if (+value > 100) {
                inputElement.value = 100
            }
        } catch (error) {
            console.error(error)
        }
    }

    getLearningPathData = async () => {
        this.getPackageListApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_categories/learning_paths`,
        });
    }

    submitLearningPath = async (values: { title: string, description: string }) => {
        this.postLearningPathApiCallId = await apiCall({
            method: "POST",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_categories/learning_paths`,
            body: {
                "learning_path": {
                    "title": values.title,
                    "description": values.description
                }
            },
        });
    }
    // Customizable Area End
}
