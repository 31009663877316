import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { apiCall } from '../../../components/src/ApiCall.web';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    anchorEl:any
    courseDetail:any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CourseDescriptionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCourseDetailDataApiCall:string =''
    
//    async componentDidUpdate()  {
//     const param = this.props.navigation.getParam('courseId')
//     if(this.state.courseId) return   
//     this.setState({courseId:param})
//     this.handleGetCourseDetail()
//     }
   
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            anchorEl:null,
            courseDetail:undefined
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
             if (apiRequestCallId === this.getCourseDetailDataApiCall) {
                return this.handleGetCourseDescriptionResponse(responseJson)
                
               
             }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.handleGetCourseDetail()
    }
    handleGetCourseDescriptionResponse=(res:any)=>{
        this.setState({courseDetail:res.data})
        console.log(this.state?.courseDetail?.attributes?.images[0]?.url,"url")
    }
    handleGetCourseDetail = async ()=>{
        const routeName = this.props.navigation.state.routeName.split('/')
        const courseId = routeName[routeName.length-1]
            this.getCourseDetailDataApiCall = await apiCall({
                method: 'GET',
                contentType: 'application/json',
                endPoint: `bx_block_admin/courses/get_course?id=${courseId}`,
                token: localStorage.getItem('token'),
              });
    }

    handleResumeCourse =()=>{
        const {courseDetail} = this.state

        const courseId = this.props.navigation.getParam('courseId')
        const courseModuleId = courseDetail.attributes.course_modules[0].id
        this.props.navigation.navigate('CourseLearning',{path:{courseId,courseModuleId}})
    }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onLogout = () => {
    const { setAccessPermission } = this.context as any;
    setAccessPermission?.(false);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("p");
    localStorage.removeItem("u");
    this.props.navigation.replace("EmailAccountRegistration")
    this.handleClose()
  }

  myLearning = ()=>{
    this.props.navigation.navigate("MyLearning")
  }
    // Customizable Area End
}
