import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { apiCall } from "../../../../packages/components/src/ApiCall.web";
import { SelectChangeEvent } from '@mui/material/Select';
import React from "react";
import { toast } from "react-toastify";

interface Course {
  id: string,
  name: string
}

interface Question {
  question: string,
  required: boolean,
  type: string
}

type QuestionField = 'question' | 'required' | 'type';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number,
  txtInputValue: string,
  reviewModal: boolean,
  courseData: Course[],
  selectedCourse: Course,
  feedbackFormName: string,
  questions: Question[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCoursesApiCallId: string = ""
  saveApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      , getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      value: 3,
      txtInputValue: "",
      reviewModal: false,
      courseData: [],
      selectedCourse: {
        id: "",
        name: ""
      },
      feedbackFormName: "",
      questions: [{ question: '', required: true, type: 'rating' }]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCoursesApiCallId) {
      this.handleGetCoursesResponse(responseJson)
    }
    else if (apiRequestCallId === this.saveApiCallId) {
      this.handleReviewModalClose()
      if (responseJson.errors) {
        toast.error(responseJson.errors[0])
      } else {
        toast.success("Feedback form created successfully")
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getCourses()
  }

  handleChange = (event: React.SyntheticEvent, value: any) => {
    this.setState({ value: value });
  };

  handleReviewModalOpen = () => {
    this.setState({ reviewModal: true })
  }

  handleReviewModalClose = () => {
    this.setState({
      reviewModal: false,
      feedbackFormName: "",
      questions: [{ question: "", required: false, type: "rating" }],
      selectedCourse: { id: '', name: "" }
    })
  }

  getCourses = async () => {
    this.getCoursesApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses`,
    });
  }

  handleGetCoursesResponse = (response: any) => {
    this.setState({
      courseData: response?.data?.map((course: any) => ({
        id: course.id,
        name: course.attributes.title
      }))
    })
  }
  handleSelectedCourse = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const selectedId = event.target.value
    const selectedClient = this.state.courseData.find(c => c.id === selectedId);

    if (selectedClient) {
      this.setState({
        selectedCourse: {
          id: selectedClient.id,
          name: selectedClient.name,
        },
      });
    }
  };

  handleFeedbackFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ feedbackFormName: event.target.value })
  }

  handleAddQuestion = () => {
    this.setState(prevState => ({
      questions: [...prevState.questions, { question: '', required: true, type: 'rating' }]
    }));
  };
  handleQuestionChange = (index: number, field: keyof Question, value: string | boolean) => {
    const questions = [...this.state.questions];
    const newValue = value
    if (
      (field === "question" && typeof value === "string") ||
      (field === "type" && typeof value === "string")
    ) {
      questions[index][field] = value;
    } else if (
      (field === "required" && typeof value === "boolean")) {
      questions[index][field] = newValue as boolean;
    }

    this.setState({ questions });
  };

  handleDeleteQuestion = (index: number) => {
    const questions = this.state.questions.filter((_, i) => i !== index);
    this.setState({ questions });
  };

  getQuestionType = (type: string) => {
    const typeMapping: { [key: string]: number } = {
      'rating': 0,
      'free-text': 1,
    };
    return typeMapping[type];
  }

  handleSave = async () => {
    const { selectedCourse, feedbackFormName, questions } = this.state;

    const body = {
      "feedback_form": {
        "course_id": selectedCourse.id,
        "name": feedbackFormName,
        "admin_user": 1,
        "feedback_questions_attributes": questions.map((q) => ({
          "question": q.question,
          "question_type": this.getQuestionType(q.type),
        })),
      },
    }

    this.saveApiCallId = await apiCall({
      method: "POST",
      contentType: 'application/json',
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_profile/feedback_forms`,
      body: body
    });
  }

  // Customizable Area End
}
