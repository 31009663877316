import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  showEventDetailsDialog:boolean,
  currentMonthYear:string,
  showSelectAttendeesDialog:boolean,
  selectedClient:any,
  anchorEl:any,
  clientSearch:string,
  eventTitle:string,
  eventLocation:string,
  selectedTimeZone:string,
  selectedTab:number,
  calendarRef:any,
  events:any,
  showEditEvent:boolean,
  selectedEvent:any,
  eventDate:string,
  timeStart:string,
  timeEnd:string,
  eventLink:string,
  notificationTime:string,
  ClientList:any,
  upcomingEvents:any,
  pastEvents:any,
  jobRoles:string[],
  selectedJobRoles:any,
  selectedClients:any,
  isEditingEvent:boolean,
  allUsers:any,
  selectedUsers:any,
  filteredUsers:any,
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getAllEventsApiCallId = "";
  getAllUsersApiCallId = "";
  deleteEventApiCallId = "";
  getClientListCallId = "";
  createEventApiCallId="";
  updateEventApiCallId ="";
  // calendarRef: React.RefObject<FullCalendar>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      showEventDetailsDialog:false,
      showSelectAttendeesDialog:false,
      currentMonthYear:'',
      selectedClient:[],
      anchorEl:null,
      clientSearch:'',
      eventTitle:'',
      eventLocation:'',
      selectedTimeZone:'',
      selectedTab:2,
      calendarRef: React.createRef(),
      events:[],
      showEditEvent:false,
      selectedEvent:null,
      eventDate:'',
      timeStart:'',
      eventLink:'',
      timeEnd:'',
      notificationTime:'',
      ClientList:[],
      upcomingEvents:[],
      pastEvents:[],
      selectedJobRoles:[],
      jobRoles: [
        'Developer',
        'Designer',
        'Product Manager',
        'QA Engineer',
        'DevOps',
        'Data Scientist',
        'Business Analyst',
        'UX Researcher',
        'Systems Architect',
        'Technical Writer',
        'Scrum Master',
        'Marketing Specialist',
        'Sales Engineer',
        'Security Analyst',
        'Support Engineer',
        'Human Resources Manager',
        'Talent Acquisition Specialist'
    ],
    selectedClients:[],
    isEditingEvent:false,
    allUsers:[],
    selectedUsers:[],
    filteredUsers:[],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    
    await Promise.all([
        this.getAllEvents(),
        this.getClientList(),
        this.updateMonthYear(),
        this.getAllUsers()
    ]);
}


  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      

      if (apiRequestCallId === this.getAllEventsApiCallId) {
        this.recieveGetAllEventApiResponse(responseJson);
      }
      if (apiRequestCallId === this.deleteEventApiCallId) {
        this.receiveEventDeleteResponse(responseJson);
      }
      if(apiRequestCallId === this.getClientListCallId)
      {
        this.receiveGetClientListResponse(responseJson)
      }
      if (apiRequestCallId === this.createEventApiCallId) {
        this.receiveCreateEventResponse(responseJson)
      }
      if (apiRequestCallId === this.updateEventApiCallId) {
        this.receiveUpdateEventApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAllUsersApiCallId) {
        this.receiveGetAllUsersApiResponse(responseJson)
      }
      
    }
    
  };
  handleShowEventDetailsDialog = () => {
    
    this.setState({ showEventDetailsDialog: true });
    this.setState({showSelectAttendeesDialog:false});
    this.getAllUsers()
  };
  updateMonthYear = () => {
    const calendarApi = this.state.calendarRef.current.getApi();
    const currentDate = calendarApi.getDate(); 
    const month = currentDate.toLocaleString('default', { month: 'long' }); 
    const year = currentDate.getFullYear(); 

    this.setState({ currentMonthYear: `${month} ${year}` });
  };
  handleCloseEventDetailsDialog = () => {
    this.setState({ showEventDetailsDialog: false });
    this.setState({showSelectAttendeesDialog:false});
    this.clearCachedStates();
  };
  handleShowSelectAttendeeDialog = () => {
    this.setState({ showEventDetailsDialog: true });
  };

  handleCloseSelectAttendeeDialog = () => {
    this.setState({ showSelectAttendeesDialog: false });
  };
  handleEventDetailsSave = () => {
    const { timeStart, timeEnd, notificationTime } = this.state;
    

    const timeStartObj = new Date(`1970-01-01T${timeStart}:00Z`);
    const timeEndObj = new Date(`1970-01-01T${timeEnd}:00Z`);
    const notificationTimeObj = new Date(`1970-01-01T${notificationTime}:00Z`);

    if (timeStartObj > timeEndObj) {
      toast.error("Start time must be earlier than end time.");
      return;
    }
    if (notificationTimeObj >= timeStartObj) {
      toast.error("Notification time must be earlier than start time.");
      return;
    }
    this.setState({ showEventDetailsDialog: false });
    this.setState({ showSelectAttendeesDialog: true });
  }
  handleClientChange = (event:any, newValue:any) => {
    this.setState({ selectedClient: newValue });
  };
  handleAddClientClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleAddClientClose = () => {
    this.setState({ anchorEl: null });
    
  };
  handleInputChange = (event: any) => {
    this.setState({ clientSearch: event.target.value });
  };
  handleClientSelect = (client:any) => {
    this.setState({ selectedClient: client, anchorEl: null });
  };
  clearCachedStates = () => {
    this.setState({
      eventTitle:'',
      eventDate:'',
      timeStart:'',
      timeEnd:'',
      eventLink:'',
      eventLocation:'',
      notificationTime:'',
      selectedTimeZone:'',
      selectedClients:[],
      selectedJobRoles:[],
      filteredUsers:[],
    })
  }
  filterUsersByClients = (selectedClients: any) => {
    const numericClients = selectedClients.map((clientId: any) => parseInt(clientId, 10));
    const filteredUsers = this.state.allUsers.filter((user: any) =>
      numericClients.includes(user.companyId)
    );
    const selectedUserIds = filteredUsers.map((user:any) => user.id);
    this.setState({
      filteredUsers,
      selectedUsers: selectedUserIds 
    });
    this.setState({ filteredUsers });
  };
  
  handleUserToggle = (event: any, userId: number) => {
    const { selectedUsers } = this.state;
    const newSelectedUsers = event.target.checked
      ? [...selectedUsers, userId]
      : selectedUsers.filter((id: number) => id !== userId);
    this.setState({ selectedUsers: newSelectedUsers });
  };
  handleClientToggle = (client: any) => {
    const { selectedClients } = this.state;
    if (selectedClients.includes(client.companyId)) {
      this.setState({ selectedClients: selectedClients.filter((id: number) => id !== client.companyId) }, () => this.filterUsersByClients(this.state.selectedClients));
    } else {
      this.setState({ selectedClients: [...selectedClients, client.companyId] }, () => this.filterUsersByClients(this.state.selectedClients));
    }

  };
  handleChange = (event:any, field:string) => {
    if(field === 'title')
    {
      this.setState({eventTitle:event.target.value})
    }
    if(field === 'location')
    {
      this.setState({eventLocation:event.target.value})
    }
    if(field === 'event-link')
    {
      this.setState({eventLink:event.target.value})
    }
    if(field === 'date')
    {
      this.setState({eventDate:event.target.value});
    }
  }
  handleTimeChange = (event:any,field:string) => {
    if(field === 'time-start')
    {
      this.setState({timeStart:event.target.value})
    }
    if (field === 'time-end') {
      this.setState({ timeEnd: event.target.value })
    }
    if (field === 'notification-time') {
      this.setState({ notificationTime: event.target.value })
    }
  }
  handleSelectTimeZone = (event:any) => {
    this.setState({selectedTimeZone:event.target.value})
  }
  handleSelectJobRole = (event:any) => {
    this.setState({selectedJobRoles:event.target.value})
  }
  handleSelectUser = (event:any) => {

  }
  
  handleTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
    const calendarApi = this.state.calendarRef.current!.getApi();
    switch (newValue) {
      case 0:
        calendarApi.changeView('timeGridDay'); 
        break;
      case 1:
        calendarApi.changeView('timeGridWeek'); 
        break;
      case 2:
        calendarApi.changeView('dayGridMonth'); 
        break;
      default:
        break;
    }
    this.updateMonthYear()
  };
  handlePrevClick = () => {
    const calendarApi = this.state.calendarRef.current!.getApi();
    calendarApi.prev(); 
    this.updateMonthYear()
  };

  handleNextClick = () => {
    const calendarApi = this.state.calendarRef.current!.getApi();
    calendarApi.next(); 
    this.updateMonthYear()
  };
  
  getAllEvents = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.getAllEventsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_calendar/events"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEventsMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  organizeEventsByStatus = (data: any) => {
    const upcomingEvents: any = [];
    const pastEvents: any = [];

    data.forEach((event: any) => {
      const { title, date, start_time, end_time, account_details, status, admin_user_details } = event.attributes;
      const totalAttendees = account_details.length;
      const host = admin_user_details.full_name;

      const eventObject = {
        title,
        date,
        start_time,
        end_time,
        total_attendees: totalAttendees,
        host
      };

      if (status === "upcomming_events") {
        upcomingEvents.push(eventObject);
      } else if (status === "past_events") {
        pastEvents.push(eventObject);
      }
    });

    this.setState({ upcomingEvents, pastEvents });
  };

  recieveGetAllEventApiResponse = (response: any) => {
    const clientIds:any = [];
    const accountIds:any = [];

    response.data.forEach((event:any) => {
      event.attributes.client_details.forEach((client:any) => {
        clientIds.push(client.id);
      });
      event.attributes.account_details.forEach((account:any) => {
        accountIds.push(account.id);
      });
    });
    
    const events = response.data.map((item:any) => {
      const { attributes } = item;
      return {
        id: attributes.id, 
        title: attributes.title, 
        start: attributes.start_date, 
        end: attributes.end_date, 
        url: attributes.event_link, 
        date:attributes.date,
        extendedProps: {
          location: attributes.location, 
          status: attributes.status, 
          job_role: attributes.job_role, 
          notification_time: attributes.notification_time, 
          admin_user_id: attributes.admin_user_id,
          client_ids: clientIds,
          account_ids: accountIds,
          time_zone: attributes.time_zone,
          start_time: attributes.start_time, 
          end_time: attributes.end_time 
        }
      };
    });
    this.setState({events:events})
    this.organizeEventsByStatus(response.data);
  }
  
  getAllUsers = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllUsersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/user_managements/get_all_users"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEventsMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveGetAllUsersApiResponse = (response: any) => {
    if (response.errors) {
      toast.error(response.errors)
      return;
    }
    const extractedData = response.data.map((user: any) => ({
      id: user.id,
      companyId: user.attributes.client_id,
      first_name: user.attributes.first_name,
      last_name: user.attributes.last_name,
      company_name: user.attributes.company_name
    }));
    
    this.setState({allUsers:extractedData})
  }



  deleteEvent = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteEventApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_calendar/events/${this.state.selectedEvent.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteEventMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveEventDeleteResponse = (response:any) => {
    
    if(response.errors)
    {
      toast.error(response.message)
      return;
    }
    toast.success(response.message)
    this.handleCloseEditEvent()
    this.getAllEvents()
  }
  getClientList = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_account_groups/clients"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEventsMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveGetClientListResponse = (response: any) => {
    const extractedData = response.data.map((item: any) => ({
      companyId: item.id,
      companyName: item.attributes.company_name
    }));
    this.setState({ ClientList: extractedData })
  }
  createEvent = () => {
    const reqBody = {
      "event": {
        "title": this.state.eventTitle,
        "date": this.state.eventDate,
        "start_time": this.state.timeStart,
        "end_time": this.state.timeEnd,
        "time_zone": this.state.selectedTimeZone,
        "event_link": this.state.eventLink,
        "location": this.state.eventLocation,
        "notification_time": this.state.notificationTime,
        "job_role": this.state.selectedJobRoles,
        "start_date": this.state.eventDate,
        "end_date": this.state.eventDate,
        "client_ids": this.state.selectedClients,
        "account_ids": this.state.selectedUsers
      }
    }
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.createEventApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_calendar/events"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createEventMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveCreateEventResponse = (response:any) => {
    if(response.errors)
    {
      toast.error(response.errors[0]);
      return
    }
    toast.success("Event created succesfully")
    this.getAllEvents()
    this.setState({showSelectAttendeesDialog:false})
    this.setState({showEventDetailsDialog:false});
    this.clearCachedStates();
  }
  updateEvent = () => {
    const reqBody = {
      "event": {
        "title": this.state.eventTitle,
        "date": this.state.eventDate,
        "start_time": this.state.timeStart,
        "end_time": this.state.timeEnd,
        "time_zone": this.state.selectedTimeZone,
        "event_link": this.state.eventLink,
        "location": this.state.eventLocation,
        "notification_time": this.state.notificationTime,
        "job_role": this.state.selectedJobRoles,
        "start_date": this.state.eventDate,
        "end_date": this.state.eventDate,
        "client_ids": this.state.selectedClients,
        "account_ids": this.state.selectedUsers,
      }
    }
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateEventApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_calendar/events/${this.state.selectedEvent.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateEventMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveUpdateEventApiResponse = (response:any) => {
    if(response.errors)
    {
      toast.error(response.errors[0])
      return;
    }
    this.setState({showSelectAttendeesDialog:false})
    this.clearCachedStates();
    toast.success("Event updated successfully")
    this.getAllEvents()
  }
  formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().slice(11, 16); // Extracts HH:MM
  }
  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = date.getUTCFullYear();
    return `${year}-${month}-${day}`;
  }
  formatSelectedClients = (clients: any, selectedClientIds: any) => {
    const selectedClients = clients.filter((client: any) => selectedClientIds.includes(client.companyId));
    if (selectedClients.length === 0) {
      return "No clients selected";
    }
    const firstSelectedClientName = selectedClients[0].companyName;
    const remainingCount = selectedClients.length - 1;
    return remainingCount > 0
      ? `${firstSelectedClientName} and +${remainingCount}`
      : firstSelectedClientName;
  };
  handleEventClick = (eventInfo: any) => {
    eventInfo.jsEvent.preventDefault();
    const extractedInfo = {
      id: eventInfo.event.id,
      title: eventInfo.event.title,
      date:eventInfo.event.date,
      start: this.formatDate(eventInfo.event.start),
      end: this.formatDate(eventInfo.event.end),
      notification: this.formatTime(eventInfo.event.extendedProps.notification_time),
      url: eventInfo.event.url,
      clientId: eventInfo.event.extendedProps.client_ids,
      timeZone: eventInfo.event.extendedProps.time_zone,
      startTime: this.formatTime(eventInfo.event.extendedProps.start_time),
      endTime: this.formatTime(eventInfo.event.extendedProps.end_time),
      location: eventInfo.event.extendedProps.location,
      totalUser:eventInfo.event.extendedProps.account_ids.length,
      clients:this.formatSelectedClients(this.state.ClientList,eventInfo.event.extendedProps.client_ids.map(String)),
      users:eventInfo.event.extendedProps.account_ids
    };
    this.setState({selectedEvent:extractedInfo})
    this.setState({showEditEvent:true})
  }
  handleCloseEditEvent = () => {
    this.setState({showEditEvent:false});
    this.setState({isEditingEvent:false});
    this.clearCachedStates();
  }
  handleEditEventClick = () => {
    
    this.setState({showEditEvent:false});
    this.setState({isEditingEvent:true});
    this.setState({eventDate:this.state.selectedEvent.start});
    this.setState({eventTitle:this.state.selectedEvent.title});
    this.setState({timeStart:this.state.selectedEvent.startTime});
    this.setState({timeEnd:this.state.selectedEvent.endTime});
    this.setState({eventLink:this.state.selectedEvent.url});
    this.setState({eventLocation:this.state.selectedEvent.location});
    this.setState({selectedTimeZone:this.state.selectedEvent.timeZone})
    this.setState({notificationTime:this.state.selectedEvent.notification})
    this.setState({selectedClients:this.state.selectedEvent.clientId.map(String)},() => this.filterUsersByClients(this.state.selectedClients))
    this.setState({filteredUsers:this.state.filteredUsers});
    this.setState({showEventDetailsDialog:true})
  }
  handlePublishEventClick = () => {
    if(this.state.isEditingEvent)
    {
      this.updateEvent();
      return;
    }
    this.createEvent();
  }
  // Customizable Area End
}
