import React from 'react';

// Customizable Area Start
import {
  Modal,
  FormControl,
  Select,
  MenuItem,
Menu,
  Container,
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  Typography,
  InputLabel,
  List,
  Pagination,
  TableFooter,
  TextField,
  styled,
  InputAdornment,
  Popover
} from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { Search } from '@mui/icons-material';
import { ExportIcon, DeselectIcon } from './assets';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Account, Group, GroupAccount } from './types';
import FilterListIcon from '@mui/icons-material/FilterList';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Sidebar from "./../../../components/src/sidebar/Sidebar.web";
const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from './AccountGroupsController';

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentPage, itemsPerPage, accountsData } = this.state;
    const totalPages = Math.ceil(accountsData.length / itemsPerPage);
    const startResult = currentPage * itemsPerPage + 1;
    const endResult = Math.min(startResult + itemsPerPage - 1, accountsData.length);
    const open = Boolean(this.state.filterAchorEl); 
    const id = open ? 'filter-popover' : undefined;
    const getFontWeight = (isSelected: boolean) => {
      return isSelected ? '700' : '400';
    };
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2); 
      return `${day}.${month}.${year}`;
    };
    
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={webStyles.mainWrapper}>
            <Box sx={webStyles.mainBlock}>


            <Sidebar navigation={this.props.navigation} current='clients' />

              <Box sx={webStyles.pageContent} data-testid="pageContent">
                <Box sx={webStyles.contentHeader}>
                  <Box sx={webStyles.navigation}>
                    <Box sx={webStyles.breadcrumbButton}>Clients</Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Button
                        startIcon={<img src={ExportIcon} />}
                        disableElevation
                        variant="contained"
                        style={{ backgroundColor: "white", color: "black", marginRight: '10px', width: "111px", height: '44px',textTransform:'none',borderRadius:'8px' }}
                      > 
                        Export
                      </Button>

                      <Button
                        disableElevation
                        variant="contained"
                        style={{ backgroundColor: '#1C1C1C', width: "169px", height: '44px', color: 'white',whiteSpace: 'nowrap',textTransform:'none',borderRadius:'8px' }}
                        startIcon={<GroupAddIcon />}
                      >
                        Add New Client
                      </Button>

                    </Box>
                  </Box>
                </Box>
                <TextField
                  
                  InputProps={{
                    
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    sx: { backgroundColor: 'white',padding:'5px',paddingLeft:'20px' } 
                  }}
                  variant="outlined"
                  size="small"
                  placeholder="Search for a specific client"
                  sx={{ width: "55%", height: "50px", marginLeft: "4px",}}
                  value={this.state.searchQuery}
                  onChange={this.handleSearchChange}/>
                <Box display="flex" alignItems="center" justifyContent="space-between"  p={[0, 3]} bgcolor="#f5f5f5">
                  <Box display="flex" alignItems="center">
                    {this.state.selectedCount > 0 && (
                      <>
                        <img onClick={this.deselectAll} src={DeselectIcon} style={{marginRight:'10px'}} />
                        <Typography>{this.state.selectedCount} item selected</Typography>
                        <ArchiveButton 
                        variant="contained"
                        disableElevation
                        >
                          Archive
                        </ArchiveButton>
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: "center" }}>

                    <Box data-test-id="show-filter" onClick={this.showFilter} display="flex" alignItems="center" sx={{ marginRight: "20px" }}>
                      <Typography>Filter</Typography>
                      <IconButton aria-label="filter">
                        <FilterListIcon />
                      </IconButton>
                    </Box>
                    <Popover
                      data-test-id='filter-close'
                      style={{boxShadow: '0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008'}}
                      id={id}
                      open={open}
                      anchorEl={this.state.filterAchorEl}
                      onClose={this.handleFilterClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Box sx={{padding:'10px 14px',width:'200px',}}>
                        <Typography variant="body1">Status</Typography>
                      </Box>
                    </Popover>

                    <div style={{ display: 'flex', alignItems: 'center', }}>
                      <Typography>Sort by:</Typography>
                      <Select
                        data-testid="sort-select"
                        value={this.state.sortBy}
                        onChange={(e: any) => this.handleSortChange(e)}
                        defaultValue="relevance"
                        disableUnderline
                        variant='standard'
                        sx={{
                          paddingLeft: '10px',
                          width: '150px',
                          backgroundColor: 'transparent',
                          '& .MuiSelect-icon': {
                            color: 'gray',
                          },
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            '& input': {
                              backgroundColor: 'transparent',
                              padding: ''
                            },
                          },
                        }}
                      >
                        <MenuItem value="A-Z">Name (A-Z)</MenuItem>
                        <MenuItem value="Z-A">Name (Z-A)</MenuItem>
                        <MenuItem value="old-new">Oldest to newest</MenuItem>
                        <MenuItem value="new-old">Newest to oldest</MenuItem>
                        <MenuItem value="status">Status</MenuItem>
                      </Select>
                    </div>
                  </Box>
                </Box>
                <Container style={{paddingLeft:'4px'}} maxWidth={false} sx={{ width: "100%", }}>

                  <Paper style={webStyles.tableViewStyle}>
                    <TableContainer style={webStyles.tableContainerStyle}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>   </TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderCompany}</TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderAdminUser}</TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderPackages}</TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderTotalNumberOfUsers}</TableCell>
                            <TableCell sx={{textAlign:"center",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderDateCreated}</TableCell>
                            <TableCell sx={{textAlign:"center",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderStatus}</TableCell>
                            <TableCell sx={{textAlign:"right",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {accountsData.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage).map((account:any) => (
                            <TableRow
                              key={account.id}
                              style={{
                                backgroundColor: account.isSelected ? '#f8fafc' : '#FFFFFF',
                              }}
                            >

                              <TableCell sx={{textAlign:"left",}}>
                                <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: 'black', 
                                    borderRadius: '6px', 
                                    '&:hover': {
                                     backgroundColor: 'rgba(0, 0, 0, 0.08)', 
                                     },},
                                }}    
                                  checked={account.isSelected}
                                  onChange={() => this.handleCheckboxChange(account.id)}
                                  data-testid={`checkbox-${account.id}`}
                                />
                              </TableCell>

                              <TableCell sx={{textAlign:"left",fontWeight: getFontWeight(account.isSelected)}}>
                                {account.attributes.company_name}
                              </TableCell>
                              <TableCell sx={{textAlign:"left",fontWeight: getFontWeight(account.isSelected)}}>{account.attributes.contact_name}</TableCell>
                              <TableCell sx={{textAlign:"left",fontWeight: getFontWeight(account.isSelected)}} >Premium</TableCell>
                              <TableCell sx={{textAlign:"center",fontWeight: getFontWeight(account.isSelected)}}>10</TableCell>

                              <TableCell sx={{ textAlign: "center", fontWeight: getFontWeight(account.isSelected) }}>
                                {formatDate(account.attributes.created_at)}
                              </TableCell>


                              {account.isSelected !== true ? (<TableCell sx={{ textAlign: 'right' }}><span style={{ padding: '5px 10px', color: '#78716C', backgroundColor: '#E7E5E4', borderRadius: '12px',fontFamily:'Heebo-bold' }}>ARCHIVE</span></TableCell>)
                                : (<TableCell sx={{fontFamily:'Heebo-bold', textAlign: 'right' }}><span style={{ padding: '5px 10px', color: 'white', backgroundColor: 'black', borderRadius: '12px' }}>ACTIVE</span></TableCell>)
                              }
                              
                              
                              <TableCell sx={{textAlign:"right"}}>
                                <IconButton
                                  onClick={(event) => this.handleMenuOpen(event, account.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>

                                <Menu
                                  anchorEl={this.state.anchorEl[account.id]}
                                  open={Boolean(this.state.anchorEl[account.id])}
                                  onClose={() => this.handleMenuClose(account.id)}
                                  sx={{ml:'4px'}}
                                  anchorOrigin={{
                                    vertical: 'center', 
                                    horizontal: 'left', 
                                  }}
                                  transformOrigin={{
                                    vertical: 'top', 
                                    horizontal: 'right', 
                                  }}
                                  PaperProps={{
                                    style: { width: '200px' }, 
                                  }}
                                >
                                  <MenuItem onClick={() => this.handleClientClick(account.id)}>View</MenuItem>
                                  <MenuItem data-test-id={`edit-client-${account.id}`} onClick={() => this.handleEditClientClick(account.id)}>Edit</MenuItem>
                                  <MenuItem sx={{color:'#DC2626'}} onClick={() => this.handleMenuClose(account.id)}>Delete</MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <div style={{ flex: 1 }}></div>

                                <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                  <Pagination
                                    count={Math.ceil(accountsData.length / itemsPerPage)}
                                    page={currentPage + 1}
                                    onChange={(event, value) => this.handleChangePage(null, value - 1)}
                                    siblingCount={1}
                                    boundaryCount={1}

                                  />
                                </div>

                                <div style={{ flex: 1, textAlign: 'right' }}>
                                  {`${startResult}-${endResult} of ${accountsData.length} results`}
                                </div>

                              </div>
                            </TableCell>
                          </TableRow>
                        </TableFooter>

                      </Table>
                    </TableContainer>
                  </Paper>
                </Container>
              </Box>



              <Modal open={this.state.isVisibleModal} onClose={this.hideModal}>
                <Box sx={webStyles.modalStyle}>
                  {this.state.fieldError && (
                    <p style={webStyles.errorMsg}>
                      {configJSON.errorAllFieldsAreMandatory}
                    </p>
                  )}
                  <Box sx={webStyles.modalRowViewStyle}>
                    <Input
                      data-test-id={'inputName'}
                      placeholder={configJSON.textName}
                      defaultValue={this.state.editMode ? this.state.name : ''}
                      onChange={(e) => this.handleInputName(e.target.value)}
                    />
                    <Button
                      data-test-id="btnAddGroup"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.state.editMode
                          ? this.editGroup(this.state.id)
                          : this.addGroup();
                      }}
                    >
                      {this.state.editMode
                        ? configJSON.textUpdate
                        : configJSON.textSave}
                    </Button>
                  </Box>

                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnHideModal"
                      variant="contained"
                      onClick={this.hideModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              
              <Modal
                open={this.state.isVisibleAddAccountModal}
                onClose={this.hideAddAccountModal}
              >
                <Box sx={webStyles.modalStyle}>
                  <Box sx={webStyles.modalRowViewStyle}>
                    <p>{this.state.name}</p>
                  </Box>
                  <Box sx={webStyles.dropdownViewStyle}>
                    <p>{configJSON.textSelectAccounts}</p>
                    <IconButton
                      data-test-id={'btnExpandAccountsView'}
                      onClick={this.expandAccountsView}
                      edge="end"
                      size="large"
                    >
                      {this.state.dropdownAccountStatus ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </Box>
                  {this.state.dropdownAccountStatus && (
                    <Paper style={webStyles.dropdownListContainer}>
                      <List>
                        {this.state.modalAccData.map(
                          (account: Account, index: number) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                              <ListItem
                                key={account.id}
                                data-test-id={'listItem' + index}
                                dense
                                button
                                onClick={() =>
                                  this.handleAccountSelect(account.id)
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={account.isSelected}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    account.attributes.first_name! + account.attributes.last_name!
                                  }
                                />
                              </ListItem>
                            );
                          }
                        )}
                      </List>
                    </Paper>
                  )}

                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnSaveAccountsToGroup"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.handleSaveAccountsToGroup(this.state.id)
                      }
                    >
                      {configJSON.textSave}
                    </Button>
                    <Button
                      data-test-id="btnCloseAddAccountModal"
                      variant="contained"
                      onClick={this.hideAddAccountModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              
              <Modal
                open={this.state.isVisibleDeleteAccountModal}
                onClose={this.hideDeleteAccountModal}
              >
                <Box sx={webStyles.modalStyle}>
                  <Box sx={webStyles.modalRowViewStyle}>
                    <p>{this.state.name}</p>
                  </Box>
                  <Paper style={webStyles.dropdownListContainer}>
                    <List>
                      {this.state.selectedAccounts.map(
                        (account: GroupAccount, index: number) => {
                          const labelId = `checkbox-list-label-${index}`;
                          return (
                            <ListItem
                              key={account.id}
                              data-test-id={'deleteListItem' + index}
                              dense
                              button
                              onClick={() =>
                                this.handleDeleteAccountSelect(account.id)
                              }
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={account.isSelected}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={
                                  account.first_name + " " + account.last_name
                                }
                              />
                            </ListItem>
                          );
                        }
                      )}
                    </List>
                  </Paper>
                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnHandleRemoveAccountsToGroup"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.handleRemoveAccountsToGroup(this.state.id)
                      }
                    >
                      {configJSON.textDelete}
                    </Button>
                    <Button
                      data-test-id="btnHideDeleteAccountModal"
                      variant="contained"
                      onClick={this.hideDeleteAccountModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Box>
          <Box sx={webStyles.footerBlock}>
            <Box sx={webStyles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Privacy Policy</Box>

              <Box></Box>
            </Box>

            <Box>
              <Box>
              © 2024 Thinkspike, All Rights Reserved</Box>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ArchiveButton = styled(Button)({
  backgroundColor:'#FDF2F8',
  border:'1px solid #F87171',
  color:'#DC2626',
  textTransform:"none",
  fontWeight:'700',
  marginLeft:'10px',
  '&:hover': {
    backgroundColor: '#DC2626', 
    color: 'white', 
  },
})
const webStyles = {
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow:1
  },
  rightFooter: {},
  modalRowViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 280,
    justifyContent: 'space-between',
    padding: 10,
    border: '1px solid #ccc',
    borderRadius: 5,
  },
  modalButtonViewStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0px',
  },
  tableViewStyle: {
    width: '100%',
    overflow: 'hidden',
    borderRadius:"6px"
  },
  tableContainerStyle: {
    maxHeight: 600,
    width:'100%',
  },
  tableButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  btnAddAccounts: {
    backgroundColor: '#005500',
    marginLeft: 10,
  },
  btnDeleteAccounts: {
    backgroundColor: '#990000',
    marginLeft: 10,
  },
  checkCircleRed: {
    height: 10,
    width: 10,
    backgroundColor: 'red',
    borderRadius: 5,
  },
  innerTableBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrow: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: 'auto',
    width: 300,
  },
  errorMsg: {
    color: 'red',
    margin: 10,
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6',

  },
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor:'#f6f6f6',
  },
  pageContent: {
    display: 'flex',
    overflowX:'auto',
    flexDirection: 'column', 
    alignItems:'left',
    width:"100%",
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign:"center",
    justifyContent:'center',
    color:"black"
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 20px',
    paddingLeft:'0px',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 4px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px',
    fontWeight:'bolder',
    fontFamily:'Heebo'
  },
};
// Customizable Area End
