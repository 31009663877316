import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Account, Group, GroupAccount } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  name: string;
  searchTerm:string;
  createdAt: string;
  updatedAt: string;
  editMode: boolean;
  token: string;
  groupList: Group[];
  searchQuery: string,
  filteredAccountsData: Account[];
  isVisibleModal: boolean;
  isVisibleAddAccountModal: boolean;
  isVisibleDeleteAccountModal: boolean;
  currentPage:number;
  itemsPerPage:number,

  accountsData: Account[];
  sortBy:string;
  anchorEl: { [key: string]: HTMLElement | null }; 
selectedCount:number;
  modalAccData: Account[];
  selectedAccounts: GroupAccount[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  filterAchorEl:any;
  filteredTableData:any;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  getClientsApiCallId = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      name: "",
      createdAt: "",
      updatedAt: "",
      editMode: false,
      searchTerm: '',
      token: "",
      currentPage:0,
      itemsPerPage:4,
      groupList: [],
      isVisibleModal: false,
      isVisibleAddAccountModal: false,
      isVisibleDeleteAccountModal: false,
      accountsData: [],
      filteredAccountsData:[],
      searchQuery: '',
      anchorEl: {} ,
      selectedCount:0,
      sortBy: 'A-Z',
      modalAccData: [],
      dropdownAccountStatus: false,
      selectedAccounts: [],
      fieldError: false,
      filterAchorEl:null,
      filteredTableData:[],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getClientsApiCallId != null &&
      this.getClientsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson.data) {
        const data = responseJson.data.map((account: Account) => ({
          id: account.id,
          type:account.type,
          isSelected: false,  
          attributes: {
            company_name: account.attributes.company_name,
contact_name:	account.attributes.contact_name,
contact_email	:	account.attributes.contact_email,
contact_job_title:account.attributes.contact_job_title,
contact_phone:account.attributes.contact_phone,
created_at:account.attributes.created_at,
updated_at:account.attributes.updated_at,
          }
        }))
        this.setState({
          accountsData:data, filteredAccountsData: data});
      }
      }
    

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getGroupsApiCallId != null &&
      this.getGroupsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.setState({
          groupList: responseJson.data.sort(
            (a: Group, b: Group) => parseInt(a.id) - parseInt(b.id)
          ),
        });
      } else {
        this.showAlert("Alert", "No Data");
        this.setState({ groupList: [] });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postGroupApiCallId != null &&
      this.postGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);

      const token = localStorage.getItem("token");
      this.setState({ token: token ? token : "" });
      this.getUsers(token)
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.putGroupApiCallId != null &&
      this.putGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteGroupApiCallId != null &&
      this.deleteGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.message) {
        this.getGroups(this.state.token);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAccountsApiCallId != null &&
      this.getAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        const data = responseJson.data.map((account: Account) => {
          return { ...account, isSelected: false };
        });
        this.setState({
          accountsData: data,
        });
      } else {
        this.setState({ accountsData: [] });
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postAddAccountsApiCallId != null &&
      this.postAddAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleAddAccountModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postRemoveAccountsApiCallId != null &&
      this.postRemoveAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleDeleteAccountModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };
  showFilter = (event:any) => {
    this.setState({filterAchorEl:event.currentTarget})
  }
  handleFilterClose = () => {
    this.setState({filterAchorEl:null})
  }
  
  filterAccountsData = () => {
    const { searchQuery, accountsData } = this.state;

    if (!searchQuery.trim()) {
      this.setState({ accountsData: this.state.filteredAccountsData });
      return;
    }

    const filteredData = accountsData.filter((account: Account) => {
      const fullName = `${account.attributes.contact_name}`.toLowerCase();
      const email = `${account.attributes.contact_email}`.toLowerCase();
      const query = searchQuery.toLowerCase();

      return fullName.includes(query) || email.includes(query);
    });

    this.setState({ accountsData: filteredData });
  };
  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.filterAccountsData();
    });
  };
  handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    this.setState({
      anchorEl: { ...this.state.anchorEl, [id]: event.currentTarget }
    });
  };
  
  handleMenuClose = (id: string) => {
    this.setState({
      anchorEl: { ...this.state.anchorEl, [id]: null }
    });
  };
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  handleCheckboxChange = (id: string) => {
  const updatedAccounts = this.state.accountsData.map(account => {
    if (account.id === id) {
      return { ...account, isSelected: !account.isSelected };
    }
    return account;
  });
  
  const selectedCount = updatedAccounts.filter(account => account.isSelected).length;

  this.setState({ accountsData: updatedAccounts, selectedCount });
  };
  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  hideAddAccountModal = () => {
    this.setState({ isVisibleAddAccountModal: false });
  };

  hideDeleteAccountModal = () => {
    this.setState({ isVisibleDeleteAccountModal: false });
  };

  showAddModal = () => {
    this.setState({
      name: "",
      editMode: false,
      fieldError: false,
      isVisibleModal: !this.state.isVisibleModal,
    });
  };


  handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sortBy = event.target.value as string;
    let sortedData = [...this.state.accountsData];
  
    if (sortBy === 'A-Z') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        firstAccount.attributes.company_name.localeCompare(secondAccount.attributes.company_name)
      );
    } else if (sortBy === 'Z-A') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        secondAccount.attributes.company_name.localeCompare(firstAccount.attributes.company_name)
      );
    } else if (sortBy === 'old-new') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        new Date(firstAccount.attributes.created_at).getTime() - new Date(secondAccount.attributes.created_at).getTime()
      );
    } else if (sortBy === 'new-old') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        new Date(secondAccount.attributes.created_at).getTime() - new Date(firstAccount.attributes.created_at).getTime()
      );
    } else if (sortBy === 'relevance') {
      // Handle 'relevance' sorting if applicable
      // Implement your relevance logic here, if required
    }
  
    this.setState({ accountsData: sortedData, sortBy });
  };

  handleChangePage = (event:any,newPage:any) => {
    this.setState({currentPage:newPage});
  };

  handleInputName = (name: string) => {
    this.setState({ name, fieldError: false });
  };

  showHideAddAccountModal = () => {
    this.setState({
      isVisibleAddAccountModal: !this.state.isVisibleAddAccountModal,
      editMode: true,
    });
  };

  handleAddAccounts = (group: Group) => {
    let accArr: any[] = [];
    if (group.attributes.accounts.length > 0) {
      accArr = this.state.accountsData.filter(
        (ar) =>
          !group.attributes.accounts.find((rm) => rm.id.toString() === ar.id)
      );
    } else {
      accArr = this.state.accountsData;
    }

    this.setState({
      id: group.id,
      name: group.attributes.name,
      modalAccData: accArr,
    });
    this.showHideAddAccountModal();
  };

  showHideDeleteAccountModal = () => {
    this.setState({
      isVisibleDeleteAccountModal: !this.state.isVisibleDeleteAccountModal,
      editMode: true,
    });
  };

  handleDeleteAccounts = (group: Group) => {
    const accData = group.attributes.accounts.map((account: GroupAccount) => {
      return { ...account, isSelected: false };
    });
    this.setState({
      id: group.id,
      name: group.attributes.name,
      selectedAccounts: accData,
    });
    this.showHideDeleteAccountModal();
  };

  handleEditGroup = (group: Group) => {
    this.setState({ id: group.id, name: group.attributes.name });
    this.showEditModal();
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccountStatus: !this.state.dropdownAccountStatus });
  };

  handleAccountSelect = (accountId: string) => {
    const newData = this.state.modalAccData.map((account: Account) => {
      if (account.id === accountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ modalAccData: newData });
  };
  deselectAll = () => {
    const updatedAccounts = this.state.accountsData.map(account => {
      return { ...account, isSelected: false };
    });
    this.setState({ accountsData: updatedAccounts,
      selectedCount:0
     });
  }
  handleDeleteAccountSelect = (groupAccountId: number) => {
    const newData = this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.id === groupAccountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ selectedAccounts: newData });
  };

  handleGetGroups = () => {
    this.getGroups(this.state.token);
    this.getAccounts(this.state.token);
  };
  handleClientClick = (clientID : string) => {
    localStorage.setItem('SelectedClientID',clientID);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationManageClientMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleEditClientClick = (clientID : string) => {
    localStorage.setItem('SelectedClientID',clientID);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationClientProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getGroups = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addGroup = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  editGroup = (groupId: string) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint + "/" + `${groupId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  deleteGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAccounts = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUsers = (token: string | null) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGroupsApiCallId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSaveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.modalAccData.map((account: Account) => {
      if (account.isSelected) {
        accountIds.push(account.id);
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/add_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRemoveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.isSelected) {
        accountIds.push(account.id.toString());
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRemoveAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/remove_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
