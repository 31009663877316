import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from '../../../components/src/ApiCall.web';
import { dummyImage} from './assets';
import { CommonSuccessResponse, CourseDataType, CourseModuleType, QuestionOptionType,  } from "./types";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isListLessonOpen:boolean
    courseData?:CourseDataType
    courseModuleSelected?: CourseModuleType
    isLoadingContent:boolean
    isSelectedCourseModule:boolean
    selectedCourseModuleId?:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start
export type CommonModuleType<T  = {}> = {
    paddingTop: any;
    paddingBottom: any;
    width: any;
    content?: T;
  };
const defaultCarouselContent = {
    media: dummyImage,
    type: "image",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is heading for carousel</p>`,
    description: `<p style="font-family:'Crimson Text';font-size:18px">This is subtext</p>`,
  };
// Customizable Area End


export default class EditorController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCourseDataApiCall:string
    getCourseModuleDataApiCall:string
    // Customizable Area End

    

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isListLessonOpen:true,
            courseData:undefined,
            isLoadingContent:false,
            isSelectedCourseModule:false,
            selectedCourseModuleId:undefined,
            courseModuleSelected:undefined
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.getCourseDataApiCall=''
        this.getCourseModuleDataApiCall=''
        // Customizable Area End
    }
    async componentDidMount() {
      const courseId = this.props.navigation.getParam('courseId')
      this.handleGetCourseData(courseId)
  }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
             if (apiRequestCallId === this.getCourseDataApiCall) {
                return this.handleGetCourseDataResponse(responseJson)
                
               
             }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    handleGetCourseDataResponse= (res:CommonSuccessResponse<CourseDataType>)=>{
      const courseModuleId = this.props.navigation.getParam('courseModuleId')
      if(res.data){
        const courseModuleList = res.data.attributes.course_modules;
      const currentModule = courseModuleList.find(item => `${item.id}` === `${courseModuleId}`);
            this.setState({
                courseData:res.data, 
                isLoadingContent:false,
                selectedCourseModuleId:courseModuleId,
                courseModuleSelected:currentModule
            })
    }
    }
  
    handleFlipCard = (cardIndex: number, contentId: string, valueUpdate: boolean) => {
      const { courseModuleSelected } = this.state;
      if (!courseModuleSelected) return;
  
      // Copy courseModuleSelected to avoid direct mutation
      const currentModule: CourseModuleType = { ...courseModuleSelected };
  
      if (currentModule.sections && currentModule.sections.length > 0) {
          const section = currentModule.sections[0];
  
          // Find the 'flashcard' block with the matching content ID
          const flashcardBlock = section.content_blocks.find(
              block => block.block_type === 'flashcard' && `${block.id}` === contentId
          );
  
          if (flashcardBlock && 'cards' in flashcardBlock && flashcardBlock.cards && flashcardBlock.cards[cardIndex]) {
              // Update the flipped status for the specified card
              flashcardBlock.cards[cardIndex] = {
                  ...flashcardBlock.cards[cardIndex],
                  flipped: valueUpdate
              };
  
              // Set the modified module back to state
              this.setState({ courseModuleSelected: currentModule });
          }
      }
  };
  
      toggleContentVisibility = (labelIndex: number, contentId: string, valueUpdate: boolean, key:string) => {
        const { courseModuleSelected } = this.state;
        if (!courseModuleSelected) return;
    
        const currentModule: CourseModuleType = { ...courseModuleSelected };
    
        if (currentModule.sections && currentModule.sections.length > 0) {
            const section = currentModule.sections[0];
    
            const labelGraphicBlock = section.content_blocks.find(
                block => block.block_type === 'labeled_graphic' && `${block.id}` === contentId
            );
            if (labelGraphicBlock && 'labels' in labelGraphicBlock && labelGraphicBlock.labels && labelGraphicBlock.labels[labelIndex]) {
                labelGraphicBlock.labels[labelIndex] = {
                    ...labelGraphicBlock.labels[labelIndex],
                    [key]: !valueUpdate
                };
    
                this.setState({ courseModuleSelected: currentModule });
            }
        }
    };
    

   
  handleSelectAnswer = (questionIndex: number, contentId: string, value: string) => {
    const { courseModuleSelected } = this.state;
    if (!courseModuleSelected) return; 

    const currentModule :CourseModuleType = { ...courseModuleSelected };
    if (currentModule && currentModule.sections) {
      const section = currentModule.sections[0];

      const testKnowledgeBlock = section.content_blocks.find(block => block.block_type === 'test_knowledge' && `${block.id}` === contentId);
      if (testKnowledgeBlock && testKnowledgeBlock.test_questions && testKnowledgeBlock.test_questions[questionIndex]) {
        const question = testKnowledgeBlock.test_questions[questionIndex];
        question.selectedAnswers = question.selectedAnswers || []
        const answerIndex = question.selectedAnswers.findIndex((answer: string) => answer === value);

        if (answerIndex !== -1) {
          question.selectedAnswers = question.selectedAnswers.filter((answer: string) => answer !== value);
        } else if (question.question_type === 'multiple_response') {
          question.selectedAnswers = [...question.selectedAnswers, value];
        } else {
          question.selectedAnswers = [value];
        }
      }

      this.setState({ courseModuleSelected: currentModule });
    }
  };
  handleSubmitQuestion = (questionIndex:number,contentId:string)=>{
    const { courseModuleSelected } = this.state;
    if (!courseModuleSelected) return; 

    const currentModule :CourseModuleType = { ...courseModuleSelected };
    if (currentModule && currentModule.sections) {
      const section = currentModule.sections[0];
      const testKnowledgeBlock = section.content_blocks.find(block => block.block_type === 'test_knowledge' && `${block.id}` === contentId);
      console.log(testKnowledgeBlock)
      if (testKnowledgeBlock && testKnowledgeBlock.test_questions && testKnowledgeBlock.test_questions[questionIndex]) {
        const question = testKnowledgeBlock.test_questions[questionIndex];
        const correctAnswers = question.options
        .filter((option:QuestionOptionType) => option.is_correct)
        .map((option:QuestionOptionType) => `${option.id}`);

        const selectedAnswers = question.selectedAnswers || [];

        let isAnswersCorrect = false;
        if (question.question_type === 'multiple_response') {
          isAnswersCorrect =
            correctAnswers.length === selectedAnswers.length &&
            correctAnswers.every((answerId:number) => selectedAnswers.includes(answerId));
        } else if (question.question_type === 'single_response') {
          isAnswersCorrect =
            selectedAnswers.length === 1 && correctAnswers.includes(selectedAnswers[0]);
        }
      
        question.isAnswersCorrect = isAnswersCorrect;
        question.isSubmit=true
  
        this.setState({ courseModuleSelected: currentModule });
      }
    }
  }
  handleGetCourseData = async (id: string) => {
      this.setState({ isLoadingContent: true, })
      this.getCourseDataApiCall = await apiCall({
        method: 'GET',
        contentType: 'application/json',
        endPoint: `bx_block_admin/courses/get_course?id=${id}`,
        token: localStorage.getItem('token'),
      });
    }
    handleChooseCourseModule = async (courseModuleId: string) => {
      const {courseData} = this.state
      if(!courseData) return
      const courseModuleList = courseData.attributes.course_modules;
      const currentModule = courseModuleList.find(item => `${item.id}` === `${courseModuleId}`);
      const courseId = this.props.navigation.getParam('courseId')
      this.setState({ selectedCourseModuleId: courseModuleId, courseModuleSelected:currentModule })
      this.props.navigation.navigate('CourseLearning',{path:{courseId,courseModuleId}})
    }
    handleButtonTypeClick =()=>{
      const {courseData,selectedCourseModuleId} = this.state
      this.props.navigation.navigate("AssessmentTest",{path:{courseId:courseData?.id,courseModuleId:selectedCourseModuleId}})
    }
    // Customizable Area End
}
