//@ts-nocheck
import React, { ChangeEvent } from "react";
// Customizable Area Start
import {
  InputBase, Box, Button, List, ListItem, Grid, Typography, ClickAwayListener, Divider,
  ToggleButtonGroup, ToggleButton, Drawer, IconButton, Slider, Dialog,
  Checkbox, FormControlLabel, TextField, Tooltip, Zoom, Modal, Container, RadioGroup, Radio
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import LoopIcon from '@mui/icons-material/Loop';
import {
  addImage, addImageLight, imageArrowRight, imageArrowUp,
  imageListBulleted, imageListEmpty, imageListNumbered, trashImage,
  plusIcon, copyIcon, editIcon, formateIcon, deleteIcon, deleteIcon2, textIcon, listIcon,
  videoIcon, tableIcon, flashcardIcon, carouselIcon, buttonIcon, imageIcon,  binIcon,
  repeatBtnBlack, repeatBtnWhite
} from "./assets";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select, { components }  from 'react-select';
import { styled } from "@mui/material/styles";
import ReactPlayer from 'react-player';
import Carousel from "react-elastic-carousel";
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import {ExpandMore} from '@mui/icons-material'
import ContentManagementController, {
  DefaultInputTitle, Props, configJSON, PaginationProps, CarouselButtonProps
} from "./ContentManagementController";
import CourseCreationHeader from "./CourseCreationHeader.web";
import Editor from "./Editor.web";
import PopupMenu from "./PopupMenu.web";
import CustomEditor from "../../../components/src/CustomEditor.web";
import Loader from "../../../components/src/Loader.web";

const toolbarItems = [
  { label: 'Text', icon: textIcon },
  { label: 'List', icon: listIcon },
  { label: 'Image', icon: imageIcon },
  { label: 'Video', icon: videoIcon },
  { label: 'Table', icon: tableIcon },
  { label: 'Flash card', icon: flashcardIcon },
  { label: 'Carousel', icon: carouselIcon },
  { label: 'Button', icon: buttonIcon },
];

type ChangeTitleRenderProps = {
  state: DefaultInputTitle,
  inputStyles: React.CSSProperties;
  toggleActivity: (val: boolean) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  dataTestId?: string;
};
// Customizable Area End

export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.containerRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  getListStyleType = (number: number) => {
    if (this.state.lessonListType === "unordered") {
      return <Circle></Circle>
    } else if (this.state.lessonListType === "ordered") {
      return <div>{number}</div>
    }
  }

  ChangeTitleRender: React.FC<ChangeTitleRenderProps> = ({
    state,
    inputStyles,
    toggleActivity,
    onChange,
    placeholder,
    dataTestId
  }) => {
    return (
      <div data-test-id="change-title-render" onDoubleClick={() => !this.state.isPreview && toggleActivity(true)}>
        {state.isActiveToChange ?
          <CustomInput
            readOnly={this.state.isPreview}
            fullWidth
            autoFocus
            data-test-id={dataTestId}
            placeholder={placeholder}
            style={inputStyles}
            value={state.title}
            onKeyDown={(event) => this.onHandleEnterPress(event, () => toggleActivity(false))}
            onChange={(event) => onChange(event)}
          />
          :
          state.title?.length === 0 ?
            <div className="placeholder">{placeholder}</div>
            :
            state.title
        }
      </div>
    );
  }

  renderTitleAndAuthor = (props) => {
    return (
      <div data-test-id="change-title-render">
        <CustomInput
          fullWidth
          autoFocus
          value={props.value}
          onChange={(event) => props.onChange(event)}
          readOnly={this.state.isPreview}
          data-test-id={props.dataTestId}
          placeholder={props.placeholder}
          style={props.inputStyles}
        />
      </div>
    );
  }

  renderToolbar = () => {
    const isToolbarVisible = this.state.isToolbarVisible || this.state.courseData.length === 0;
    return (
      <>
        {this.state.courseData.length !== 0 && this.state.courseData.findIndex(item => item.type === "Button") === -1 &&
          <PlusLine onClick={this.openToolbar} data-test-id="plus-btn">
            <div />
            <img src={plusIcon} />
          </PlusLine>
        }
        <Toolbar isVisible={isToolbarVisible}>
          <Grid container spacing={1} justifyContent="center">
            {toolbarItems.map((item, index) => (
              <Grid item key={index}>
                <button data-test-id="content-type" onClick={() => this.handleTypeButtonClick(item.label)}>
                  <img src={item.icon} />
                  <span>{item.label}</span>
                </button>
              </Grid>
            ))}
          </Grid>
        </Toolbar>
      </>
    );
  };

  renderOptionList = (index: number) => {
    const isEditActive = this.state.currentIndex === index && this.state.buttonType === "edit";
    const isFormatActive = this.state.currentIndex === index && this.state.buttonType === "format";
    return (
      <OptionList className="option-list">
        <button><img src={editIcon} onClick={() => this.handleEditClick(index)} className={isEditActive ? "active" : ""} data-test-id="edit-btn" /></button>
        <button><img src={formateIcon} onClick={() => this.handleFormatClick(index)} className={isFormatActive ? "active" : ""} data-test-id="format-btn" /></button>
        <button><img src={copyIcon} onClick={() => this.handleCopyClick(index)} data-test-id="copy-btn" /></button>
        <button><img src={deleteIcon} onClick={() => this.handleDeleteClick(index)} data-test-id="delete-btn" /></button>
        {index !== 0 && <button><ArrowUpIcon onClick={() => this.handleUpDownClick(index)} className="up-down" data-test-id="up-btn" /></button>}
        {index !== (this.state.courseData.length - 1) && <button><ArrowDownIcon onClick={() => this.handleUpDownClick(index + 1)} className="up-down" data-test-id="down-btn" /></button>}
      </OptionList>
    );
  }

  renderButtonOptionList = (index: number) => {
    const isFormatActive = this.state.currentIndex === index && this.state.buttonType === "format";
    return (
      <OptionList className="option-list">
        <button><img src={formateIcon} onClick={() => this.handleFormatClick(index)} className={isFormatActive ? "active" : ""} data-test-id="format-btn-btn" /></button>
        <button><img src={deleteIcon} onClick={() => this.handleDeleteClick(index)} data-test-id="delete-btn-btn" /></button>
      </OptionList>
    );
  }

  isContentPaddingVisible = (type: string) => {
    return ["Text", "List", "Quote"].includes(type);
  }

  renderQuizOptionList = () => {
    return (
      <OptionList className="option-list">
        <button><img src={editIcon} onClick={() => this.handleShowQuizEditor()} data-test-id="quiz-edit-btn" /></button>
        <button><img src={deleteIcon} data-test-id="delete-btn" /></button>
      </OptionList>
    )
  }

  renderFormatPopup = () => {
    const obj = this.state.courseData[this.state.currentIndex];
    const top = obj.paddingTop;
    const bottom = obj.paddingBottom;
    const blockPadding = top === bottom ? top : 0;
    const isContentPaddingVisible = this.isContentPaddingVisible(obj.type);
    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={this.hideFormatOption}
      >
        <FormatList ref={this.formatRef} className="format-option">
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography className="format">Format</Typography>
              <CloseIcon onClick={this.hideFormatOption} className="close-btn" data-test-id="close-btn" />
            </Box>
            <Box>
              <Typography className="text">Block Padding</Typography>
              <ToggleButtonGroup
                exclusive
                value={blockPadding}
                data-test-id="block-padding-btn"
                onChange={(e) => this.handleBlockPadding(e)}
              >
                <CustomToggleButton value={30}>S</CustomToggleButton>
                <CustomToggleButton value={60}>M</CustomToggleButton>
                <CustomToggleButton value={120}>L</CustomToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box>
              <Typography className="small-text">Top</Typography>
              <CustomSlider
                data-test-id="padding-top-btn"
                valueLabelDisplay="auto"
                min={0}
                max={200}
                step={10}
                value={top}
                onChange={(e) => this.handlePadding(e, "paddingTop")}
              />
            </Box>
            <Box>
              <Typography className="small-text">Bottom</Typography>
              <CustomSlider
                data-test-id="padding-bottom-btn"
                valueLabelDisplay="auto"
                min={0}
                max={200}
                step={10}
                value={bottom}
                onChange={(e) => this.handlePadding(e, "paddingBottom")}
              />
            </Box>
            {isContentPaddingVisible &&
              <Box>
                <Typography className="text">Content Padding</Typography>
                <ToggleButtonGroup
                  exclusive
                  data-test-id="content-padding"
                  value={obj.width}
                  onChange={(e) => this.handlePadding(e, "width")}
                >
                  <CustomToggleButton value="50vw">S</CustomToggleButton>
                  <CustomToggleButton value="70vw">M</CustomToggleButton>
                  <CustomToggleButton value="90vw">L</CustomToggleButton>
                </ToggleButtonGroup>
              </Box>
            }
          </Box>
        </FormatList>
      </ClickAwayListener>
    );
  }

  renderTextDrawer = () => {
    return (
      <>
        <Typography className="title">
          Heading
        </Typography>
        <Box paddingBottom="15px">
          <CustomEditor
            value={this.state.currentObj.heading}
            onChange={this.onChangeHeading}
          />
        </Box>
        <Typography className="title">
          Paragraph
        </Typography>
        <Box paddingBottom="15px">
          <CustomEditor
            data-test-id="paragraph"
            value={this.state.currentObj.content}
            onChange={(value: string) => this.onChangeValue("content", value)}
          />
        </Box>
      </>
    );
  }

  renderListDrawer = () => {
    const type = this.state.currentObj.listType;
    const listTypes = [
      { label: 'Bulleted List', value: 'unordered' },
      { label: 'Numbered List', value: 'ordered' }
    ];
    return (
      <>
        <Box>
          <Typography className="title">
            Type of List
          </Typography>
          <Select
            data-test-id="list-type"
            isSearchable={false}
            options={listTypes}
            placeholder="Select list type"
            value={listTypes.filter((item) => item.value === type)[0]}
            onChange={(e) => this.onChangeListValue("listType", e.value)}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: '999',
              })
            }}
          />
        </Box>
        <Box>
          <Typography className="title">
            Heading
          </Typography>
          <Box paddingBottom="15px">
            <CustomEditor
              hideBottomBorder={true}
              data-test-id="list-heading-editor"
              value={this.state.currentObj.heading}
              onChange={(value) => this.onChangeListValue("heading", value)}
            />
          </Box>
        </Box>
        <Box position="relative" display="flex" alignItems="center">
          <ListDrawer width="100%">
            <CustomEditor
              key={this.state.currentObj.listType}
              isListHide={true}
              hideBottomBorder={true}
              value={this.state.currentObj.content}
              data-test-id="list-content-editor"
              onChange={(value) => this.onChangeListValue("content", value)}
            />
          </ListDrawer>
        </Box>
      </>
    );
  }

  renderImageDrawer = () => {
    const width = this.state.currentObj.width;
    const imageWidth = [
      { label: 'Centred Image', value: '70vw' },
      { label: 'Full Width Image', value: '100vw' },
    ];
    return (
      <>
        <Box mt="30px">
          <Select
            data-test-id="image-width"
            isSearchable={false}
            options={imageWidth}
            placeholder="Select image width"
            value={imageWidth.filter((item) => item.value === width)[0]}
            onChange={(e) => this.onChangeValue("width", e.value)}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: '999',
              })
            }}
          />
        </Box>
        <Box paddingBottom="30px">
          <Typography className="title">
            Image Caption
          </Typography>
          <Box>
            <CustomEditor
              data-test-id="image-caption-editor"
              value={this.state.currentObj.caption}
              onChange={(value) => this.onChangeValue("caption", value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <img src={this.state.currentObj.image} width="134px" height="88px" style={{ objectFit: "cover" }} />
            <Typography sx={{ marginLeft: "20px" }}>{this.state.currentObj.fileName}</Typography>
          </Box>
          <Box ml="25px">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              id="capture-image"
              data-test-id="capture-image"
              onChange={(e) => this.onCaptureMedia("image", e)}
            />
            <label htmlFor="capture-image">
              <UploadButton component="span">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.fileError}</Box>
        <Box textAlign="center" mt="20px">
          <Typography sx={{ fontSize: "16px", fontFamily: "Heebo-Medium" }}>Or</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Box width="100%">
            <Typography sx={{ margin: "0px 0px 8px 0px !important" }} className="title">
              Import from URL
            </Typography>
            <UrlInputBase
              placeholder="Enter URL"
              data-test-id="url-input"
              value={this.state.currentObj.mediaUrl}
              onKeyDown={(e) => this.handleKeyDown(e.key)}
              onChange={(e) => this.onChangeUrl(e.target.value)}
            />
          </Box>
          <Box ml="25px">
            <UploadButton onClick={this.onImportFromLink} data-test-id="import-btn">Import</UploadButton>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.urlError}</Box>
      </>
    );
  }

  renderVideoDrawer = () => {
    const width = this.state.currentObj.width;
    const videoWidth = [
      { label: 'Centred Video', value: '70vw' },
      { label: 'Full Width Video', value: '100vw' },
    ];
    return (
      <>
        <Box mt="30px">
          <Select
            data-test-id="video-width"
            isSearchable={false}
            options={videoWidth}
            placeholder="Select video width"
            value={videoWidth.filter((item) => item.value === width)[0]}
            onChange={(e) => this.onChangeValue("width", e.value)}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: '999',
              })
            }}
          />
        </Box>
        <Box paddingBottom="30px">
          <Typography className="title">
            Video Caption
          </Typography>
          <Box>
            <CustomEditor
              data-test-id="video-caption-editor"
              value={this.state.currentObj.caption}
              onChange={(value) => this.onChangeValue("caption", value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box width="200px" height="120px">
              <Box position='relative' display="flex" justifyContent="center" width='100%' height="100%">
                <ReactPlayer
                  controls
                  url={this.state.currentObj.video}
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
            <Typography sx={{ marginLeft: "20px" }}>{this.state.currentObj.fileName}</Typography>
          </Box>
          <Box ml="25px">
            <input
              type="file"
              accept="video/mp4, video/webm, video/ogg, video/x-msvideo"
              style={{ display: "none" }}
              id="capture-video"
              data-test-id="capture-video"
              onChange={(e) => this.onCaptureVideo("video", e)}
            />
            <label htmlFor="capture-video">
              <UploadButton component="span">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.fileError}</Box>
        <Box mt="20px" textAlign="center">
          <Typography sx={{ fontFamily: "Heebo-Medium", fontSize: "16px" }}>Or</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Box width="100%">
            <Typography sx={{ margin: "0px 0px 8px 0px !important" }} className="title">
              Import from URL
            </Typography>
            <UrlInputBase
              placeholder="Enter URL"
              data-test-id="video-url-input"
              value={this.state.currentObj.mediaUrl}
              onKeyDown={(e) => this.handleKeyDownForVideo(e.key)}
              onChange={(e) => this.onChangeUrl(e.target.value)}
            />
          </Box>
          <Box ml="25px">
            <UploadButton onClick={this.onImportVideoFromLink} data-test-id="video-import-btn">Import</UploadButton>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.urlError}</Box>
      </>
    );
  }

  renderQuoteDrawer = () => {
    return (
      <>
        <Box paddingBottom="15px">
          <Typography className="title">
            Quote Text
            <Tooltip
              arrow
              title="Maximum 200 characters allowed"
              placement="right"
              TransitionComponent={Zoom}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Box>
            <CustomEditor
              maxCharLimit={200}
              isListHide={true}
              isBlockTypeVisible={true}
              data-test-id="quote-text"
              value={this.state.currentObj.content}
              onChange={(value: string) => this.onChangeValue("content", value)}
            />
          </Box>
        </Box>
        <Box paddingBottom="15px">
          <Typography className="title">
            Name
          </Typography>
          <Box>
            <CustomEditor
              data-test-id="name-text"
              value={this.state.currentObj.name}
              onChange={(value: string) => this.onChangeValue("name", value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems={{ xs: "start", sm: "center" }}>
          <Box display="flex" alignItems="center" flexDirection={{ xs: "column", sm: "row" }}>
            {this.state.currentObj.image && <img src={this.state.currentObj.image} width="88px" height="88px" style={{ objectFit: "cover", borderRadius: "50%" }} />}
            <Typography sx={{ marginLeft: "20px" }}>{this.state.currentObj.fileName}</Typography>
          </Box>
          <Box margin="23px 0px" gap="10px" display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            {this.state.currentObj.image &&
              <UploadButton
                component="span"
                data-test-id="remove-btn"
                onClick={this.onRemoveImage}
              >
                Remove
              </UploadButton>
            }
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              id="capture-quote-image"
              data-test-id="capture-quote-image"
              onChange={(e) => this.onCaptureMedia("image", e)}
            />
            <label htmlFor="capture-quote-image">
              <UploadButton component="span" data-test-id="upload-btn">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.fileError}</Box>
      </>
    );
  }

  renderLabelDrawer = () => {
    return (
      <>
        <Box paddingBottom="30px">
          <Typography className="title">
            You can add upto 6 labels
          </Typography>

        </Box>
        <Box justifyContent="space-between" display="flex" alignItems="center">
          <Box alignItems="center" display="flex">
            <img height="88px" width="134px" style={{ objectFit: "cover" }} src={this.state.currentObj.image} />
            <Typography
              sx={{ marginLeft: "20px" }}
            >
              {this.state.currentObj.fileName}
            </Typography>
          </Box>
          <Box ml="25px">
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="captureLabelImage"
              style={{ display: "none" }}
              onChange={(e) => this.onCaptureMedia("image", e)}
              data-test-id="captureLabelImage"
            />
            <label htmlFor="captureLabelImage">
              <UploadButton component="span">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box data-test-id="errorMessage" color="red">{this.state.currentObj.fileError}</Box>
        <Box mt="20px" textAlign="center" >
          <Typography sx={{
            fontFamily: "Heebo-Medium",
            fontSize: "16px",
          }}>
            Or
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="end"
          justifyContent="space-between"
        >
          <Box width="100%">
            <Typography className="title" sx={{ margin: "0px 0px 8px 0px !important" }} >
              Import from URL
            </Typography>
            <UrlInputBase
              data-test-id="url-input-label"
              placeholder="Enter URL"
              onChange={(e) => this.onChangeUrl(e.target.value)}
              onKeyDown={(e) => this.handleKeyDown(e.key)}
              value={this.state.currentObj.mediaUrl}
            />
          </Box>
          <Box ml="25px">
            <UploadButton onClick={this.onImportFromLink} data-test-id="import-btn">Import</UploadButton>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.urlError}</Box>
        <Box display={'flex'} flexDirection={'column'} sx={{ borderTop: '1px solid #D6D3D1', marginTop: '40px', padding: '20px 0' }}>
          {this.state.currentObj.content.map((content, index) => {
            return (
              <Box key={content.id} display={'flex'} alignItems={'center'} >
                <Box sx={{ flex: '3' }}>
                  <Box sx={{ marginBottom: '10px', marginTop: '20px' }}>
                    <CustomEditor
                      maxCharLimit={200}
                      hideBottomBorder={true}
                      isListHide={true}
                      isBlockTypeVisible={true}
                      data-test-id={`content-header-${index}`}
                      value={content.title}
                      onChange={(value: string) => { this.handleEditContent('title', value, content.id) }}
                    />
                  </Box>
                  <Box sx={{ marginBottom: '10px', marginTop: '20px' }}>
                    <CustomEditor
                      maxCharLimit={200}
                      hideBottomBorder={true}
                      isListHide={true}
                      isBlockTypeVisible={true}
                      data-test-id={`content-description-${index}`}
                      value={content.description}
                      onChange={(value: string) => { this.handleEditContent('description', value, content.id) }}
                    />
                  </Box>
                </Box>
                <DeleteButton data-test-id={`delete-button-${index}`} style={{ marginLeft: '20px' }} onClick={() => { this.deleteContentItem(content.id) }}>
                  <img src={trashImage} />
                </DeleteButton>
              </Box>
            )
          })}
        </Box>
        {this.state.currentObj.content.length < 6 &&
          <Button
            data-test-id="add-new-btn"
            style={{
              width: '100%',
              border: '1px solid #D6D3D1',
              backgroundColor: "#FFF",
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '14px'
            }}
            onClick={this.handleAddNewBtn}>
            Add Item
          </Button>}
      </>
    );
  }

  renderCarouselDrawer = () => {
    const obj = this.state.currentObj;
    return (
      <Box>
        <Typography sx={{ marginTop: "-12px" }}>You can add upto 5 slides</Typography>
        {obj.content.map((item, index: number) => {
          return (
            <Box position="relative">
              <Box className="border-bottom" borderTop={index === 0 ? "none" : "1.5px solid #D6D3D1"} />
              <Typography className="side-title">Slide {index + 1}</Typography>
              <Box>
                <Typography className="title">
                  Heading Slide {index + 1}
                </Typography>
                <Box paddingBottom="15px">
                  <CustomEditor
                    data-test-id="heading"
                    value={item.heading}
                    onChange={(value: string) => this.onChangeCarouselHeading(index, "heading", value)}
                  />
                </Box>
              </Box>
              <Box>
                <Typography className="title">
                  Text Slide {index + 1}
                </Typography>
                <Box paddingBottom="15px">
                  <CustomEditor
                    data-test-id="description"
                    value={item.description}
                    onChange={(value: string) => this.onChangeCarouselHeading(index, "description", value)}
                  />
                </Box>
              </Box>
              <Box mb="40px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    {item.type === "video" && <Box width="200px" height="120px">
                      <Box position='relative' display="flex" justifyContent="center" width='100%' height="100%">
                        <ReactPlayer
                          controls
                          url={item.media}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                    </Box>}
                    {item.type === "image" && <img src={item.media} width="200px" height="120px" style={{ objectFit: "cover" }} />}
                    <Typography sx={{ marginLeft: "20px" }}>{item.fileName}</Typography>
                  </Box>
                  <Box ml="25px">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, video/mp4, video/webm, video/ogg, video/x-msvideo"
                      style={{ display: "none" }}
                      id={`capture-media-${index}`}
                      data-test-id={`capture-media-${index}`}
                      onChange={(e) => this.onCaptureCarouselMedia("media", index, e)}
                    />
                    <label htmlFor={`capture-media-${index}`}>
                      <UploadButton component="span">Upload</UploadButton>
                    </label>
                  </Box>
                </Box>
                <Box color="red">{item.fileError}</Box>
                <Box textAlign="center" mt="20px">
                  <Typography sx={{ fontSize: "16px", fontFamily: "Heebo-Medium" }}>Or</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="end">
                  <Box width="100%">
                    <Typography sx={{ margin: "0px 0px 8px 0px !important" }} className="title">
                      Import from URL
                    </Typography>
                    <UrlInputBase
                      placeholder="Enter URL"
                      data-test-id="media-url-input"
                      onChange={(e) => this.onChangeCarouselHeading(index, "mediaUrl", e.target.value)}
                      onKeyDown={(e) => this.handleKeyDownForCarousel(e.key, index)}
                      value={item.mediaUrl}
                    />
                  </Box>
                  <Box ml="25px">
                    <UploadButton
                      data-test-id="import-url-btn"
                      onClick={() => this.onImportMediaFromLink(index)}
                    >Import</UploadButton>
                  </Box>
                </Box>
                <Box color="red">{item.urlError}</Box>
              </Box>
            </Box>
          )
        }
        )}
        {this.state.currentObj.content.length < 5 &&
          <UploadButton
            data-test-id="add-item"
            component="span"
            sx={{
              height: "50px",
              width: "100%",
              marginBottom: "30px",
            }}
            onClick={this.onAddCarouselSides}
          >
            Add Slides
          </UploadButton>}
      </Box>
    );
  }

  renderTableDrawer = () => {
    const { currentObj } = this.state;
    const tableData = {...currentObj};
  
   
    const rowsData = [ ...tableData.table_rows_attributes];
  
    return (
      <>
        <Box paddingBottom="30px">
          <Typography className="title">You can add up to 4 Columns and 5 Rows</Typography>
        </Box>
        <Box>
          <StyledTable>
            <tbody>
              {rowsData.map((row, rowIndex) => (
                <tr key={row.id}>
                  {Object.entries(row).map(([key,cell], cellIndex) => (
                     key!=='id' && (<td key={`${rowIndex}-${cell.id}`} data-test-id={`cell-${rowIndex}-${cellIndex}`} 
                      onClick={() => this.handleCellSelect(rowIndex, cellIndex,cell.id)}>
                        <CustomEditor
                          hideBottomBorder={true}
                          isListHide={true}
                          isBlockTypeVisible={true}
                          data-test-id={`table-cell-${rowIndex}-${cellIndex}`}
                          value={cell.value}
                          onChange={(value) => this.handleTableValueChange(value, rowIndex,cell.id)}
                        />
                      </td>)
                  ))}
                </tr>
              ))}
            </tbody>
          </StyledTable>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button data-test-id="add-column-before" style={webStyles.buttonTable} onClick={() => this.addColumn('before')}>Add Column Before</Button>
            </Grid>
            <Grid item xs={6}>
              <Button data-test-id="add-column-after" style={webStyles.buttonTable} onClick={() => this.addColumn('after')}>Add Column After</Button>
            </Grid>
            <Grid item xs={6}>
              <Button data-test-id="add-row-before" style={webStyles.buttonTable} onClick={() => this.addRow('before')}>Add Row Before</Button>
            </Grid>
            <Grid item xs={6}>
              <Button data-test-id="add-row-after" style={webStyles.buttonTable} onClick={() => this.addRow('after')}>Add Row After</Button>
            </Grid>
            <Grid item xs={12}>
              <Button data-test-id="delete-column-selected" style={{ ...webStyles.buttonTable, borderColor: '#F87171', color: '#F87171' }} onClick={this.deleteColumn}>Delete Column Selected</Button>
            </Grid>
            <Grid item xs={12}>
              <Button data-test-id="delete-row-selected" style={{ ...webStyles.buttonTable, borderColor: '#F87171', color: '#F87171' }} onClick={this.deleteRow}>Delete Row Selected</Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  renderFlashCardDrawer = () => {
    return (
      <>
        <Box paddingBottom="30px">
          <Typography className="title">
            You can add upto 6 Flashcards
          </Typography>

        </Box>
        <Box display={'flex'} flexDirection={'column'} >
          {this.state.currentObj.cards.map((content, index) => {
            return (
              <Box display={'flex'} alignItems={'center'} key={content.id}>
                <Box sx={{ padding: '20px 0', flex: '3' }}>
                  <Typography color={'#6A6A6A'} fontSize={16} style={{ marginBottom: '10px', marginTop: '20px' }}>{`Card ${index + 1} Front`}</Typography>
                  <CustomEditor
                    maxCharLimit={200}
                    isListHide={true}
                    isBlockTypeVisible={true}
                    data-test-id={`card-front-${index}`}
                    value={content.front}
                    onChange={(value: string) => { this.handleEditCardContent('front', value, content.id) }}
                  />
                  <Typography style={{ marginBottom: '10px', marginTop: '20px' }} color={'#6A6A6A'} fontSize={16}>{`Card ${index + 1} Back`}</Typography>
                  <CustomEditor
                    maxCharLimit={200}
                    isListHide={true}
                    isBlockTypeVisible={true}
                    data-test-id={`card-back-${index}`}
                    value={content.back}
                    onChange={(value: string) => { this.handleEditCardContent('back', value, content.id) }}
                  />
                </Box>
                <DeleteButton data-test-id={`delete-button-${index}`} style={{ marginLeft: '20px' }} onClick={() => { this.handleDeleteCardContent(content.id) }}>
                  <img src={trashImage} />
                </DeleteButton>
              </Box>
            )
          })}
        </Box>
        {this.state.currentObj.cards.length < 6 &&
          <Button
            data-test-id="add-new-btn"
            style={{
              width: '100%',
              border: '1px solid #D6D3D1',
              backgroundColor: "#FFF",
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '14px'
            }}
            onClick={this.handleAddNewCard}>
            Add Item
          </Button>}
      </>
    );
  }
  renderTestKnowledgeDrawer = () => {
    const { currentObj } = this.state
    const question = currentObj.content
    const listTypes = [
      { label: 'Single Response', value: false },
      { label: 'Multiple Response', value: true }
    ];
    return (<>
        <Typography style={webStyles.quizEditorSubHeading}>You can add up to 4 options</Typography>
      <Box display={"flex"} flexDirection={"column"} gap={"32px"} paddingBottom={'30px'} sx={{overflowX:'hidden'}}>
        <Box>
          <Typography
            paddingY={"10px"}
            className="title">Type of Question</Typography>
          <Select
            data-test-id="test-type-select"
            options={listTypes}
            value={listTypes.find(type => type.value === question.isMultiple)}
            onChange={this.handleChangeQuestionType}
          />
        </Box>

        <Box>
          <Typography paddingY={"10px"} className="title">Question</Typography>
          <CustomEditor
            value={question.question}
            data-test-id="question-title-field"
            onChange={(value) => this.handleTestContentChange('question', value)}
          />
        </Box>
        <Box >
          <Box display={"flex"} justifyContent={"space-between"} height={"22px"} gap={"24px"} width={"122px"}>
            <Typography style={webStyles.quizOptionHeading}>Correct</Typography>
            <Typography style={webStyles.quizOptionHeading}>Choices</Typography>
          </Box>
          {question.options.map((option, optionIndex) => (
            <Box
              key={option.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="35px"
              paddingTop="10px"
            >
              {question.isMultiple ? (
                <CustomCheckbox
                  data-test-id={`checkbox-${optionIndex}`}
                  checked={option.isCorrect}
                  onChange={() => this.handleCorrectOptionChange(optionIndex)}
                />
              ) : (
                <Radio
                  data-test-id={`radio-${optionIndex}`}
                  checked={option.isCorrect}
                  style={{ color: 'black' }}
                  onChange={() => this.handleCorrectOptionChange(optionIndex)}
                />
              )}
              <Box style={{ width: '100%' }}>
                <CustomEditor
                  data-test-id={`answer-option-${optionIndex}`}
                  value={option.answer}
                  onChange={(value) => this.handleTextAnswerChange(value, optionIndex)}
                />
              </Box>
              <IconButton
                onClick={() => { this.handleDeleteAnswer(option.id) }}
                data-test-id={`delete-answer-${optionIndex}`}
              >
                <img src={deleteIcon2} alt="delete option" />
              </IconButton>
            </Box>
          ))}
        </Box>
        {question.options.length < 4 && (
          <Button onClick={this.handleAddAnswer}
            data-test-id="add-test-option"
          >Add Option</Button>
        )}
         <Box >
          <Typography paddingY={"10px"} className="title">
          Correct answer Supporting Text
          </Typography>
          <CustomEditor
            value={question.supportingText}
            data-test-id="supporting-text-field"
            onChange={(value)=>this.handleTestContentChange('supportingText',value)}
          />
        </Box>
        <Box >
          <Typography paddingY={"10px"} className="title">
            InCorrect Answer Supporting Text
          </Typography>
          <CustomEditor
            value={question.supportingTextInCorrect}
            data-test-id="supporting-incorrect-text-field"
            onChange={(value)=>this.handleTestContentChange('supportingTextInCorrect',value)}
          />
        </Box>
      </Box>
      <Divider />
    </>)
  }

  renderDrawerContent = () => {
    const drawerMap = {
      Text: this.renderTextDrawer,
      List: this.renderListDrawer,
      Image: this.renderImageDrawer,
      Video: this.renderVideoDrawer,
      Quote: this.renderQuoteDrawer,
      Carousel: this.renderCarouselDrawer,
      Label: this.renderLabelDrawer,
      Table: this.renderTableDrawer,
      Card: this.renderFlashCardDrawer,
      Test: this.renderTestKnowledgeDrawer
    };
    const renderDrawer = drawerMap[this.state.currentObj.type];
    return renderDrawer && renderDrawer();
  }

  renderEditDrawer = () => {
    return (
      <CustomDrawer open={this.state.isDrawerOpen} onClose={this.handleDrawerToggle}>
        <Box sx={{ width: { xs: 250, sm: 640 }, padding: "30px", position: 'relative' }}>
          <IconButton
            data-test-id="close-drawer"
            onClick={this.handleDrawerToggle}
            sx={{ position: 'absolute', top: 16, right: 16 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Edit {this.state.courseData[this.state.currentIndex]?.type}
          </Typography>
          {this.renderDrawerContent()}
        </Box>
      </CustomDrawer>
    );
  };

  renderQuizEditor = () => {
    const listTypes = [
      { label: 'Single Response', value: 'single_correct_option' },
      { label: 'Multiple Response', value: 'multiple_correct_options' },
    ];

    return (
      <Box>
        {this.state.quizData?.map((question, index) => (
          <Box
            key={'question'+index + this.state.quizData?.length}
            padding={'30px'}
            marginTop={'20px'}
            marginBottom={'20px'}
            sx={{
              width: 640,
              backgroundColor: 'white',
              boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.3)',
              borderRadius: 2,
            }}
            display={'flex'}
            flexDirection={'column'}
            gap={'32px'}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box fontSize={'32'} fontWeight={600}>
                {this.addLeadingZero(index + 1)}/
                {this.addLeadingZero(this.state.quizData?.length)}
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    this.onClickDeleteQuestion(index);
                  }}
                  data-test-id={`delete-question}`}
                >
                  <img src={binIcon} height={'40px'} width={'40px'} />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{zIndex:100}}>
              <Typography
                paddingY={'10px'}
                fontSize={'16px'}
                color={'rgba(106, 106, 106, 1)'}
                fontWeight={500}
                className="title">Type of Question</Typography>
              <Select
                data-test-id="quiz-select"
                options={listTypes}
                value={listTypes.find(
                  (type) => type.value === question.question_type
                )}
                onChange={(value) =>
                  this.handleQuestionTypeChange(value, index)
                }
                styles={{zIndex:12}}
              />
            </Box>

            <Box>
              <Typography
                paddingY={"10px"}
                className="title"
                fontSize={'16px'}
                color={'rgba(106, 106, 106, 1)'}
                fontWeight={500}>Question</Typography>
              <CustomEditor
                key={'question' + index}
                value={question.description}
                data-test-id="question-field"
                onChange={(value) => this.handleQuestionChange(value, index)}
              />
            </Box>
            <Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                height={'22px'}
                gap={'24px'}
                width={'122px'}
              >
                <Typography
                  fontSize={'14px'}
                  color={'rgba(106, 106, 106, 1)'}
                  fontWeight={500}>
                  Correct
                </Typography>
                <Typography
                  fontSize={'14px'}
                  color={'rgba(106, 106, 106, 1)'}
                  fontWeight={500}>
                  Choices
                </Typography>
              </Box>
              {question.answer_options_attributes.map((option, optionIndex) => (
                <Box
                  key={optionIndex}
                  display="flex"
                  alignItems="center"
                  justifyContent={'space-between'}
                  gap={'35px'}
                  paddingTop={'10px'}
                >
                  {this.isMultiSelect(question.question_type) ? (
                <CustomCheckbox
                  data-test-id={`checkbox-${optionIndex}`}
                  checked={option.is_correct}
                  onChange={() => this.handleOptionCorrectChange(index,optionIndex)}
                />
              ) : (
                <Radio
                  data-test-id={`radio-${optionIndex}`}
                  checked={option.is_correct}
                  style={{ color: 'black' }}
                  onChange={() => this.handleOptionCorrectChange(index,optionIndex)}
                />
              )}
                  <Box style={{ width: '100%' }}>
                    <CustomEditor
                      data-test-id={`quiz-option-${optionIndex}`}
                      value={option.description}
                      onChange={(value) =>
                        this.handleOptionTextChange(value, index, optionIndex)
                      }
                    />
                  </Box>
                </Box>
              ))}
              <Box>
                <Typography paddingY={'10px'}
                  fontSize={'16px'}
                  color={'rgba(106, 106, 106, 1)'}
                  fontWeight={400} className="title">
                  Correct answer Supporting Text
                </Typography>
                <CustomEditor
                  value={question.correct_feedback}
                  data-test-id="correct_feedback-field"
                  onChange={(value) =>
                    this.handleSupportingTextChange(value, index, true)
                  }
                />
              </Box>
              <Box>
                <Typography paddingY={'10px'}
                  fontSize={'16px'}
                  color={'rgba(106, 106, 106, 1)'}
                  fontWeight={400} className="title">
                  Incorrect answer Supporting Text
                </Typography>
                <CustomEditor
                  value={question.incorrect_feedback}
                  data-test-id="incorrect_feedback-field"
                  onChange={(value) =>
                    this.handleSupportingTextChange(value, index)
                  }
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            textAlign="center"
            variant="contained"
            style={{
              backgroundColor: `rgba(28, 28, 28, 1)`,
              borderRadius: '4px',
              padding: '16px',
              minHeight: '60px',
              width: '100%',
              fontFamily: 'Heebo',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '22px',
              color: '#ffffff',
              textTransform: 'none',
            }}
            data-test-id="add-new-question"
            onClick={this.handleAddNewQuestion}
          >
            Add Another Question
          </Button>
        </Box>
        {this.renderDeleteModal()}
      </Box>
    );
  };

  renderSidebarDrawer = () => {
    return (
      <CustomDrawer open={this.state.isSidebarOpen} onClose={this.sidebarHandler}>
        <Box className="content-sidebar">
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography className="content-library">Content Library</Typography>
            <IconButton onClick={this.sidebarHandler} data-test-id="close-sidebar">
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {['TEXT', 'LIST', 'IMAGE', 'VIDEO', 'TABLE', 'FLASH CARD', 'LABELED GRAPHIC', 'QUOTE', 'TEST KNOWLEDGE', 'BUTTON'].map((text, index) => (
              <ListItem className="list-item" key={`${index + 1}`} onClick={() => this.handleTypeButtonClick(text.charAt(0) + text.slice(1).toLowerCase(), true)} data-test-id="sidebar-content-type">
                <Typography className="list-text">{text}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </CustomDrawer>
    );
  }

  renderPlusButton = (index: number) => {
    return (
      <IconButton
        data-test-id="plus-button"
        className="plus-button"
        onClick={() => this.onPlusClick(index)}
        sx={{ padding: "0px", position: "absolute", left: "50%", bottom: "-1px" }}
      >
        <PlusLine sx={{ marginBottom: "0px" }}>
          <div />
          <img src={plusIcon} />
        </PlusLine>
      </IconButton>
    );
  }

  isOptionVisible = (index: number) => {
    return this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
  }

  renderTextType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <div style={{ marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html: item.heading }} className="margin0" />
          <div dangerouslySetInnerHTML={{ __html: item.content }} className="margin0" />
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderListType = (item, index: number) => {
    return (
      <MainWrapper
        data-test-id="list-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <div style={{ marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html: item.heading }} className="margin0" />
          <div dangerouslySetInnerHTML={{ __html: item.content }} className="margin0 list-content" />
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderImageType = (item, index: number) => {
    return (
      <MainWrapper
        data-test-id="image-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <Box textAlign="center">
            <img src={item.image} className="margin0 image" />
            <Box sx={{ marginTop: "30px" }} dangerouslySetInnerHTML={{ __html: item.caption }} />
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderVideoType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`} height="100%">
          <Box textAlign="center">
            <Box width="100%" height={{ xs: "fit-content", sm: "500px" }}>
              <ReactPlayer
                controls
                url={item.video}
                width="100%"
                height="100%"
                style={{ maxHeight: "500px" }}
              />
            </Box>
            <Box sx={{ marginTop: "30px" }} dangerouslySetInnerHTML={{ __html: item.caption }} />
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  };

  renderQuoteType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <Box>
            <div style={{ marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html: item.content }} className="margin0" />
            <Box textAlign="center">
              {item.image && <img width="88px" height="88px" style={{ borderRadius: "50%", textAlign: "center" }} src={item.image} />}
            </Box>
            <div dangerouslySetInnerHTML={{ __html: item.name }} />
            {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
            {!this.state.isPreview && this.renderOptionList(index)}
          </Box>
          {this.renderPlusButton(index)}
        </Box>
      </MainWrapper>
    );
  }

  renderLabelGraphicType = (item, index: number) => {
    const isOptionVisible = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
    return (
      <MainWrapper
        data-test-id="image-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={isOptionVisible}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <Box
            textAlign="center"
            sx={{ position: 'relative', overflow: 'hidden' }}
            data-test-id="backgroundLabelImage"
            ref={this.containerRef}
            onMouseMove={(e) => this.handleDrag(e, index)}
            onMouseUp={this.handleDragEnd}
            onDoubleClick={(e) => this.handleImageClick(e, index)}
          >
            <img
              src={item.image}
              className="margin0 image"
              alt="Background"
              style={{ display: 'block', maxWidth: '100%' }}
            />
            {item.content.map((button, index2) => (
              <Box
                sx={{
                  position: 'absolute',
                  top: `${button.y}px`,
                  left: `${button.x}px`,
                  cursor: 'move',
                  width: 'max-content',
                  userSelect: 'none',
                  touchAction: 'none',
                }}
                data-test-id={`draggable-btn-${index2}`}
                display={'flex'}
                flexDirection={'column'}
                onTouchStart={(e) => this.handleDragStart(e, index2, index)}
                onMouseDown={(e) => this.handleDragStart(e, index2, index)}
                onMouseUp={this.handleDragEnd}
              >
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position="relative">
                  <Button
                    style={{
                      ...webStyles.contentBtn,
                    }}
                  >
                    <AddIcon data-test-id={`show-content-${index2}`} style={webStyles.plusIcon} onClick={() => { this.handleEditLabelContent(index, button.id, 'visibleContent', !button.visibleContent) }} />
                  </Button>
                  <Button
                    data-test-id={`reverse-btn-${index2}`}
                    onClick={() => {
                      this.handleEditLabelContent(index, button.id, 'contentRightSide', !button.contentRightSide)
                    }}>
                    <LoopIcon style={{ color: "#fff" }} />
                  </Button>

                  <div
                    data-test-id={`content-show-${index2}`}
                    style={{
                      ...webStyles.labelContent,
                      display: button.visibleContent ? 'flex' : 'none',
                      position: 'absolute',
                      top: 0,
                      [button.contentRightSide ? 'left' : 'right']: '100%',
                      marginLeft: button.contentRightSide ? '10px' : '0',
                      marginRight: button.contentRightSide ? '0' : '10px',
                    }}
                  >
                    <div style={{ marginBottom: "15px" }} dangerouslySetInnerHTML={{ __html: button.title }} className="margin0" />
                    <div dangerouslySetInnerHTML={{ __html: button.description }} className="margin0" />
                  </div>
                </Box>
              </Box>

            ))}
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderCarouselPagination = ({ pages, activePage, onClick }: PaginationProps) => {
    return (
      <Box className="pagination">
        {pages.map((page) => (
          <Box
            key={page}
            className="page"
            onClick={() => onClick(page)}
            backgroundColor={activePage === page ? 'black' : '#e0e0e0'}
          />
        ))}
      </Box>
    );
  }

  renderCarouselArrow = ({ type, isEdge, onClick }: CarouselButtonProps) => {
    const fill = isEdge ? "#999999" : "#0F172A";
    return (
      <Box display="flex" alignItems="center">
        <Box
          onClick={!isEdge ? onClick : undefined}
          className="arrow-btn"
          sx={{
            cursor: isEdge ? "not-allowed" : "pointer"
          }}
        >
          {type === 'PREV' ?
            <svg width="10" height="17" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.0009 19.7469L5.24094 11.9869L13.0009 4.2269C13.7809 3.4469 13.7809 2.1869 13.0009 1.4069C12.2209 0.626902 10.9609 0.626902 10.1809 1.4069L1.00094 10.5869C0.220938 11.3669 0.220938 12.6269 1.00094 13.4069L10.1809 22.5869C10.5546 22.9614 11.0619 23.1719 11.5909 23.1719C12.12 23.1719 12.6273 22.9614 13.0009 22.5869C13.7609 21.8069 13.7809 20.5269 13.0009 19.7469Z" fill={fill} />
            </svg>
            : <svg width="10" height="17" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.999062 19.7469L8.75906 11.9869L0.999062 4.2269C0.219062 3.4469 0.219062 2.1869 0.999062 1.4069C1.77906 0.626902 3.03906 0.626902 3.81906 1.4069L12.9991 10.5869C13.7791 11.3669 13.7791 12.6269 12.9991 13.4069L3.81906 22.5869C3.4454 22.9614 2.9381 23.1719 2.40906 23.1719C1.88003 23.1719 1.37273 22.9614 0.999062 22.5869C0.239062 21.8069 0.219062 20.5269 0.999062 19.7469Z" fill={fill} />
            </svg>
          }
        </Box>
      </Box>
    );
  }

  renderCarouselContent = (item) => {
    return item.content.map((obj, index: number) => {
      return (
        <Box margin={{ xs: "0px 35px", sm: "0px 10px" }} width="100%">
          <Box dangerouslySetInnerHTML={{ __html: obj.heading }} />
          {obj.type === "image" && <img src={obj.media} className="margin0 image" />}
          {obj.type === "video" &&
            <Box width="100%" height={{ xs: "fit-content", sm: "300px" }}>
              <ReactPlayer
                controls
                url={obj.media}
                width="100%"
                height="100%"
                style={{ maxHeight: "300px" }}
              />
            </Box>}
          <Box mt="30px" dangerouslySetInnerHTML={{ __html: obj.description }} />
        </Box>
      );
    })
  }

  renderCarouselType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <Box className="carousel">
            <Box textAlign="center" position="relative">
              <Carousel
                isRTL={false}
                data-test-id="carousel"
                renderArrow={this.renderCarouselArrow}
                renderPagination={this.renderCarouselPagination}
              >
                {this.renderCarouselContent(item)}
              </Carousel>
            </Box>
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderFlashCardType = (item, index: number) => {
    const isVisibleOptions = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");

    return (
      <MainWrapper
        data-test-id="flash-card-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={isVisibleOptions}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}>
        <Box sx={{ width: '70vw' }}>
          <Grid container spacing={4} >
            {item.cards.map((card, childIndex: number) => {
              return (
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={childIndex}>
                  <FlipCard className={card.flipped ? "flipped" : ""}>
                    <div className="flip-card-inner">
                      <div data-test-id={`front-card-${childIndex}`} className="flip-card-front" >
                        <div className="card-content">
                          <div dangerouslySetInnerHTML={{ __html: card.front }} className="margin0" />
                        </div>
                        <img data-test-id={`flip-front-${childIndex}`} src={repeatBtnBlack} onClick={() => { this.handleFlipCard(childIndex, index, true) }} />

                      </div>
                      <div data-test-id={`back-card-${childIndex}`} className="flip-card-back">
                        <div className="card-content">
                          <div dangerouslySetInnerHTML={{ __html: card.back }} className="margin0" />
                        </div>
                        <img data-test-id={`flip-back-${childIndex}`} src={repeatBtnWhite} onClick={() => { this.handleFlipCard(childIndex, index, false) }} />
                      </div>
                    </div>
                  </FlipCard>

                </Grid>
              )
            })}
          </Grid>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderTableType = (item, index: number) => {
    const isOptionVisible = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
    
    const rows = [...item.table_rows_attributes];
    return (
      <MainWrapper
        data-test-id="image-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={isOptionVisible}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box sx={{
          width: `${item.width}`
        }}>
          <StyledTable>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={row.id}>
                  {Object.entries(row).map(([key,cell], cellIndex) => (
                   key!=='id'&& <td key={cell.id}>
                          <div dangerouslySetInnerHTML={{ __html: cell.value }} className="margin0" />
                      
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </StyledTable>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    )
  }

  renderTestKnowledgeType = (item, index: number) => {
    const isOptionVisible = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
    const { content } = item
    return (
      <MainWrapper
        data-test-id="test-knowledge-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={isOptionVisible}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Box width={item.width} sx={webStyles.testCard} >
          <Box display={"flex"} borderBottom={"1px solid #D7D7D7"} justifyContent={"space-between"} gap={"10px"} padding={"40px"}>
            <Typography style={webStyles.quizBlockQuestion}>
              <div dangerouslySetInnerHTML={{ __html: content.question }} className="margin0" />
            </Typography>
          </Box>
          <Box padding={"40px 72px"} gap={"32px"} display={"flex"} flexDirection={"column"}>
            {item.content.isMultiple ?
              content.options.map(option => {
                return <FormControlLabel
                  data-test-id={`check-answer-${option.id}`}
                  style={{ gap: '40px' }}
                  control={<CustomCheckbox
                    checked={option.isSelected}
                    onChange={() => { this.handleSelectAnswer(index, option.id) }} />}
                  label={<Typography style={webStyles.checkboxLabel}><div dangerouslySetInnerHTML={{ __html: option.answer }} /></Typography>}
                />
              })
              :
              <RadioGroup>
                {content.options.map(option => {
                  return <FormControlLabel
                    style={{ gap: '40px', padding: '20px 0' }}
                    data-test-id={`radio-answer-${option.id}`}
                    control={<Radio
                      data-test-id="test"
                      checked={option.isSelected}
                      onChange={() => { this.handleSelectAnswer(index, option.id) }}
                      style={{
                        color: 'black',
                        width: '32px',
                        height: '32px',
                      }} />}
                    label={<Typography style={webStyles.checkboxLabel}><div dangerouslySetInnerHTML={{ __html: option.answer }} /></Typography>}
                  />
                })}
              </RadioGroup>
            }
          </Box>
        {content.isSubmit &&
          <Typography style={{ ...webStyles.checkboxLabel, margin: '15px 0',textAlign:'center' }}>
            <div dangerouslySetInnerHTML={{ __html: content.answerCorrect ? content.supportingText : content.supportingTextInCorrect }} />
          </Typography>}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '40px',
        }}>
          <Button style={webStyles.submitButton} data-test-id="submit-test-button" onClick={() => { this.submitAnswer(index) }} variant="contained">Submit</Button>
        </Box>
        </Box>
        {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
        {!this.state.isPreview && this.renderOptionList(index)}
        {this.renderPlusButton(index)}
      </MainWrapper>
    )
  }
   DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <ExpandMore data-test-id='expand-indicator'/>
      </components.DropdownIndicator>
    );
  };
  renderButtonType = (item, index: number) => {
    const isLastLesson = this.state.lessonList.findIndex(lesson => lesson.id === this.state.currentLessonId) === (this.state.lessonList.length - 1)
    const buttonTypes = [
      { label: 'Continue Button', value: false },
      { label: 'Finish Button', value: true, isDisabled: !isLastLesson }
    ]
    return (
      <MainWrapper
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
          position: 'relative'
        }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Box sx={{ width: '50vw' }}>
          <Box sx={{
            position: 'absolute',
            top: 10,
            left: 10,
            width: '100%',
            maxWidth: '230px'
          }}>
            <Select
              data-test-id="list-button-type"
              isSearchable={false}
              options={buttonTypes}
              placeholder="Select button type"
              components={{
                DropdownIndicator:this.DropdownIndicator,
                IndicatorSeparator:()=>null
              }}
              value={buttonTypes.filter((button) => button.value === item.finishButton)[0]}
              onChange={(e) => { this.handleChangeButtonType(index, e?.value) }}
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: '999',
                }),
                option:(base, props) => ({
                  ...base,
                  backgroundColor:"#fff",
                  color:'black',
                  fontWeight:props.isSelected?'bolder':'normal',
                  padding:'15px',
                  fontSize:'14px'
                }),
                dropdownIndicator: (base, state) => ({
                  ...base,
                  transition: 'all .2s ease',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
                })
              }}
            />
          </Box>
          <Button style={buttonStyle.blackButton}>{item.finishButton ? "Finish" : "Continue"}</Button>
          {item.finishButton?
          <p style={{textAlign:'center', color:'#78716C'}}>Adding the <b>‘Finish’</b> button marks all lessons as completed. No additional lessons can be added after this point.</p>
          :<></>}
        </Box>
          <Toolbar isVisible={true}>
            <Box display={'flex'} justifyContent="space-around">
              {toolbarItems.map((item, index) => (
                <button data-test-id="content-type" style={{ color: '#A8A29E' }} >
                  <img src={item.icon} style={{ filter: 'invert(74%) sepia(9%) saturate(178%) hue-rotate(341deg) brightness(89%) contrast(81%)'}}/>
                  <span>{item.label}</span>
                </button>
              ))}
            </Box>
          </Toolbar>
        {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
        {!this.state.isPreview && this.renderButtonOptionList(index)}
      </MainWrapper>
    )
  }

  renderContentType = () => {
    return (
      <ContentWrapper>
        <Box className="course-wrapper">
          {!this.state.isPreview &&
            <Box position="absolute" top="10px" left="10px">
              <IconButton onClick={this.sidebarHandler}>
                <MenuIcon />
              </IconButton>
            </Box>
          }
          <Box padding="40px 30px 30px 70px">
            <Box className="title">
              {this.renderTitleAndAuthor({
                value: this.state.lessonTitle,
                inputStyles: inputStyle.courseTitle,
                onChange: (event) => this.onChangeLessonTitle(event),
                placeholder: "Enter lesson title",
                dataTestId: "lesson-title-input"
              })}
            </Box>
            <Box className="author">
              {this.renderTitleAndAuthor({
                value: this.state.lessonAuthorName,
                inputStyles: inputStyle.authorName,
                onChange: (event) => this.onChangeAuthorName(event),
                placeholder: "Enter author name",
                dataTestId: "author-name-input"
              })}
            </Box>
          </Box>
        </Box>
        <Box marginTop="50px">
          {this.state.courseData.map((item, index: number) => {
            const renderType = {
              Text: this.renderTextType,
              List: this.renderListType,
              Image: this.renderImageType,
              Video: this.renderVideoType,
              Quote: this.renderQuoteType,
              Carousel: this.renderCarouselType,
              Label: this.renderLabelGraphicType,
              Card: this.renderFlashCardType,
              Table: this.renderTableType,
              Test: this.renderTestKnowledgeType,
              Button: this.renderButtonType
            }[item.type];

            return renderType && (
              <Box key={`${index + 1}`}>
                {renderType(item, index)}
              </Box>
            );
          })}
          {!this.state.isPreview && this.renderToolbar()}
        </Box>
      </ContentWrapper>
    );
  }

  renderDeleteModal = () => {
    return (
      <Modal
        open={this.state.currentQuestion!==null}
        onClose={this.handleModalClose}
      >
        <Container sx={{ ...webStyles.containerModal }}>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            padding="10px 0"
          >
            <IconButton
              data-testID="close-Modal"
              onClick={this.handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display={'flex'}
            gap={'10px'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '32px',
                color: '#0F172A',
              }}
            >
              Are you sure you want to delete this question?
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifySelf="end"
            paddingTop="20px"
            gap="16px"
            justifyContent="end"
          >
            <Button
              variant="outlined"
              style={{
                color: 'black',
                backgroundColor: '#F0F0F0',
                border: 'none',
                padding: '10px 35px',
                textTransform: 'none',
                borderRadius: '10px',
                fontSize: '16px',
                fontWeight: '700',
              }}
              onClick={this.handleModalClose}
            >
              Cancel{' '}
            </Button>
            <Button
              variant="contained"
              style={{
                color: 'white',
                backgroundColor: 'rgba(252, 85, 85, 1)',
                padding: '10px 35px',
                textTransform: 'none',
                borderRadius: '10px',
                fontSize: '16px',
                fontWeight: '700',
              }}
              onClick={() => this.handleDeleteQuestion()}
              data-testID="deleteBtn"
            >
              Delete
            </Button>
          </Box>
        </Container>
      </Modal>
    );
  };

  renderQuizContent = () => {
    return (
      <QuizWrapper>
        <Box
          className="course-wrapper"
          sx={{ height: '100vh', display: 'flex', justifyContent: 'center' }}
        >
          {this.renderQuizEditor()}
        </Box>
      </QuizWrapper>
    );
  };

  hasFinishButton = (lessons) => {
    return lessons.some((lesson) =>
      lesson.courseData?.some((data) => data.type === 'Button' && data.finishButton === true)
    );
  }

  renderErrors = () => {
    return (
      <CustomDialog
        data-test-id="close-dialog"
        open={this.state.isErrorVisible}
        onClose={this.onCloseErrorDialog}
      >
         <Box className="dialog-title">
          <Typography>Errors</Typography>
          <CloseIcon className="close-icon" onClick={this.onCloseErrorDialog} />
        </Box>
        <Box padding="15px">
          {this.state.errors?.map((error) => (
            <Box className="error-wrapper">
              <Typography className="text">
                {error}
              </Typography>
            </Box>
          ))}
        </Box>
      </CustomDialog>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapper">
        <Loader loading={this.state.isLoading} />
        <CourseCreationHeader
          data-test-id="header"
          onGoBack={this.onGoBack}
          onPreview={this.onPreview}
          onPublish={this.onPublish}
          navigation={this.props.navigation}
          learningPath={this.state.learningPath}
          requirement={this.state.requirement}
          isPreview={this.state.isPreview}
          isQuizVisible={this.state.isQuizVisible}
          isLessonVisible={this.state.isLessonVisible}
          onSaveQuiz={() => this.saveAssessment()}
          onSaveRequirements={(values) => this.updateRequirements(values)}
          updateAssessmentTitle={(value) => this.updateAssessmentTitle(value)}
        >
          {this.state.isLessonVisible && this.renderContentType()}
          {this.state.isQuizVisible && this.renderQuizContent()}
          {this.renderEditDrawer()}
          {this.renderSidebarDrawer()}
          {this.renderErrors()}
          {!this.state.isLessonVisible && !this.state.isQuizVisible &&
            <>
              <CourseHead>
                <div className="course-wrapper">
                  <div className="title">
                    {this.renderTitleAndAuthor({
                      value: this.state.courseTitle,
                      inputStyles: inputStyle.courseTitle,
                      onChange: (event) => this.onHandleCourseTitleChange(event),
                      placeholder: "Enter course title"
                    })}
                  </div>
                  <div className="author">
                    {this.renderTitleAndAuthor({
                      value: this.state.authorName,
                      inputStyles: inputStyle.authorName,
                      onChange: (event) => this.onHandleAuthorNameTitleChange(event),
                      placeholder: "Enter author name"
                    })}
                  </div>
                  <Box>
                    <Typography>Upload course cover photo</Typography>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: "none" }}
                      id="capture-course-image"
                      data-test-id="capture-course-image"
                      onChange={(e) => this.onCourseImageChange(e)}
                    />
                    <label htmlFor="capture-course-image">
                      <UploadButton component="span" sx={{ marginTop: "10px" }}>Upload</UploadButton>
                    </label>
                    <div className="text-danger">{this.state.imageObj?.fileError}</div>
                    <div >{this.state.imageObj?.fileName}</div>
                  </Box>
                </div>
              </CourseHead>
              <CourseDescription data-test-id="description-form">
                <PopupMenu ref={this.lessonTypeMenuRef}>
                  <LessonTypeMenu data-test-id="lesson-type-menu">
                    <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-empty" onClick={() => this.setLessonListType("grid")}><img src={imageListEmpty} /></AddButton>
                    <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-numbered" onClick={() => this.setLessonListType("ordered")}><img src={imageListNumbered} /></AddButton>
                    <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-bullet" onClick={() => this.setLessonListType("unordered")}><img src={imageListBulleted} /></AddButton>
                  </LessonTypeMenu>
                </PopupMenu>
                <Box display="flex" width="100%">
                  <Square />
                  <Box width="100%" marginLeft="20px">
                    <CustomEditor
                      data-test-id="description"
                      hideBottomBorder={true}
                      value={this.state.description}
                      onChange={this.onChangeCourseDescription}
                    />
                  </Box>
                </Box>
              </CourseDescription>
              <LessonList>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="lessons">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.lessonList.length > 0 &&
                          this.state.lessonList.map((lessonItem, index) => {
                            if (!this.lessonPopupMenuRefs[lessonItem.id]) {
                              this.lessonPopupMenuRefs[lessonItem.id] = React.createRef();
                            }
                            return (
                              <Draggable key={lessonItem.id} draggableId={lessonItem.id.toString()} index={index}>
                                {(provided) => (
                                  <LessonBlock
                                    key={lessonItem.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                                      {this.getListStyleType(index + 1)}
                                      <div className="lesson-title">
                                        {this.ChangeTitleRender({
                                          state: lessonItem.lessonTitle,
                                          inputStyles: inputStyle.lessonTitle,
                                          toggleActivity: (val) => this.onHandleSetLessonTitleActive(lessonItem.id, val),
                                          onChange: (event) => this.onHandleLessonTitleChange(event, lessonItem.id),
                                          placeholder: "Enter lesson title"
                                        })}
                                      </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                                      <div style={{ position: "relative" }}>
                                        <EditLessonButton data-test-id="edit-button" onClick={(event) => this.onOpenLessonPopupMenu(event, lessonItem.id)}>
                                          {lessonItem.isPopUpActive ?
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 8, marginRight: "-8px" }}><span>{configJSON.labelAddLesson}</span><img src={imageArrowUp} /></div>
                                            :
                                            <span>{configJSON.labelEditLesson}</span>
                                          }
                                        </EditLessonButton>
                                        <PopupMenu onClose={this.onCloseLessonPopupMenu} ref={this.lessonPopupMenuRefs[lessonItem.id]}>
                                          <LessonPopupMenu data-test-id="lesson-popup-menu">
                                            <div data-test-id="lesson-creation-btn" onClick={() => this.onLessonCreation(lessonItem, index)} className="lesson-popup-menu-block"><span>{lessonItem.courseData?.length === 0 ? "Create new lesson" : "Edit lesson"}</span><img src={imageArrowRight} /></div>
                                            <div className="lesson-popup-menu-block"
                                              onClick={() => this.onQuizCreation(lessonItem, index)} data-test-id='quiz-creation-btn'
                                            ><span style={{ fontWeight: 700 }}
                                            >{lessonItem.quizData?.length === 0 ? "Create New Assessment" : "Edit Assessment"}</span><img src={imageArrowRight} /></div>
                                          </LessonPopupMenu>
                                        </PopupMenu>
                                      </div>
                                      <DeleteButton
                                        data-test-id="delete-button"
                                        onClick={() => this.deleteLesson(lessonItem.id)}
                                      >
                                        <img src={trashImage} />
                                      </DeleteButton>
                                    </div>
                                  </LessonBlock>
                                )}
                              </Draggable>
                            )
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {this.state.lessonCreationBar.isActive &&
                  <LessonCreator>
                    <div style={{
                      ...inputStyle.inputBox,
                      ...(this.state.lessonList.length > 0 && { padding: "32px" })
                    }}>

                      {this.getListStyleType(this.state.lessonList.length + 1)}
                      <CustomInput
                        fullWidth
                        autoFocus
                        style={inputStyle.lessonCreation}
                        data-test-id="lesson-title"
                        placeholder={configJSON.placeHolderLessonTitle}
                        value={this.state.lessonCreationBar.title}
                        onChange={(event) => {
                          this.setState({ lessonCreationBar: { title: event.target.value, isActive: true } });
                        }}
                        onKeyDown={(event) => this.onHandleEnterPress(event, () => this.addNewLesson(event.target.value))}
                      />
                    </div>
                    <div className="label-submit">{configJSON.labelPressEnterNewLesson}</div>
                  </LessonCreator>
                }
                {!this.state.lessonCreationBar.isActive && !this.hasFinishButton(this.state.lessonList) &&
                  <AddButton data-test-id="add-lesson-button" style={buttonStyle.lessonAddButton} onClick={this.setCreationBarActive}><img src={addImage} /></AddButton>
                }
              </LessonList>
            </>}
        </CourseCreationHeader>
        <div ref={this.bottomRef}></div>
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ContentWrapper = styled(Box)({
  "& .course-wrapper": {
    position: "relative",
    boxShadow: "0px 2px 20px 0px #00000014",
  },
  "& .title": {
    fontSize: "64px",
    lineHeight: "80px",
    marginBottom: "25px",
    fontFamily: 'Crimson Text',
  },
  "& .author": {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1C1C1C",
  }
});

const sharedStyles = ({ isOptionVisible, isPreview }: { isOptionVisible: boolean; isPreview: boolean }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  borderTop: `1px solid ${isOptionVisible ? "#D7D7D7" : "transparent"}`,
  borderBottom: `1px solid ${isOptionVisible ? "#D7D7D7" : "transparent"}`,
  "& .option-list": {
    display: isOptionVisible ? "block" : "none",
  },
  "& .plus-button": {
    display: "none",
  },
  "&:hover": {
    borderTop: `1px solid ${isPreview ? "transparent" : "#D7D7D7"}`,
    borderBottom: `1px solid ${isPreview ? "transparent" : "#D7D7D7"}`,
    "& .option-list, & .plus-button": {
      display: isPreview ? "none" : "block"
    },
  },
  "& blockquote": {
    margin: "0px"
  }
});

const MainWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isPreview" && prop !== "isOptionVisible" })<{ isOptionVisible: boolean, isPreview: boolean; }>(({ theme, isOptionVisible, isPreview }) => ({
  ...sharedStyles({ isOptionVisible, isPreview }),
  "& .image": {
    width: "100%",
    objectFit: "cover",
    maxHeight: "500px",
  },
  "& .list-content": {
    "& ol": {
      paddingLeft: "17px",
      "& li": {
        marginBottom: "20px",
      }
    },
    "& ul": {
      margin: "0px",
      padding: "0px",
      paddingLeft: "35px",
      listStyleType: "none",
      "& li": {
        position: "relative",
        marginBottom: "20px"
      },
      "& li::before": {
        content: "'\u25C6'",
        color: "black",
        fontSize: "25px",
        position: "absolute",
        top: "-4px",
        left: "-36px",
      }
    }
  },
  "& .carousel": {
    padding: "20px 70px 35px",
    backgroundColor: "#FAFAFA",
    boxShadow: "0px 4px 20px 0px #0000001A inset",
    [theme.breakpoints.down('md')]: {
      padding: "10px 9px 15px",
    },
    "& .image": {
      maxHeight: "300px",
    },
    "& .arrow-btn": {
      width: 40,
      height: 40,
      backgroundColor: '#fff',
      boxShadow: "0px 2px 20px 0px #00000014",
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    "& .pagination": {
      gap: '10px',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
      "& .page": {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        cursor: 'pointer',
      }
    }
  }
}));

const QuizWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOptionVisible",
})(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  "& .option-list": {
    display: "none",
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  "&:hover .option-list": {
    display: "block",
  },
}));

const StyledTextField = styled(TextField)`
  & input[type='number'] {
    -moz-appearance: textfield;
  }
  
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ListDrawer = styled(Box)({
  "& .DraftEditor-editorContainer": {
    "& ol": {
      paddingLeft: "12px",
      "& li": {
        marginBottom: "20px",
      }
    },
    "& ul": {
      paddingLeft: "12px",
      "& li": {
        listStyleType: "none !important",
        marginBottom: "20px",
        position: "relative",
      },
      "& li::before": {
        content: "'\u25C6'",
        color: "black",
        fontSize: "25px",
        position: "absolute",
        top: "-4px",
        left: "-36px",
      }
    }
  }
});

const UploadButton = styled(Button)({
  width: "80px",
  height: "42px",
  border: "1.2px solid #D6D3D1",
  fontSize: "14px",
  borderRadius: '4px',
  textTransform: 'none',
  padding: '8px 16px',
  fontFamily: "Heebo-Bold",
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    borderColor: '#D6D3D1',
  },
});

const UrlInputBase = styled(InputBase)({
  width: "100%",
  padding: "6px 12px",
  borderRadius: "4px",
  fontFamily: "heebo-Regular",
  fontSize: "14px",
  border: "1px solid #D6D3D1",
});

const FormatList = styled(Box)({
  zIndex: "9999999",
  position: "absolute",
  top: "76px",
  right: "25px",
  display: "flex",
  gap: "10px",
  width: "fit-content",
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "24px",
  boxShadow: "0px 2px 20px 0px #00000014",
  "& .close-btn": {
    cursor: "pointer"
  },
  "& .format": {
    fontFamily: "Heebo-Bold",
    fontSize: "16px"
  },
  "& .text": {
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: "Heebo-Regular",
    fontSize: "16px",
  },
  "& .small-text": {
    fontSize: "12px",
    marginTop: "15px",
    marginBottom: "5px",
    fontFamily: "Heebo-Medium",
  }
});

const CustomToggleButton = styled(ToggleButton)({
  borderRadius: '4px !important',
  padding: '5px 20px',
  width: "67px",
  color: "#000",
  background: "#fff",
  fontFamily: "Heebo-Regular",
  border: "1.5px solid #D6D3D1",
  '&.Mui-selected': {
    fontFamily: "Heebo-Bold",
    borderRadius: '4px',
    background: "#fff",
    borderColor: '#000',
    fontWeight: 'bold',
    border: "1.5px solid #1C1C1C"
  },
  "&.MuiToggleButtonGroup-middleButton": {
    margin: "0px 13px",
  },
  '&:not(.Mui-selected)': {
    border: "1.5px solid rgb(214, 211, 209)",
    backgroundColor: 'transparent',
  },
  '&:hover': {
    borderColor: '#000',
  },
});

const CustomSlider = styled(Slider)({
  color: '#000',
  height: 2,
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#000',
    border: '2px solid currentColor',
  },
  '& .MuiSlider-track': {
    height: 2,
  },
  '& .MuiSlider-rail': {
    color: '#ccc',
    height: 2,
  },
});

const PlusLine = styled(Box)({
  marginBottom: "50px",
  cursor: "pointer",
  position: "relative",
  "& div": {
    borderBottom: "1px solid rgb(215, 215, 215)"
  },
  "& img": {
    position: "absolute",
    left: "50%",
    top: "-18px",
    padding: "10px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: "1px solid #D7D7D7",
    boxShadow: "0px 2px 20px 0px #00000014"
  }
});

const Toolbar = styled(Box, { shouldForwardProp: (prop) => prop !== "isVisible" })<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  display: isVisible ? "block" : "none",
  padding: "20px",
  width: "fit-content",
  margin: "20px auto 70px",
  borderRadius: "4px",
  border: "1px solid #A8A29E",
  "& button": {
    cursor: "pointer",
    outline: "none",
    border: "none",
    display: "flex",
    flexDirection: "column",
    padding: "12px 24px",
    alignItems: "center",
    borderRadius: "4px",
    backgroundColor: "transparent",
    "& span": {
      marginTop: "20px",
      fontSize: "18px",
      fontFamily: "Heebo-Regular",
    },
    "&:hover": {
      backgroundColor: "#D7D7D7"
    }
  }
}));

const OptionList = styled(Box)({
  position: "absolute",
  top: "10px",
  right: "25px",
  display: "flex",
  gap: "10px",
  width: "fit-content",
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "10px 15px",
  boxShadow: "0px 2px 20px 0px #00000014",
  "& button": {
    padding: "0px",
    border: "none",
    outline: "none",
    background: "transparent"
  },
  "& .up-down": {
    width: "28px",
    height: "28px",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "5px",
    "&:hover, &.active": {
      backgroundColor: "#D7D7D7",
    }
  },
  "& img": {
    cursor: "pointer",
    borderRadius: "4px",
    padding: "5px",
    "&:hover, &.active": {
      backgroundColor: "#D7D7D7",
    }
  }
});

const CustomDrawer = styled(Drawer)({
  "& .title": {
    color: "#6A6A6A",
    fontSize: "16px",
    marginTop: "30px",
    marginBottom: "20px",
    fontFamily: "Heebo-Medium",
  },
  "& .margin0": {
    "& h1, h2, h3, h4, p": {
      margin: "0px"
    }
  },
  "& .content-sidebar": {
    width: "250px",
    padding: "16px",
    "& .content-library": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold"
    },
    "& .list-item": {
      cursor: "pointer",
      padding: "10px 0px"
    },
    "& .list-text": {
      fontSize: "16px !important",
      fontFamily: "Heebo-Bold !important"
    }
  },
  "& .side-title": {
    paddingTop: "25px",
    fontSize: "24px",
    fontFamily: "Heebo-Bold"
  },
  "& .border-bottom": {
    position: "absolute",
    left: "-30px",
    right: "-30px",
  }
});

const inputStyle = {
  courseCreation: {
    fontFamily: "Crimson Text",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "32px",
    color: "#1C1C1C",
    "&::placeholder": {
      color: "#A8A29E"
    },
  },
  lessonCreation: {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26.44px",
  },
  inputBox: {
    borderBottom: "1px solid #9D9D9D",
    display: "flex",
    alignItems: "center",
    gap: 20,
    padding: "32px 0"
  },
  lessonTitle: {
    fontSize: "18px",
    fontFamily: "Heebo",
    fontWeight: 500,
    lineHeight: "26.44px",
    color: "#1C1C1C",
  },
  courseTitle: {
    height: "auto",
    fontFamily: 'Crimson Text',
    fontSize: "50px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "80px"
  },
  authorName: {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23.5px",
    color: "#1C1C1C",
  }
}

const buttonStyle = {
  lessonAddButton: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 50%)",
    "&:hover": {
      backgroundColor: "#1C1C1C",
    }
  },
  lessonListTypeAddButton: {
    boxShadow: "0px 2px 20px 0px #00000014",
  },
  lessonListTypeButton: {
    border: "1px solid #6A6A6A",
  },
  blackButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: '#1C1C1C',
    width: '100%',
    fontSize: '16px',
    color: "#fff"
  }
}

const LessonPopupMenu = styled(Box)({
  width: "auto",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 2px 20px 0px #00000014",
  padding: "14px",
  borderRadius: "4px",
  "& .lesson-popup-menu-block": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 32,
    textWrap: "nowrap",
    backgroundColor: "#FAFAF9",
    borderBottom: "1px solid #F5F5F4",
    padding: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#1C1C1C",
    "& img": {
      width: 16,
      height: 16
    }
  }
})

const LessonTypeMenu = styled(Box)({
  boxShadow: "0px 2px 20px 0px #00000014",
  backgroundColor: "#FFFFFF",
  display: 'flex',
  gap: 16,
  padding: "12px 16px 12px 16px",
  top: "50%",
})

const AddButton = styled(Button)({
  width: 32,
  height: 32,
  minWidth: "auto",
  borderRadius: "50%",
})

const EditLessonButton = styled(Button)({
  padding: "4px 16px 4px 16px",
  borderRadius: "4px",
  border: "1px solid #1C1C1C",
  backgroundColor: "#FFFFFF",
  fontFamily: "Heebo",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#1C1C1C",
  textTransform: "none",
  "& img": {
    width: 24,
    height: 24,
  }
})

const DeleteButton = styled(Button)({
  width: 32,
  height: 32,
  minWidth: "auto",
  backgroundColor: "transparent"
})

const Circle = styled(Box)({
  width: 12,
  height: 12,
  borderRadius: "50%",
  background: "#1C1C1C",
})

const Square = styled(Box)({
  width: 12,
  height: 12,
  marginTop: "10px",
  background: "#1C1C1C",
});

const LessonCreator = styled(Box)({
  padding: "0 0 32px 0",
  "& .label-submit": {
    textAlign: "right",
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "17.63px",
    marginTop: 12,
    color: "#6A6A6A",
  }
})

const LessonList = styled(List)({
  padding: "0 32px",
  maxWidth: "1064px",
  margin: '20px auto 0 auto',
  position: "relative"
})

const LessonBlock = styled(ListItem)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "32px",
  background: "#FFFFFF",
  borderTop: "1px solid #9D9D9D",
  borderBottom: "1px solid #9D9D9D",
  fontSize: "18px",
  fontFamily: "Heebo",
  fontWeight: 500,
  lineHeight: "26.44px",
  color: "#1C1C1C",
  "&:hover": {
    backgroundColor: "#9D9D9D"
  },
});

const CourseHead = styled(Box)({
  boxShadow: "0px 2px 20px 0px #00000014",
  "& .course-wrapper": {
    display: "flex",
    flexDirection: 'column',
    gap: "32px",
    margin: '0 auto',
    padding: "40px 30px 30px 70px",
  },
  "& .title": {
    fontFamily: 'Crimson Text',
    fontSize: "64px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "80px"
  },
  "& .author": {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23.5px",
    color: "#1C1C1C",
  }
})

const CourseDescription = styled(Box)({
  margin: '0 auto',
  maxWidth: "1440px",
  padding: "55px 32px 0 32px",
  position: "relative",
  display: "flex",
  gap: 8,
  "& .public-DraftStyleDefault-block": {
    margin: "5px 0px !important",
  },
  "& .course-description": {
    fontFamily: "Crimson Text",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "32px",
    color: "#1C1C1C",
    width: "100%",
    "& .editor": {
      position: 'absolute',
      top: 0,
      left: '50%'
    }
  }
});

const CustomInput = styled(InputBase)({
  height: "32px",
  "& input": {
    height: "100%",
  }
});

const Wrapper = styled(Box)({
  padding: "0 0 64px 0",
  "& .placeholder": {
    color: "#A8A29E"
  },
  "& .margin0": {
    "& h1, h2, h3, h4, p": {
      margin: "0px"
    }
  }
})

const CustomCheckbox = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': {
    width: '32px',
    height: '32px',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '4px',
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M5 12l5 5L20 7")',
      stroke: 'white',
      strokeWidth: 2,
      fill: 'none',
    },
  },
}));

const FlipCard = styled('div')({
  perspective: '1000px',
  width: '200px',
  height: '100%',
  position: 'relative',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.5s',
  "&.flipped": {
    transform: 'rotateY(180deg)',
  },
  '.flip-card-inner': {
    width: '200px',
    height: '240px',
    transformStyle: 'preserve-3d',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.flip-card-front,.flip-card-back': {
    boxSizing: 'border-box',
    padding: '30px 20px 35px',
    width: '100%',
    position: 'absolute',
    backfaceVisibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>img': {
      width: '24px',
      height: '24px',
      position: 'absolute',
      right: '20px',
      bottom: '10px'
    }
  },

  '.flip-card-front': {
    backgroundColor: '#fff',
    color: 'black',
    height: '90%',
    border: '1px solid black',
    borderBottom: '3px solid black'
  },

  '.flip-card-back ': {
    height: '100%',
    backgroundColor: '#1C1C1C',
    color: 'white',
    transform: 'rotateY(180deg)',
  },

  '.card-content': {
    boxSizing: 'border-box',
    overflow: 'scroll',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    "&>div": {
      margin: 'auto'
    }
  }

})
const StyledTable = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  marginBottom: '40px',
  '& th, & td ': {
    border: '1px solid #d0d0d0',
    padding: '8px',
    textAlign: 'left',
  },

  ' & th': {
    backgroundColor: '#f1f1f1',
    fontWeight: 'bold',
  },

  '& tr:nth-child(even)': {
    backgroundColor: '#f9f9f9',
  },

  ' & tr:hover': {
    backgroundColor: '#e0e0e0',
  },
})

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '600px',
    width: '100%',
    borderRadius: "8px",
  },
  "& .dialog-title": {
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
    },
  },
  "& .error-wrapper": {
    backgroundColor: '#FEE2E2',
    borderLeft: "4px solid #DC2626",
    padding: '6px',
    borderRadius: '4px',
    marginTop: '16px',
    "&:first-child": {
      marginTop: '0px',
    },
    "& .text": {
      marginLeft: "3px",
      fontFamily: 'Heebo',
      fontSize: '15px',
      fontWeight: '600',
      color: '#DC2626',
    }
  }
}));

const webStyles = {
  containerModal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 8px 32px 0px #0000000F',
    width: '600px',
    padding: '24px',
    height: '252px',
    borderRadius: '8px 8px 32px 8px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'space-between',
  },
  quizBlockQuestion: {
    fontFamily: "Crimson Text",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    color: '#1C1C1C'
  },
  checkboxLabel: {
    fontFamily: "Crimson Text",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "32px",
    color: '#1C1C1C'
  },
  submitButton: {
    minWidth: '248px',
    minHeight: '44px',
    borderRadius: '4px',
    padding: '10px 16px',
    backgroundColor: '#1C1C1C',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '32px'
  },
  quizEditorSubHeading: {
    fontFamily: 'Heebo',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1C1C1C'
  },
  quizEditorQuestionHeadings: {
    fontFamily: 'Heebo',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#6A6A6A'
  },
  quizOptionHeading: {
    fontFamily: 'Heebo',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#6A6A6A'
  },
  contentBtn: {
    width: '52px',
    minWidth: '0',
    height: '52px',
    borderRadius: '50%',
    border: '2px solid #fff',
    backgroundColor: '#FFFFFF80',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContents: 'center'
  },
  plusIcon: {
    width: '32px',
    height: '32px',
    color: '#000',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '5px',
    boxSizing: 'border-box',
    boxShadow: "0px 2px 8px 0px #00000099",

  },
  labelContent: {
    flexDirection: "column",
    width: 'max-content',
    maxWidth: "225px",
    alignItems: 'flex-start',
    textAlign: 'left',
    backgroundColor: '#fff',
    padding: '20px'
  },
  buttonTable: {
    border: '1px solid black',
    textTransform: 'none',
    padding: '5px 20px',
    width: '100%',
    color: 'black'
  } as React.CSSProperties,
  testCard:{
    boxShadow: '0px 2px 20px 0px #0000001A',

  }
}
// Customizable Area End
