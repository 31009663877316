import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";

interface IValues {
  name: string;
  email: string;
  company: string;
  jobTitle: string;
  subject: string;
  query: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isUserLogin?: boolean;
  onClose?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  company: string;
  jobTitle: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  loading: boolean;
  isUserLogin: boolean;
  page: number;
  isModalOpen: boolean;
  currentModalItem: any;
  replyText: string;
  isReplied: boolean;
  selectedQueries: any[];
  allQueries: any[];
  queriesToRender: any[];
  currentModalKey: string,
  anchorEl: any;
  anchorElFilter: any;
  anchorElSort: any;
  currentSortKey: string;
  currentMoreItem: any;
  showNewCategoryInput: boolean;
  availableCategories: any[];
  newCategoryName: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getContactInfoApiCallId: string = "";
  contactApiCallId: string = "";
  getQueriesApiCallId: string = "";
  sendReplyApiCallId: string = "";
  markAsReadApiCallId: string = "";
  markAsUnreadApiCallId: string = "";
  changeStatusApiCallId: string = "";
  deleteQueryApiCallId: string = "";
  updateStatusApiCallId: string = "";
  deleteCategoryApiCallId: string = "";
  createCategoryApiCallId: string = "";
  updateCategoryApiCallId: string = "";
  getAllCategoriesApiCallId: string = "";

  filterKeys = [
    {
      key: 'showall',
      label: 'Show All'
    },
    {
      key: 'read',
      label: 'Read'
    },
    {
      key: 'unread',
      label: 'Unread'
    },
    {
      key: 'replied',
      label: 'Replied'
    },
    {
      key: 'not_replied',
      label: 'Not replied'
    },
    {
      key: 'in_progress',
      label: 'In Progress'
    },
    {
      key: 'pending',
      label: 'Pending'
    },
    {
      key: 'completed',
      label: 'Completed'
    }
  ]
  sortKeys = [
    {
      key: 'showall',
      label: 'Show All'
    },
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'date',
      label: 'Date'
    },
    {
      key: 'subject',
      label: 'Subject'
    }
  ]
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.getQueriesApiCallId = "";
    this.sendReplyApiCallId= "";
    this.markAsReadApiCallId = "";
    this.markAsUnreadApiCallId= "";
    this.changeStatusApiCallId = "";
    this.deleteQueryApiCallId = "";
    this.updateStatusApiCallId = "";
    this.deleteCategoryApiCallId = "";
    this.createCategoryApiCallId = "";
    this.updateCategoryApiCallId = "";
    this.getAllCategoriesApiCallId = "";

    this.state = {
      name: "",
      email: "",
      company: "",
      jobTitle: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      loading: false,
      isUserLogin: !!this.props.isUserLogin,
      page: 0,
      isModalOpen: false,
      currentModalItem: {},
      replyText: '',
      isReplied: false,
      selectedQueries: [],
      allQueries : [],
      queriesToRender : [],
      currentModalKey: '',
      anchorEl: {},
      anchorElFilter: {},
      anchorElSort: {},
      currentSortKey: 'Show All',
      currentMoreItem: {},
      showNewCategoryInput: false,
      newCategoryName: "",
      availableCategories: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getAllQueries();
    this.getAllCategories();
    if (this.props.isUserLogin) {
      this.getContactInfoApi();
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        const { data, errors, error, message } = responseJson;
        switch (apiRequestCallId) {
          case this.getContactInfoApiCallId:
            this.handleContactInfoResponse(responseJson);
            break;
          case this.contactApiCallId:
            this.handleSubmitResponse(responseJson);
            break;
          case this.getQueriesApiCallId:
            this.handleAllQueriesResponse(responseJson);
            break;
          case this.sendReplyApiCallId:
            this.handleGenericResponse(responseJson, true);
            break;
          case this.markAsReadApiCallId:
          case this.markAsUnreadApiCallId:
          case this.deleteQueryApiCallId:
          case this.updateStatusApiCallId:
            this.handleGenericResponse(responseJson);
            break;
          case this.deleteCategoryApiCallId:
          case this.updateCategoryApiCallId:
            this.handleCategoriesGenericResponse(responseJson)
            break;
          case this.createCategoryApiCallId:
            this.handleCreateCategoriesResponse(responseJson);
            break;
          case this.getAllCategoriesApiCallId:
            this.handleCategoriesResponse(responseJson);
            break;
          default:
            break;
        }
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onDeleteCategory = async (e: any, item: any) => {
    let id = item.id;
    this.deleteCategoryApiCallId = await apiCall({
      method: "DELETE",
      contentType: "application/json",
      endPoint: `bx_block_categories/contact_categories/` + id,
      token: localStorage.getItem("token"),
    });
    e.stopPropagation();
  }
  
  getBGColor = (row: any) => this.isSelectedRow(row.id) ? '#FFFFFF' : '' ;

  getAllCategories = async () => {
    this.setState({ loading: true });
    this.getAllCategoriesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_categories/contact_categories`,
      token: localStorage.getItem("token"),
    });
  }

  addNewCategory = async () => {
    this.toggleAddNewCategoryModal();
    let reqBody = {
      "contact_category": {
        "name": this.state.newCategoryName
      }
    }
    this.createCategoryApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      endPoint: `bx_block_categories/contact_categories`,
      body: reqBody,
      token: localStorage.getItem("token"),
    });
  }

  toggleAddNewCategoryModal = () => {
    this.setState({ showNewCategoryInput: !this.state.showNewCategoryInput });
  }

  isOpenNewCatDialog = () => this.state.showNewCategoryInput && !this.isReplyModalOpen();
  
  getCategoryVal = (row: any) => row?.attributes?.categories?.[0] || 'Category';

  updateCategory = async (queryId: any, categoryId: any) => {
    this.setState({ loading: true });
    let reqBody = {
      contact_ids: queryId
    }
    this.updateCategoryApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      endPoint: `bx_block_categories/contact_categories/add_contacts?id=` + categoryId,
      body: reqBody,
      token: localStorage.getItem("token"),
    });
  }

  getAllQueries = async () => {
    this.setState({ loading: true });
    this.getQueriesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_contact_us/contacts`,
      token: localStorage.getItem("token"),
    });
  }

  getItemsSelectedString = () => {
    const length = this.state.selectedQueries.length;
    if(!length){
      return null;
    }
    return length + (length > 1 ? ' items' : ' item') + ' selected';
  }

  markAsReadUnread = async (isRead: boolean, isArray: boolean = true, id: string = '') => {
    this.setState({ loading: true });
    let query = isRead ? `bx_block_contact_us/contacts/mark_as_read?contact_ids=` : `bx_block_contact_us/contacts/mark_as_unread?contact_ids=`;
    query = query + (isArray ? this.state.selectedQueries?.toString() : id);
    const msgId = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      endPoint: query,
      token: localStorage.getItem("token"),
    });
    this.setMsgId(isRead, msgId);
  }

  setMsgId=(isRead:boolean,msgId:string)=>{
    if (isRead) {
      this.markAsReadApiCallId = msgId
    } else {
      this.markAsUnreadApiCallId = msgId;
    }
  }

  deleteQueries = async (isArray: boolean = true, id: string = '') => {
    this.setState({ loading: true });
    let query = `bx_block_contact_us/contacts/multi_destroy?contact_ids=`
    query = query + (isArray ? this.state.selectedQueries?.toString() : id);
    this.deleteQueryApiCallId = await apiCall({
      method: "DELETE",
      contentType: "application/json",
      endPoint: query,
      token: localStorage.getItem("token"),
    });
  }

  updateStatus = async (id: any, status: string) => {
    this.setState({ loading: true });
    let query = `bx_block_contact_us/contacts/update_status?` + `id=` + id + `&status=` + status;
    this.updateStatusApiCallId = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      endPoint: query,
      token: localStorage.getItem("token"),
    });
  }

  sendReply = async () => {
    this.setState({ loading: true });
    const id = this.state.currentModalItem.id;
    let query = `bx_block_contact_us/contacts/reply/?id=` + id + `&reply_body=` + this.state.replyText;

    this.sendReplyApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      endPoint: query,
      token: localStorage.getItem("token"),
    });
  }

  handlePopoverClick = (event: any, operation: string, row = {}) => {
    switch (operation) {
      case 'more': this.setState({ anchorEl: event?.currentTarget, currentMoreItem: row });
        break;
      case 'filter': this.setState({ anchorElFilter: event?.currentTarget });
        break;
      case 'sort': this.setState({ anchorElSort: event?.currentTarget });
        break;
    }
    this.setState({ currentModalKey: operation });
  };

  handlePopoverClose = () => {
    this.setState({ currentModalKey: '', anchorEl: null, anchorElFilter: null, anchorElSort: null });
  };

  handleContactInfoResponse = (responseJson: any) => {
    const { data, errors, error } = responseJson;
    if (data) {
      const { name, email, company, job_title: jobTitle } = data;
      this.setState({ name, email, company, jobTitle });
    } else if (errors || error) {
      this.setState({ isUserLogin: false });
    }
  }

  handleCategoriesResponse = (responseJson: any) => {
    responseJson.data ? this.setState({ availableCategories: responseJson.data }) : toast.error(responseJson.errors || responseJson.error);
  }

  handleAllQueriesResponse = (responseJson: any) => {
    responseJson.data ? this.setState({ allQueries: responseJson.data, queriesToRender: responseJson.data }) : toast.error(responseJson.errors || responseJson.error);
  }

  handleGenericResponse = (responseJson: any, isReplied: boolean = false) => {
    this.handlePopoverClose();
    if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors ? responseJson.errors : responseJson.error)
    } else {
      this.setState({ selectedQueries: [] });
      if (isReplied) {
        this.setState({ isReplied: true })
      }
      this.getAllQueries();
      toast.success(responseJson.message);
    }
  }

  handleCreateCategoriesResponse = (responseJson: any) => {
    if (responseJson.message || responseJson?.meta.message) {
      toast.success(responseJson.message);
      this.getAllQueries();
      this.getAllCategories();
    } else {
      toast.error(responseJson.errors ? responseJson.errors : responseJson.error)
    }
  }

  handleCategoriesGenericResponse = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(responseJson.message);
      this.getAllQueries();
      this.getAllCategories();
    } else {
      toast.error(responseJson.errors ? responseJson.errors : responseJson.error)
    }
  }

  setSelectedQueries = (queryId: any, index: any) => {
    if (this.state.selectedQueries?.includes(queryId)) {
      let tempQueries = this.state.selectedQueries;
      const updatedQueries = tempQueries.filter(item => item !== queryId);
      this.setState({ selectedQueries: updatedQueries });
    } else {
      this.setState({ selectedQueries: [...this.state.selectedQueries, queryId] })
    }
  }

  unSelectAllQueries = () => {
    this.setState({ selectedQueries: [] });
  }

  isReplied = () => this.state.isModalOpen && (this.state.currentModalItem?.attributes?.replied || this.state.isReplied);

  showHeaderActionButtons = () => this.state.allQueries && this.state.selectedQueries?.length;

  isSelectedRow = (id: string) => this.state.selectedQueries.includes(id);

  isReplyModalOpen = () => this.state.isModalOpen && this.state.currentModalItem?.attributes;

  isCompletedQuery = (row: any) => row.attributes.status === 'completed';

  handleFilter = (item: any) => {
    this.handlePopoverClose();
    let filteredQueries = [];
    let allQueriesList = this.state.allQueries;
    switch (item.key) {
      case 'read': filteredQueries = allQueriesList.filter(item => item.attributes.read);
        break;
      case 'unread': filteredQueries = allQueriesList.filter(item => !item.attributes.read);
        break;
      case 'replied': filteredQueries = allQueriesList.filter(item => item.attributes.replied);
        break;
      case 'not_replied': filteredQueries = allQueriesList.filter(item => !item.attributes.replied);
        break;
      case 'in_progress': filteredQueries = allQueriesList.filter(item => item.attributes.status === 'in_progress');
        break;
      case 'pending': filteredQueries = allQueriesList.filter(item => item.attributes.status === 'pending');
        break;
      case 'completed': filteredQueries = allQueriesList.filter(item => item.attributes.status === 'completed');
        break;
      default: filteredQueries = allQueriesList;
    }
    this.setState({ queriesToRender: filteredQueries, page: 0 });
  }

  handleSort = (item: any) => {
    this.handlePopoverClose();
    this.setState({ currentSortKey: item.label });
    let sortedQueries = [];
    let allQueriesList = this.state.allQueries;
    switch (item.key) {
      case 'name': sortedQueries = this.sortByName(allQueriesList);
        break;
      case 'email': sortedQueries = this.sortByEmail(allQueriesList);
        break;
      case 'date': sortedQueries = this.sortByDate(allQueriesList);
        break;
      case 'subject': sortedQueries = this.sortBySubject(allQueriesList);
        break;
      default: sortedQueries = this.state.allQueries;
    }
    this.setState({ queriesToRender: sortedQueries, page: 0 });
  }

  sortByName = (list: any[]) => { return list.sort((a, b) => { if (a.attributes.name > b.attributes.name) { return 1 } else { return -1 } }); }

  sortByEmail = (list: any[]) => { return list.sort((a, b) => { if (a.attributes.email > b.attributes.email) { return 1 } else { return -1 } }); }

  sortByDate = (list: any[]) => { return list.sort((a: any, b: any) => new Date(b.attributes.created_at ?? 0).getTime() - new Date(a.attributes.created_at ?? 0).getTime()); }

  sortBySubject = (list: any[]) => { return list.sort((a, b) => { if (a.attributes.subject > b.attributes.subject) { return 1 } else { return -1 } }); }

  isFilterPopover = () => this.state.currentModalKey === 'filter';

  isSortPopover = () => this.state.currentModalKey === 'sort';

  isMorePopover = () => this.state.currentModalKey === 'more';

  filterPopoverId = () => this.isFilterPopover() ? 'simple_popover' : undefined;

  sortPopoverId = () => this.isSortPopover() ? 'simple_popover' : undefined;

  morePopoverId = () => this.isMorePopover() ? 'simple_popover' : undefined;

  openModal = (row: any) => {
    this.handlePopoverClose();
    this.setState({ currentModalItem: row, isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false, currentModalItem: {}, replyText: '', isReplied: false });
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = String(date.getUTCFullYear()).slice(-2);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  onChangeReplyText = (e: any) => {
    this.setState({ replyText: e.target.value })
  }

  onChangeCategory = (query: any = '', categoryName: any = '', isAddNew = false) => {
    if (isAddNew) {
      this.setState({ showNewCategoryInput: true });
    } else {
      const category = this.state.availableCategories?.find(item => item?.attributes?.name === categoryName);
      if (category) {
        query?.attributes.categories.includes(categoryName) ? toast.error('Category already exists') : this.updateCategory(query.id, category.id);
      }
    }
  }

  handleCategoryNameChange = (val: string) => {
    this.setState({ newCategoryName: val });
  }

  handleChangePage = (event: any, newPage: any) => {
    this.setState({ page: newPage });
  };

  handleSubmitResponse = (responseJson:any) => {
    if (responseJson.error) {
     toast.error(responseJson.message);
     this.props.onClose?.()
    }else{
      toast.success(responseJson.message);
      this.props.onClose?.();
    }
  }

  getContactInfoApi = async () => {
    this.setState({ loading: true });
    this.getContactInfoApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_contact_us/contacts/user_details`,
    });
  }

  submitContactInfoApi = async (values: IValues) => {
    this.setState({ loading: true });
    this.contactApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      endPoint: `bx_block_contact_us/contacts`,
      body: {
        "data": {
          "name": values.name,
          "email": values.email,
          "company": values.company,
          "job_title": values.jobTitle,
          "subject": values.subject,
          "description": values.query
        }
      }
    });
  }

  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };
  // Customizable Area End
}
