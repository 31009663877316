import React from "react";
// Customizable Area Start
import {
  Box, Container, Typography, IconButton, Popover, Grid, InputAdornment, Modal,
  Paper, MenuItem, MenuList, Chip, TextField, Button, Card, CardContent, Checkbox,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  styled,
  StyledEngineProvider,
} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import { FilterList, KeyboardArrowDown, AddCircleOutline, MoreVert, Search, } from '@mui/icons-material';
import { courseImg, ellipse, timer } from "./assets";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  AddPackageModal = () => {
    return (
      <Modal open={this.state.addPackageModal} onClose={this.closeAddPackageModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container
          sx={{ ...webStyle.container, height: '30%', width: '40%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              Create New Package
            </Typography>
            <IconButton data-testID="closeAddPackageModal" onClick={this.closeAddPackageModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box width={"100%"}>
            <Typography>Title of Package</Typography>
            <TextField value={this.state.packageName} data-testID="titlePackage" onChange={this.handlePackageName} fullWidth />
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <Button data-testID="closeAddPackageModal" onClick={this.closeAddPackageModal}>Cancel</Button>
            <Button variant="contained" data-testID="addPackageTitle" onClick={this.addPackageTitle}>Add Courses</Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  CreatePackageModal = () => {
    return (
      <Modal open={this.state.createPackageModal} onClose={this.closeCreatePackageModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={webStyle.container}>

          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              Add Courses in {this.state.packageName}
            </Typography>
            <IconButton data-testID="closeCreatePackageModal" onClick={this.closeCreatePackageModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              placeholder="Search Courses"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid"
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                  '& input': {
                    padding: '4px 8px',
                  },
                },
              }}
              value={this.state.courseSearchQuery}
              onChange={this.handleCourseSearchQuery}
              data-testID="handleCourseSearchQuery"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> <Search /></InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          {this.state.packageData && this.state.packageData.length > 0 &&
            <Box
              display={"flex"}
              gap={"10px"}
              width={"25%"}
              alignItems={"center"}>
              <Custombox
                checked={this.state.packageData.length > 0}
                onChange={this.handleDeselectAll}
                data-testID="deselectAll"
              />
              <Typography>{this.state.packageData.length} items selected</Typography>
            </Box>
          }
          <Box
            padding={2}
            width="100%"
            sx={{
              overflowY: 'scroll',
              height: '100%',
            }}
          >
            <Grid
              container
              spacing={4}
              justifyContent="center">
              {this.state.courseData.map((course, index) => (
                <Grid item
                  padding={"10px"}
                  md={4}
                  key={course.id}
                  style={{
                    width: '300px',
                    height: '350px'
                  }}
                >
                  <Card style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }}>
                    <Box
                      position="relative">
                      <Box
                        style={{
                          position: 'relative',
                          height: '160px',
                          width: '100%',
                          backgroundSize: 'cover',
                          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImg})`,
                          backgroundPosition: 'center',
                        }}
                      />
                      <Box
                        position="absolute"
                        left={0}
                        top={0}
                        height="100%"
                        width="100%"
                        flexDirection="column"
                        display="flex"
                        justifyContent="space-between"
                        p={1}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems={"center"}
                          padding={"8px"}>
                          <Chip style={{ borderRadius: '4px' }} label="Published" color="primary" />
                          <CustomCheckbox
                            checked={this.state.packageData.includes(course.id)}
                            data-testID={`check${index}`}
                            onChange={() => this.handleCourseSelection(course.id)}
                          />
                        </Box>
                        <Box
                          display={"flex"}
                          padding={"5px"}
                          alignItems={"center"}>
                          <img src={timer} height={"16px"} width={"16px"} alt="" />
                          <Typography
                            color={"white"}
                            margin={"10px"}>
                            {course.duration} Hours
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <CardContent>
                      <Typography variant="h6" component="div"> {course.title}</Typography>
                      <Typography variant="body2" color="text.secondary"> {course.course_module_count} Lessons</Typography>
                      <Typography variant="body2" color="text.secondary"> {course.description}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box width={"100%"} display={"flex"} gap={"20px"} justifyContent={"end"}>
            <Button data-testID="closeCreatePackageModal" onClick={this.closeCreatePackageModal}>Cancel</Button>
            <Button variant="contained" data-testID="createPackage" onClick={this.createPackage}>Add Courses</Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  UpdatePackageNameModal = () => {
    return (
      <Modal open={this.state.updatePackageNameModal} onClose={this.closeUpdatePackageNameModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container
          sx={{ ...webStyle.container, height: '30%', width: '40%' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="10px 0"
            borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                letterSpacing: '0.5%',
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '32px',
              }}>
              Update Package Name
            </Typography>
            <IconButton data-testID="closeUpdatePackageNameModal" onClick={this.closeUpdatePackageNameModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box width={"100%"}>
            <Typography>Title of Package</Typography>
            <TextField value={this.state.updatedPackageName}
              data-testID="titlePackageUpdate" onChange={this.handleUpdatePackageName} fullWidth />
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <Button data-testID="closeUpdatePackageNameModal" onClick={this.closeUpdatePackageNameModal}>Cancel</Button>
            <Button variant="contained" data-testID="updatePackageTitle" onClick={this.UpdatePackageTitle}>Save Changes</Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  UpdatePackageModal = () => {
    return (
      <Modal open={this.state.updatePackageModal} onClose={this.closeUpdatePackageModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={webStyle.container}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              Add Courses in {this.state.currentPackage?.attributes.name}
            </Typography>
            <IconButton data-testID="closeUpdatePackageModal" onClick={this.closeUpdatePackageModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              placeholder="Search Courses"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid"
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                  '& input': {
                    padding: '4px 8px',
                  },
                },
              }}
              value={this.state.courseSearchQuery}
              onChange={this.handleCourseSearchQuery}
              data-testID="handleCourseSearchQueryy"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Box
            width="100%"
            padding={2}
            sx={{
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <Grid container spacing={4} justifyContent="center">
              {this.state.courseData.map((course, index) => (
                <Grid item
                  padding={"10px"}
                  md={4}
                  key={course.id} style={{ width: '300px', height: '350px' }}>
                  <Card style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <Box position="relative">
                      <Box
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '160px',
                          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImg})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      />
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        p={1}
                      >
                        <Box display="flex" justifyContent="space-between" alignItems={"center"} padding={"8px"}>
                          <Chip style={{ borderRadius: '4px' }} label="Published" color="primary" />
                          <CustomCheckbox
                            data-testID={`checks${index}`}
                            checked={
                              this.state.updatedPackage?.addCourse?.includes(course.id) ?? false
                            }
                            onChange={() => this.handleUpdateCourseSelection(course.id)}
                          />
                        </Box>
                        <Box display={"flex"} padding={"5px"} alignItems={"center"}>
                          <img src={timer} height={"16px"} width={"16px"} alt="" />
                          <Typography color={"white"} margin={"10px"}>
                            {course.duration} Hours
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {course.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {course.course_module_count} Lessons
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {course.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box width={"100%"} display={"flex"} gap={"20px"} justifyContent={"end"}>
            <Button data-testID="closeUpdatePackageModal" onClick={this.closeUpdatePackageModal}>Cancel</Button>
            <Button variant="contained" data-testID="update" onClick={this.updatePackage}>Save Changes</Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  DeleteModal = () => {
    return (
      <Modal
        open={this.state.deleteModal}
        onClose={this.handleDeleteModalClose}
      >
        <Container sx={{ ...webStyle.container, height: '20%', width: '40%' }}>
          <Box
            justifyContent="space-between"
            display="flex"
            padding="10px 0"
            alignItems="center"
          >

            <Typography
              style={{
                fontSize: '24px',
                fontFamily: 'Heebo',
                lineHeight: '32px',
                fontWeight: '700',
                color: '#0F172A',
              }}
            >
              Are you sure you want to delete this package?
            </Typography>
            <IconButton
              data-testID="close-Modal"
              onClick={this.handleDeleteModalClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            justifySelf="end"
            display="flex"
            gap="16px"
            paddingTop="20px"
            justifyContent="end"
          >
            <Button
              variant="outlined"
              style={{
                backgroundColor: '#F0F0F0',
                color: 'black',
                padding: '10px 35px',
                border: 'none',
                textTransform: 'none',
                fontSize: '16px',
                borderRadius: '10px',
                fontWeight: '700',
              }}
              onClick={this.handleDeleteModalClose}
            >
              Cancel{' '}
            </Button>
            <Button
              variant="contained"
              style={{
                color: 'white',
                padding: '10px 35px',
                backgroundColor: 'red',
                borderRadius: '10px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: '700',
              }}
              onClick={() => this.deletePackage()}
              data-testID="deletePackageBtn"
            >
              Delete
            </Button>
          </Box>
        </Container>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box height={"100vh"}>
            <Box display={"flex"} height={"93%"}>
              <Box width={"20%"} height={"fit-content"}>
                <Sidebar navigation={this.props.navigation} current="packages" />
              </Box>
              <Box width={"80%"} padding={"24px 40px"} display={"flex"} flexDirection={"column"} gap={"20px"}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography style={{ color: "#0F172A", fontFamily: 'Heebo', fontSize: '24px', fontWeight: "700", lineHeight: '32px', letterSpacing: '0.5px' }}>Packages</Typography>
                  <Button
                    style={{ color: '#ffffff', padding: '16px', minWidth: '250px', display: 'flex', gap: '8px', backgroundColor: '#1C1C1C', fontWeight: '700', fontFamily: 'heebo', fontSize: '16px', lineHeight: '24px', textTransform: "none" }}
                    startIcon={<AddCircleOutline />}
                    onClick={this.openAddPackageModal}
                    data-testID="createPackageBtn"
                  >
                    Create New Package
                  </Button>
                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '22px'
                }} >
                  <Box style={{ width: '75%' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search packages"
                      style={{
                        borderRadius: '4px',
                        border: "#E7E7E7 1px solid"
                      }}
                      value={this.state.packageSearchQuery}
                      onChange={this.handlePackageSearchQuery}
                      data-testID="handlePackageSearchQuery"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    alignItems: 'center'
                  }}>

                    <Typography
                      style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
                    >Filter</Typography>
                    <IconButton data-test-id='btnFilter'
                    >
                      <FilterList />
                    </IconButton>
                  </Box>
                  <Box display={'flex'} gap={"5px"}>
                    <Box>Sort by :</Box>
                    <Box
                      data-testID='btnSort'
                      onClick={this.handlePopoverClick}
                      style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                    >
                      {this.state.currentSortKey}
                      <KeyboardArrowDown />
                    </Box>

                    <Popover
                      data-test-id='btnPopover'
                      open={this.isSortPopoverOpen()}
                      anchorEl={this.state.anchorElSort}
                      onClose={this.handlePopoverClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      style={{ boxShadow: 'none' }}
                    >
                      {this.sortKeys.map((item, index) => (
                        <MenuItem
                          key={'sortId' + index}
                          data-testID={`btnOptions${index}`}
                          onClick={() => this.handleSort(item)}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Popover>
                  </Box>
                </Box>

                {this.state.packages && this.state.packages.length > 0 ?
                  <Box padding={"20px"} overflow={"scroll"}>
                    <Grid container spacing={4}>
                      {this.state.packages.map((value, index) => (
                        <Grid item xs={6} key={index}>
                          <Paper elevation={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: '16px 24px',
                              borderRadius: '8px',
                              gap: '16px'
                            }}>
                            <Box>
                              <Typography
                                style={{ fontFamily: 'Heebo', paddingBottom: '8px', fontWeight: '500', fontSize: '16px', lineHeight: '23.5px', color: '#000000' }}
                              >
                                {value.attributes.name}
                              </Typography>
                              <Box display={"flex"} gap={"5px"}>
                                <img src={ellipse} alt="" />
                                <Typography
                                  style={{
                                    fontFamily: 'Heebo',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '17px',
                                    color: '#1C1C1C'
                                  }}
                                >{value.attributes.courses.length} Courses</Typography>
                              </Box>
                            </Box>
                            <Box>
                              <IconButton
                                data-testID="packageOptions"
                                onClick={(event) => this.handlePackageOption(event, value)}
                              >
                                <MoreVert />
                              </IconButton>
                            </Box>
                            <Popover
                              onClose={this.handlePopoverClose}
                              anchorEl={this.state.userAnchorEl}
                              open={this.isPackagePopoverOpen()}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <MenuList>
                                <MenuItem data-testID="UpdateName" onClick={this.handleUpdateName}>Update Name</MenuItem>
                                <MenuItem data-testID="UpdateCourse" onClick={this.handleUpdateCourse} >Update Courses</MenuItem>
                                <MenuItem data-testID="clonePackage" onClick={this.clonePackage}>Clone</MenuItem>
                                <MenuItem data-testID="deletePackage" onClick={this.handleDeleteModalOpen}>Delete</MenuItem>
                              </MenuList>
                            </Popover>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  :
                  <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} gap={"20px"} alignItems={"center"}>
                    <Typography
                      style={{
                        color: '#6A6A6A',
                        fontFamily: 'Heebo',
                        fontSize: '32px',
                        lineHeight: '47px',
                        fontWeight: '400'
                      }}
                    >
                      You don't have any packages
                    </Typography>
                    <Button
                      style={{
                        padding: '10px 16px',
                        border: '#6A6A6A 1px solid',
                        borderRadius: '4px',
                        fontFamily: 'Heebo',
                        lineHeight: '47px',
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#1C1C1C',
                        textTransform: 'none',
                        minWidth: '280px'
                      }}
                      onClick={this.openAddPackageModal}
                    >
                      Create New Package
                    </Button>
                  </Box>
                }
              </Box>
            </Box>

            <Box sx={webStyle.footerBlock}>
              <Box sx={webStyle.leftFooter}>
                <Box>Terms and Conditions</Box>
                <Box>Privacy Policy</Box>
                <Box></Box>
              </Box>

              <Box>
                <Box>© 2024 All Rights Reserved</Box>
              </Box>
            </Box>
            {this.AddPackageModal()}
            {this.CreatePackageModal()}
            {this.UpdatePackageNameModal()}
            {this.UpdatePackageModal()}
            {this.DeleteModal()}
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow: 1
  },
  container: {
    left: "50%",
    position: 'absolute',
    transform: "translate(-50%, -50%)",
    top: "50%",
    boxShadow: "0px 8px 32px 0px #0000000F",
    height: "75%",
    width: '70%',
    padding: '24px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  }
};

const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    backgroundColor: 'white',
    width: '20px',
    borderRadius: '4px',
    height: '24px',
    border: '1px solid black',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M5 12l5 5L20 7")',
      stroke: 'white',
      fill: 'none',
    },
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
});

const Custombox = styled(Checkbox)({
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    backgroundColor: 'white',
    border: '1px solid black',
    height: '24px',
    borderRadius: '4px',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M6 12h12")',
      fill: 'none',
      stroke: 'white',
    },
  },
});



// Customizable Area End
